import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Table, Form, Button, Card, Modal } from "react-bootstrap";
import Base from "./base.js";
import { withRouter, Link } from "react-router-dom";
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  isUserStaff,
  concatAllErrors,
  checkRawDataAvailability,
} from "../Common/helpers";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import * as Sentry from "@sentry/browser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

function PatientPrescriptionHistory(props) {
  const isStaff = isUserStaff();
  const identifier = props.match.params.identifier;
  const [openSnackbar] = useSnackbar(error_options);
  const history = useHistory();
  // const { t } = useTranslation();

  const REQUEST_PATIENT = gql`
  query {
    patients(identifier:"${identifier}"){
      totalCount
      edges {
        node {
          firstName
          lastName
          dob
          age
          id
          phone
          displayGender
          civilId
          additionalNotes
          doctor{
            firstName
            lastName
            id
            identifier
          }
          waitinglist{
            edges{
             node{
                waitingList{
                  name
                }
                event{
                  start
                  duration
                  shouldBeSeen
                  doctor{
                    firstName
                    lastName
                  }
                }
             }
           }
          }
          discount
          firstVisit
          dateFirstVisit
          address
          governorate
          referredBy
          impressionDiagnosis
          medication
          identifier
          paymentContact
          paymentContactDetails
          prescriptions{
            edges{
              node{
                id
                created
                date
                doctor{
                  id
                  firstName
                  lastName
                }
                  patient{
                  firstName
                  lastName
                  civilId
                  identifier
                  phone
                  dob
                  id}
                lines{
                  edges{
                    node{
                      id
                      medication
                      quantity
                      frequency
                      route
                      dosage
                      rawData
                      others
                    }
                  }
                }
              }
            }
          }
          controlledPrescriptions{
            edges{
              node{
                patient{
                  id
                  firstName
                }
                id
                date
                medicine
                dosage
                quantity
                frequency 
              }
            }
          }
        }
      }
    }
  }
`;

  const DELETE_PRESCRIPTION_ITEM = gql`
    mutation deletePrescriptionItem($id: ID!) {
      deletePrescriptionItem(id: $id) {
        deleted
      }
    }
  `;

  const frequencyOptions = {
    once_daily: "Once daily",
    twice_daily: "Twice daily",
    three_times_a_day: "Thrice a day",
    four_times_a_days: "Four times a days",
    others: "Others",
  };

  const routeOptions = {
    per_oral: "PO",
    iv: "IV",
    im: "IM",
    id: "ID",
    nasal: "Nasal Spray",
  };

  const quantityOptions = {
    one: "1 Month",
    two: "2 Month",
    three: "3 Month",
    four: "4 Month",
    five: "5 Month",
    six: "6 Month",
    seven: "7 Month",
    eight: "8 Month",
    nine: "9 Month",
    ten: "10 Month",
    eleven: "11 Month",
    twelve: "12 Month",
  };

  const [deletePrescriptionItem] = useMutation(DELETE_PRESCRIPTION_ITEM, {
    onCompleted({ deletePrescriptionItem }) {
      if (deletePrescriptionItem) {
        console.log("deletePrescriptionItem", deletePrescriptionItem);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("deletePrescriptionItem error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
    refetchQueries: [{ query: REQUEST_PATIENT, fetchPolicy: "network-only" }],
  });

  const { data, loading } = useQuery(REQUEST_PATIENT, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("REQUEST_PATIENT error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const patient_prescriptions = data?.patients?.edges[0]?.node?.prescriptions;

  const deletePrescription = (prescItem) => {
    let variables = {
      id: prescItem.node.id,
    };
    if (window.confirm("Are you sure you want to delete this prescription?")) {
      deletePrescriptionItem({ variables });
    }
  };

  const reprintData = (node) => {
    history.push({
      pathname: "/prescription/print",
      state: { reprintData: node },
    });
  };

  const renderPrintButton = (node) => {
    if (checkRawDataAvailability(node)) {
      return <Button onClick={() => reprintData(node)}>Refill</Button>;
    }

    return null;
  };

  const getPrescriptionCard = (item, index) => {
    if (item?.node?.lines?.edges.length > 0) {
      return (
        <div key={index}>
          <Card className="col-md-10 mt-5">
            <Card.Body>
              <Card.Title className="d-flex flex-row justify-content-between align-items-center my-4">
                <div>
                  Date :{" "}
                  <b>
                    {item.node.date
                      ? getDate(new Date(item.node.date))
                      : getDate(new Date(item.node.created))}
                  </b>
                </div>
                <div>
                  Prescribed By :{" "}
                  <b>
                    {item.node.doctor.firstName} {item.node.doctor.lastName}
                  </b>
                </div>
                <div>{renderPrintButton(item.node)}</div>
              </Card.Title>

              <Table responsive className=" ">
                <thead>
                  <tr>
                    <th scope="col " className="text-uppercase">
                      Medication
                    </th>
                    <th scope="col " className="text-uppercase">
                      Dosage
                    </th>
                    <th scope="col " className="text-uppercase">
                      Frequency
                    </th>
                    <th scope="col " className="text-uppercase">
                      Route
                    </th>
                    <th scope="col " className="text-uppercase">
                      Quantity
                    </th>
                    <th scope="col " className="text-uppercase text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                {/* {loading ? <Preloader /> : */}
                <tbody>
                  {item?.node?.lines?.edges.map((i, index) => {
                    let freq = frequencyOptions[i.node.frequency];

                    if (i.node.frequency === "others") {
                      freq = i.node.others;
                    }

                    return (
                      <tr key={index} className="patient_table_row">
                        <td>{i.node.medication}</td>
                        <td>{i.node.dosage}</td>
                        <td>{freq}</td>
                        <td>{routeOptions[i.node.route]}</td>
                        <td>{quantityOptions[i.node.quantity]}</td>
                        <td>
                          <Button
                            variant="link"
                            size="sm"
                            className="m-0 w-100"
                            onClick={() => deletePrescription(i)}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                {/* } */}
              </Table>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };
  return (
    <Base
      isPatientPortal={!isStaff}
      title={<b>Prescription History</b>}
      showHeader={true}
    >
      {patient_prescriptions?.edges.map((item, index) => {
        return getPrescriptionCard(item, index);
      })}
    </Base>
  );
}
export default withRouter(PatientPrescriptionHistory);
