import Base from "../base.js";
import React from "react";
import { withRouter, Link } from "react-router-dom";
import SearchForPatient from "./SearchForPatientHoc";
import { Button } from 'react-bootstrap';

function SearchForPatientPage(props) {
  return (
    <Base
      title={"Search for Patient"}
      showHeader={true}
      rightChild={
        <Link to="/create/patient">
          <Button
            // className={style.patient__block_button}
            variant="link"
            size="lg"
            active
          >
            <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i> Create new Patient Record
          </Button>
        </Link>
      }
    >
      <SearchForPatient />
    </Base>
  );
}
export default withRouter(SearchForPatientPage);
