import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CreateEventFormHoc from "../component/AppointmentDetails/CreateEventForm/CreateEventFormHoc";
import CalendarHoc from "../component/AppointmentDetails/Calendar/CalendarHoc";
import Base from "./base.js";
import { gql, useLazyQuery } from "@apollo/client";
import {
  error_options,
  SNACK_DURATION,
  isUserStaff,
  urlActions,
} from "../Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import {
  eventsDataVar,
  editableAppointmentVar,
  displayLoaderVar,
} from "../cache/cache.js";
import * as Sentry from "@sentry/browser";

export default function AppointmentCreate() {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [doctorLeavesList, setDoctorLeavesList] = useState(null);
  const fullCalendarRef = useRef();
  const [openSnackbar] = useSnackbar(error_options);
  const isStaff = isUserStaff();
  let editID = urlActions(window.location.href, "get", "editID");

  const REQUEST_EVENTS = gql`
    query (
      $doctor_identifier: String!
      $start: DateTime!
      $end: DateTime!
      $excludeId: String
    ) {
      events(
        doctor_Identifier: $doctor_identifier
        start: $start
        end: $end
        active: true
        excludeId: $excludeId
      ) {
        edges {
          node {
            title
            id
            eventId
            status
            start
            end
            holdWaitingReplacement
            doctorEventType {
              duration
              buffer
            }
            doctor {
              id
              firstName
              identifier
              lastName
              #eventType {
              #  edges {
              #    node {
              #      id
              #      duration
              #      buffer
              #    }
              #  }
              #}
            }
            patient {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  `;

  const REQUEST_EDIT_EVENT = gql`
    query ($id: ID!) {
      event(id: $id) {
        title
        description
        start
        end
        id
        eventId
        duration
        discount
        status
        price
        displayStatus
        shouldBeSeen
        lastSeenDate
        initialDate
        lastDate
        requirePayment
        identifier
        hasRefund
        eventType {
          title
          id
        }
        waitinglist {
          waitingList {
            name
            id
            isUrgent
          }
          calledDate
          waitingListNotes
          event {
            start
            duration
            shouldBeSeen
            doctor {
              firstName
              lastName
            }
          }
        }
        doctorEventType {
          title
          id
          duration
          buffer
        }
        location {
          title
        }
        patient {
          firstName
          lastName
          phone
          age
          identifier
          displayGender
          id
          identifier
          discount
          doctor {
            identifier
          }
          patientId
        }
        doctor {
          firstName
          lastName
          email
          phone
          id
          identifier
          overrideSchedules {
            edges {
              node {
                date
                shifts {
                  edges {
                    node {
                      startTime
                      endTime
                      id
                    }
                  }
                }
              }
            }
          }
          availability {
            edges {
              node {
                day
                id
                shifts {
                  edges {
                    node {
                      endTime
                      id
                      startTime
                    }
                  }
                }
              }
            }
          }
          eventType {
            edges {
              node {
                id
                title
                buffer
                duration
                price
                notification {
                  downPaymentNotification
                  downPaymentNotificationCutoff
                }
                eventLocation {
                  id
                  title
                }
                eventType {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  `;

  const REQUEST_PLAIN_EVENTS = gql`
    query ($doctor_identifier: String!, $start: DateTime!, $end: DateTime!) {
      plainEvents(
        doctor_Identifier: $doctor_identifier
        start: $start
        end: $end
        active: true
      ) {
        edges {
          node {
            start
            end
            id
            status
            doctor {
              id
              firstName
              identifier
              lastName
              eventType {
                edges {
                  node {
                    id
                    duration
                    buffer
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  var EVENT_REQUEST_QUERY = isStaff ? REQUEST_EVENTS : REQUEST_PLAIN_EVENTS;

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  useEffect(() => {
    if (editID) {
      GetEditEvents({ variables: { id: editID } });
    }
  }, [editID]);

  const [GetEditEvents, { data: eventEditDisplay = null }] = useLazyQuery(
    REQUEST_EDIT_EVENT,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ event }) => {
        editableAppointmentVar(event);
      },
      onError: ({ networkError }, e) => {
        Sentry.setContext("error", networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: networkError?.statusCode,
        });
        Sentry.captureException("REQUEST_EDIT_EVENT error " + networkError);

        openSnackbar(
          networkError?.result?.errors?.map((error, index) => {
            return error.message;
          }),
          [SNACK_DURATION]
        );
      },
    }
  );

  const [
    GetEvents,
    { error, data: eventDisplay = null, variables, startPolling, stopPolling },
  ] = useLazyQuery(EVENT_REQUEST_QUERY, {
    fetchPolicy: "network-only",
    pollInterval: 16000,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      var events = [];
      if (isStaff && data && data.events && data.events.edges) {
        events = data.events.edges.map((evt) => {
          return evt.node;
        });
      } else if (
        !isStaff &&
        data &&
        data.plainEvents &&
        data.plainEvents.edges
      ) {
        events = data.plainEvents.edges.map((evt) => {
          return evt.node;
        });
      }
      eventsDataVar(events);

      if (variables.nextMonth) {
        setTimeout(() => {
          let nextAppointment = document.getElementById("next-appointment");
          if (nextAppointment) {
            nextAppointment.click();
          }
        }, 500);
      }
      displayLoaderVar(false);
    },
    onError: ({ networkError }, err) => {
      displayLoaderVar(false);
      Sentry.setContext("error", networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: networkError?.statusCode,
      });
      Sentry.captureException("GetEvents error " + networkError);

      openSnackbar(
        networkError?.result?.errors?.map((error, index) => {
          return error.message;
        }),
        [SNACK_DURATION]
      );
    },
  });

  const onFocus = () => {
    if (selectedDoctor) {
      startPolling(16000);
    }
  };
  const onBlur = () => {
    stopPolling();
  };

  return (
    <Base
      title={"Appointment Detail"}
      showHeader={true}
      isPatientPortal={!isStaff}
    >
      <Row>
        <Col xs={12} sm={12} md={4} className="mb-4">
          <CreateEventFormHoc
            setSelectedDoctor={setSelectedDoctor}
            selectedDoctor={selectedDoctor}
            setDoctorLeavesList={setDoctorLeavesList}
            doctorLeavesList={doctorLeavesList}
            fullCalendarRef={fullCalendarRef}
            setSelectedSession={setSelectedSession}
            selectedSession={selectedSession}
            GetEvents={GetEvents}
          />
        </Col>
        <Col xs={12} sm={12} md={8} className="mb-2">
          <CalendarHoc
            selectedDoctor={selectedDoctor}
            doctorLeavesList={doctorLeavesList}
            fullCalendarRef={fullCalendarRef}
            selectedSession={selectedSession}
            GetEvents={GetEvents}
            eventDisplay={eventDisplay}
            error={error}
          />
        </Col>
      </Row>
    </Base>
  );
}
