import Base from "./base.js";
import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";
import {
  Pagination,
  Row,
  Col,
  Container,
  Button,
  Form,
  Table,
} from "react-bootstrap";
import Preloader from "../Common/Preloder/Preloader";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  PER_PAGE_COUNT,
  urlActions,
  getPermissionForAction,
  concatAllErrors,
  getDate,
} from "../Common/helpers";
import queryString from "query-string";
import * as Sentry from "@sentry/browser";

function getYearsRange() {
  const currentYear = new Date().getFullYear(); // Get the current year

  // Generate an array with 10 years before and 10 years after the current year
  const years = [];
  for (let i = -20; i <= 6; i++) {
    years.push(currentYear + i);
  }

  return years;
}

function Patients(props) {
  const [openSnackbar] = useSnackbar(error_options);
  const [searchTerm, setSearchTerm] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [searchTermFetch, setSearchTermFetch] = useState("");
  const [year, setYear] = useState("");

  const { location } = props;
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [count, setCount] = useState(1);

  useEffect(() => {
    let page = urlActions(window.location.href, "get", "page");
    if (page) {
      setActivePage(parseInt(page));

      if (page > 1) {
        setCount(PER_PAGE_COUNT * (page - 1) + 1);
      }
    }
  }, []);

  useEffect(() => {
    let query_string_obj = process_query_string();
    if ("search" in query_string_obj) {
      setSearchTerm(query_string_obj.search);
    }
  }, []);

  const REQUEST_PATIENT = gql`
  query{
    patients(doctor_Identifier:"${
      selectedDoctor?.node?.doctorId ? selectedDoctor?.node?.identifier : ""
    }" search:"${searchTermFetch}",year:"${year}", orderVisit:true, first:${PER_PAGE_COUNT}, offset:${
    PER_PAGE_COUNT * (activePage - 1)
  }) {
      edgeCount
      totalCount
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          phone
          dateFirstVisit
          doctor {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

  const REQUEST_DOCTOR = gql`
    query {
      doctors(user_IsActive: true) {
        edges {
          node {
            firstName
            lastName
            id
            email
            identifier
            doctorId
          }
        }
      }
    }
  `;

  const { data: doctors = [] } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.captureException("doctors error " + err);
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const handleDoctor = (event) => {
    const activeDoctor = doctors?.doctors?.edges?.find(
      (item) => item.node.identifier === event.target.value
    );

    setSelectedDoctor(activeDoctor);
    setActivePage(1);
    setCount(1);
    window.history.pushState(null, null, location.pathname + "?page=1");
  };

  const { data, loading } = useQuery(REQUEST_PATIENT, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("REQUEST_PATIENT error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const total_pages =
    data && data.patients && data.patients.totalCount
      ? Math.ceil(data.patients.totalCount / PER_PAGE_COUNT)
      : 1;

  const [time, setTime] = React.useState(null);
  const TIME_TO_FETCH = 750;

  const patients = data && data.patients ? data.patients.edges : [];

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    let new_data = { search: event.target.value };
    let query_string = process_query_string(new_data);
    const stringified = queryString.stringify(query_string);
    setActivePage(1);
    window.history.pushState(null, null, location.pathname + "?" + stringified);
  };

  useEffect(() => {
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        let termToSave = searchTerm.replaceAll("\\", "");
        setSearchTermFetch(termToSave);
      }, TIME_TO_FETCH)
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const getButtons = () => {
    return (
      <div className="d-flex">
        <Link to="/create/patient">
          {" "}
          <Button variant="link">
            {" "}
            <b> Create new Patient</b>{" "}
          </Button>
        </Link>
      </div>
    );
  };

  const renderPages = () => {
    if (total_pages > 1) {
      let currentpage = [];
      if (activePage == 1) {
        currentpage = [
          <Pagination.Item active={true} onClick={() => handlePageChange(1)}>
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      } else if (activePage == total_pages) {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(total_pages)}
          >
            {activePage}
          </Pagination.Item>,
        ];
      } else {
        currentpage = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage - 1)}
          >
            {activePage - 1}
          </Pagination.Item>,
          <Pagination.Item
            active={true}
            onClick={() => handlePageChange(activePage)}
          >
            {activePage}
          </Pagination.Item>,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(activePage + 1)}
          >
            {activePage + 1}
          </Pagination.Item>,
        ];
      }

      let itemsStart = [];
      if (activePage == 1 || activePage == 2) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
        ];
      } else if (activePage == 3) {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
        ];
      } else {
        itemsStart = [
          <Pagination.First
            disabled={activePage === 1}
            onClick={() => handlePageChange(1)}
          />,
          <Pagination.Prev
            disabled={activePage === 1}
            onClick={() => handlePageChange(activePage - 1)}
          />,
          <Pagination.Item active={false} onClick={() => handlePageChange(1)}>
            {1}
          </Pagination.Item>,
          <Pagination.Ellipsis disabled={true} />,
        ];
      }

      let itemsEnd = [];
      if (activePage == total_pages || activePage == total_pages - 1) {
        itemsEnd = [
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else if (activePage == total_pages - 2) {
        itemsEnd = [
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      } else {
        itemsEnd = [
          <Pagination.Ellipsis disabled={true} />,
          <Pagination.Item
            active={false}
            onClick={() => handlePageChange(total_pages)}
          >
            {total_pages}
          </Pagination.Item>,
          <Pagination.Next
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(activePage + 1)}
          />,
          <Pagination.Last
            disabled={activePage === total_pages}
            onClick={() => handlePageChange(total_pages)}
          />,
        ];
      }

      let allPages = [...itemsStart, ...currentpage, ...itemsEnd];
      return allPages;
    }
  };

  const handlePageChange = (number) => {
    setActivePage(number);
    let query_string = process_query_string();
    query_string["page"] = number;
    const stringified = queryString.stringify(query_string);
    window.history.pushState(null, "", "?" + stringified);

    if (number === 1) {
      setCount(1);
    } else {
      setCount(PER_PAGE_COUNT * (number - 1) + 1);
    }
  };

  const process_query_string = (data = {}) => {
    let query_string_obj = queryString.parse(window.location.search);

    for (const item in data) {
      query_string_obj[item] = data[item];
    }

    return query_string_obj;
  };

  const has_add_permission = getPermissionForAction("patients", "add");

  return (
    <Base
      title={"Patient list"}
      showHeader={true}
      rightChild={has_add_permission ? getButtons() : null}
    >
      <Container fluid>
        <Row className="mb-2">
          <Col>
            <Form.Group as={Row}>
              <Form.Label column md={3}>
                Search
                <Form.Control
                  autoComplete="off"
                  type="text"
                  placeholder="File #/CivilID/Phone"
                  value={searchTerm}
                  onChange={handleChange}
                  column
                  md={8}
                />
              </Form.Label>
              <Form.Label column md={3}>
                Practitioner
                <Form.Control
                  autoComplete="off"
                  as="select"
                  name="doctor"
                  value={selectedDoctor ? selectedDoctor.node.identifier : null}
                  onChange={handleDoctor}
                  column
                  md={8}
                >
                  <option value="">Select Practitioner</option>
                  {doctors &&
                    doctors?.doctors?.edges?.map((doctor) => {
                      return (
                        <option
                          value={doctor.node.identifier}
                          key={doctor.node.id}
                        >
                          {doctor.node.firstName} {doctor.node.lastName}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Label>
              <Form.Label column md={3}>
                Filter by year
                <Form.Control
                  autoComplete="off"
                  as="select"
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  column
                  md={8}
                >
                  <option value="">Select year</option>
                  {getYearsRange()?.map((item, index) => {
                    return (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Label>
              <Form.Label column md={3}>
                Total number
                <h4>
                  <strong>{data?.patients?.totalCount || 0}</strong>
                </h4>
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row className="patient_table_row">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>No.</th>
                <th>Patient Name</th>
                <th>Phone Number</th>
                <th>File number</th>
                <th>First visit</th>
              </tr>
            </thead>
            {loading ? (
              <Preloader />
            ) : patients.length > 0 ? (
              <tbody>
                {patients.map((patient, index) => {
                  let doc = "-";

                  if (
                    patient?.node?.doctor?.firstName &&
                    patient?.node?.doctor?.lastName
                  ) {
                    doc = `${patient?.node?.doctor?.firstName} ${patient?.node?.doctor?.lastName}`;
                  }

                  const firstVisit = patient?.node?.dateFirstVisit
                    ? getDate(new Date(patient?.node?.dateFirstVisit))
                    : "-";

                  return (
                    <tr key={index}>
                      <td>{index + count}</td>
                      <td className="align-middle">
                        <Link
                          key={index}
                          to={"/patient/record/" + patient.node.identifier}
                        >
                          {patient.node.firstName + " " + patient.node.lastName}
                        </Link>
                      </td>
                      <td className="align-middle">
                        <Link
                          key={index}
                          to={"/patient/record/" + patient.node.identifier}
                        >
                          {patient.node.phone}
                        </Link>
                      </td>
                      <td className="align-middle">
                        <Link
                          key={index}
                          to={"/patient/record/" + patient.node.identifier}
                        >
                          {patient.node.identifier}
                        </Link>
                      </td>
                      <td className="align-middle">
                        <Link
                          key={index}
                          to={"/patient/record/" + patient.node.identifier}
                        >
                          {firstVisit}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="align-middle" colSpan={5}>
                    Not Found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </Row>

        {data?.patients?.totalCount > PER_PAGE_COUNT ? (
          <div className="paginationContainer pt-1">
            <Pagination>{renderPages()}</Pagination>
          </div>
        ) : null}
      </Container>
    </Base>
  );
}
export default withRouter(Patients);
