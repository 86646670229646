import React from 'react';
// import style from './LogoutButton.module.css';
import { useApolloClient } from '@apollo/client';
import { isLoggedInVar , userDetailsVar,userPermissionsVar} from "../../../cache/cache";
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const LogoutButton = (props) => {
    
  const client = useApolloClient();
  const handleLogout = () => {
    const { history } = props;
    client.cache.evict({ fieldName: "me" });
    client.cache.gc();
    client.resetStore()
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_permissions");
    isLoggedInVar(false);
    userDetailsVar(null);
    userPermissionsVar(null);
    history.push("/");
  };

  return (
    <>
     <Button variant="link" className="d-flex justify-content-start" onClick={handleLogout} >  <i className="fa fa-sign-out-alt mx-2 " aria-hidden="true"></i> Logout</Button>

    </>
  );
};

export default withRouter(LogoutButton);
