import React, {useEffect, useState}from 'react';
import { Col, Row , Container} from 'react-bootstrap';
import LoginForm from './LoginForm/LoginForm';
import Logo from '../../assets/images/alawadhi_logo.jpeg';
import PasswordReset from "./passwordReset"
import ForgotPassword from "./forgotPassword"

const LoginPage = (props) => {
  const {location } = props;
  const { pathname } = location;
  const [displayForm, setDisplayForm] = useState("login");

  useEffect(() => {
    if(pathname === "/password-reset/"){
      setDisplayForm("passwordReset");
    }
    if(pathname === "/forgot/password"){
      setDisplayForm("forgotPassword");
    }
  }, [pathname]);

  const renderForm=()=>{
    if(pathname === "/password-reset/"){
      return <PasswordReset />
    }
    else if(pathname === "/forgot/password"){
      return <ForgotPassword />
    }
    else{
      return <LoginForm />
    }
  }
  return (
    <Container fluid>    
    <Row className={"login_page"}>
      <Col md="5" className={"login_page__logo "}>
        <div className={"logotype"}>
          <img
                alt={"Logo"}
                className="img-fluid navLogo"
                src={Logo}
            />
        </div>
      </Col>
      <Col md="7" className={"login_page__form"}>
        <Row>
          <Col md="12" className="mx-auto">
          <div className={"logotype mobileLogo"}>
            <img
                  alt={"Logo"}
                  className="img-fluid navLogo"
                  src={Logo}
              />
          </div>
            <Row>
              <Col md="12">
                <h3 className={'text-white text-center mb-5 mt-2'}><b>{displayForm==="login"?" Staff Portal":" Reset Password" }</b></h3>
              </Col>
              <Col md="12 mt-5">
               {renderForm()}
              </Col>
            </Row>
          </Col>
          </Row>
      </Col>
    </Row>
    </Container>
  );
};

export default LoginPage;
