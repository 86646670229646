import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Pagination, Table, Card } from 'react-bootstrap';
import Base from './base.js';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { dataPatientInVar } from '../cache/cache.js';
import UpcomingAppointmentCard from '../component/upcomingAppointmentCard';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, SNACK_DURATION, ERROR_MESSAGE, PER_PAGE_COUNT, urlActions, getForamttedTime, getDate, getPermissionForAction, getPaymentReasonLabel,concatAllErrors } from '../Common/helpers';
import Preloader from '../Common/Preloder/Preloader';
import ControlledPrecription from './controlled_prescription.js';
import PatientDoctorDiscountComponent from './patienDoctorDiscountComponent';

import * as Sentry from "@sentry/browser";

// FIXME: [AC-155] Same thing pagination is broken, Inifinite loop/request is keep happening.

function PatientRecord(props) {
  const identifier = props.match.params.identifier;
  const { history, location } = props;
  const [activeUpcomingPage, setActiveUpcomingPage] = useState(1);
  const [activePastPage, setActivePastPage] = useState(1);
  const [skipPastQuery, setSkipPastQuery] = useState(false);
  const [skipUpcomingQuery, setSkipUpcomingQuery] = useState(false);
  const [upcomingApointments, setUpcomingApointments] = useState([]);
  const [pastAppointments, setPastAppointments] = useState([]);
  const [upcomingTotal, setUpcomingTotal] = useState(0);
  const [pastTotal, setPastTotal] = useState(0);
  var [today, setToday] = useState(new Date().toISOString().split('.')[0]);

  useEffect(() => {
    let upcoming = urlActions(window.location.href, 'get', 'upcoming');
    let past = urlActions(window.location.href, 'get', 'past');
    if (past) {
      setActivePastPage(parseInt(past));
    }
    if (upcoming) {
      setActiveUpcomingPage(parseInt(upcoming));
    }
  }, []);


  const REQUEST_PATIENT = gql`
  query {
    patients(identifier:"${identifier}"){
      totalCount
      edges {
        node {
          firstName
          lastName
          dob
          age
          id
          phone
          displayGender
          civilId
          additionalNotes
          doctor{
            firstName
            lastName
            id
            identifier
          }
          waitinglist{
            edges{
             node{
                waitingList{
                  name
                }
                event{
                  start
                  duration
                  shouldBeSeen
                  doctor{
                    firstName
                    lastName
                  }
                }
             }
           }
          }
          discount
          firstVisit
          dateFirstVisit
          address
          governorate
          referredBy
          impressionDiagnosis
          medication
          identifier
          paymentContact
          paymentContactDetails
          prescriptions{
            edges{
              node{
                id
                doctor{
                  id
                  firstName
                }
                lines{
                  edges{
                    node{
                      id
                      medication
                      quantity
                      frequency
                      route
                      dosage
                    }
                  }
                }
              }
            }
          }
          controlledPrescriptions{
            edges{
              node{
                patient{
                  id
                  firstName
                }
                id
                date
                medicine
                dosage
                quantity
                frequency 
              }
            }
          }
          patientDoctorDiscount{
            edges{
              node{
                id
                doctor{
                  identifier
                  firstName
                  lastName
                  id
                }
                discount
              }
            }
          }
        }
      }
    }
  }
`;

  const REQUEST_UPCOMING_EVENTS = gql`
  query {
    events(patient_Identifier:"${identifier}", start:"${today}", orderby:"start", first:${PER_PAGE_COUNT}, offset:${PER_PAGE_COUNT * (activeUpcomingPage - 1)
    }){
      totalCount
      edges{
        node{
          start
          end 
          displayStatus
          id
          eventType{
          title
          id
          }
          patient{
            identifier
          }
          doctor{
            firstName
            lastName   
          }
        }
      }
    }
  }
`;

  const REQUEST_PAST_EVENTS = gql`
  query {
    events(patient_Identifier:"${identifier}", end:"${today}",orderby:"start", first:${PER_PAGE_COUNT}, offset:${PER_PAGE_COUNT * (activePastPage - 1)
    }){
      totalCount
      edges{
        node{
          start
          end 
          displayStatus
          id
          eventType{
          title
          id
          }
          doctor{
            firstName
            lastName   
          }
          patient{
            identifier
          }
        }
      }
    }
  }
`;

  const REQUEST_PAYMENTS_LIST = gql`
    query {
      payments(patient_Identifier: "${identifier}") 
      {
    totalCount   
    edges{
      node{
        created
        reason
        actualDate
        datePaid
        sources{
					edges{
						node{
							transactions {
								edges {
									node {
										created
										modified
										status
									}
								}
							}
						}
					}
				}
        doctor{
          firstName
          lastName
        }
        patient{
          identifier
          displayGender
          firstName
          lastName
          phone
        }
        id
        displayPaymentType
        displaySourceTypes
        amount
        currency
        notes
        appointment{
          originalPrice
          discount
          id
          start
          doctor{
            firstName
            lastName
          }
          eventType{
            title
          }
        }
      }
    }
  }
    }
  `;

  const [openSnackbar] = useSnackbar(error_options);

  // GET PATIENT DATA
  const { data, loading } = useQuery(REQUEST_PATIENT, {
    fetchPolicy: 'network-only',
    onError: (networkError) => {
      Sentry.setContext('error', networkError?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : networkError});
      Sentry.setContext('ERROR CODE statusCode ',{code:networkError.networkError?.statusCode});
      Sentry.captureException("REQUEST_PATIENT error "+networkError);

      let errorMsg = concatAllErrors(networkError?.graphQLErrors);
			let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
			openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const patient_record = data && data.patients ? data.patients.edges[0] : null;

  const setPatientInfo = () => {
    if(patient_record && patient_record.node){
      dataPatientInVar(patient_record.node);
    }
  };

  // GET UPCOMING APPOINTMENT DATA
  const { data: upcoming_events_obj = null, loading: loading_upcoming = false } = useQuery(
    REQUEST_UPCOMING_EVENTS,
    {
      fetchPolicy: 'network-only',
      // skip: skipUpcomingQuery, //If skip is true, the query will be skipped entirely. Not available with useLazyQuery.
      // using skip as a workaround so that both the queries(past and upcoming appointments) are not triggered when page for only one of them is changed/clicked.
      onCompleted: ({events}) => {
          if (events.totalCount) {
            setUpcomingTotal(events.totalCount);
          }
          let upcoming_events=[];
          if (events.edges) {
            upcoming_events = events.edges;
          }
          setUpcomingApointments(upcoming_events);
      },
      onError: (e) => {
        Sentry.setContext('error', e?.networkError?.result);
        Sentry.setContext('ERROR OBJ ', {errorObj : e});
        Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
        Sentry.captureException("upcoming_events_obj error "+e);

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    },
  );

  const { data: paymentsList, loading: loagingPaymentsList } = useQuery(REQUEST_PAYMENTS_LIST, {
    fetchPolicy: 'network-only',
    onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      Sentry.captureException("REQUEST_PAYMENTS_LIST error "+e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });


  // GET PAST APPOINTMENT DATA
  const { data: past_events_obj = null, loading: loading_past = false } = useQuery(
    REQUEST_PAST_EVENTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({events}) => {
        let past_events=[];
        if (events.edges) {
          past_events = events.edges;
        }
        setPastAppointments(past_events);

        if (events.totalCount) {
          setPastTotal(events.totalCount);
        }
      },
      onError: (e) => {
        Sentry.setContext('error', e?.networkError?.result);
        Sentry.setContext('ERROR OBJ ', {errorObj : e});
        Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
        Sentry.captureException("past_events_obj error "+e);

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    },
  );



  const renderUpcomingAppointments = (events_list) => {
    let appointments = events_list;
    return appointments.map((appointment, index) => {
      let event = appointment.node;
      return <UpcomingAppointmentCard event={event} key={index} />;
    });
  };

  const getPage = (number, pageFor) => {
    if (pageFor === 'upcoming') {
      setActiveUpcomingPage(number);
      let urlToPush = location.pathname + '?upcoming=' + number + '&past=' + activePastPage;
      history.push(urlToPush);
    } else if (pageFor === 'past') {
      setActivePastPage(number);
      let urlToPush = location.pathname + '?upcoming=' + activeUpcomingPage + '&past=' + number;
      history.push(urlToPush);
    }
  };

  const renderPaymentList = (paymentsList) => {
    const payments = paymentsList && paymentsList.payments ? paymentsList.payments.edges : [];

    if (payments.length > 0) {
      return payments.map((itemNode) => {
        let item = itemNode.node;
        let date = new Date(item.created);
        let app_date = item.appointment ? item.appointment.start : null;
        let app_time = item.appointment ? item.appointment.start : null;
        if (app_date) {
          app_date = new Date(app_date);
          app_time = new Date(app_time);
          app_date = getDate(app_date);
          app_time = getForamttedTime(app_time)
        }
        return (
          <tr key={item.id} onClick={() => history.push(
            {
              pathname: `/payments/detail/${item.patient.identifier}`,
              state: {
                item
              }
            })} style={{cursor: 'pointer'}}>
            <td>
              <i className="fa fa-print mr-2 pointer" aria-hidden="true"></i> {getDate(date)}
              <span className="ml-3"> {getForamttedTime(date)} </span>
            </td>
            <td>{getPaymentReasonLabel(item.reason)}</td>
            <td>{app_date} {app_time}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>No payments</td>
        </tr>
      );
    }
  };

  const renderPagesUpcoming = (pageFor) => {
    let items = [];
    let actPage = 1;
    if (pageFor === 'upcoming') {
      actPage = activeUpcomingPage;
    }
    if (pageFor === 'past') {
      actPage = activePastPage;
    }
    let total_pages = pageFor === 'past' ? pastTotal : upcomingTotal;
    total_pages = Math.ceil(total_pages / PER_PAGE_COUNT);
    for (let number = 1; number <= total_pages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === actPage}
          onClick={() => getPage(number, pageFor)}>
          {number}
        </Pagination.Item>,
      );
    }

    return items;
  }

  const has_add_permission = getPermissionForAction("patients", "add");
  const has_edit_permission = getPermissionForAction("patients", "change");


  return (
    <Base
      title={'Patient Record'}
      showHeader={true}
      back="/patients"
      rightChild={
        has_edit_permission ?
          <Link to={'/edit/patient/' + identifier}>
            <Button variant="link">
              {' '}
              <b> Edit Record </b>{' '}
            </Button>
          </Link> : null
      }>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {has_add_permission ? <Row className='py-4'>
            <Col md={4} lg={3} className='mb-2 d-flex justify-content-center'>
              <Link to="/create/appointment">
                <Button variant="primary" size="md" onClick={setPatientInfo}>
                  New Appointment
                </Button>
              </Link>
            </Col>
            <Col md={4} lg={3} className="mb-2 d-flex justify-content-center">
              <Link to={{
                pathname: `/manual/payment`,
                state: {
                  // appointment_detail: appointment_detail,
                  patient_record: patient_record,
                  isPatient: true
                }
              }}>
                {' '}
                <Button variant="primary" size="md">
                  Manual Payment
                </Button>
              </Link>
            </Col>
            <Col md={4} lg={3} className="mb-2 d-flex justify-content-center">
            <Link to={"/patient/record/files/" + patient_record?.node?.id}>
                {' '}
                <Button variant="primary" size="md">
                  Patient Files
                </Button>
              </Link>
            </Col>
            <Col md={4} lg={3} className="mb-2 d-flex justify-content-center">
            <Link to={"/patient/prescription/history/" + patient_record?.node?.identifier}>
                {' '}
                <Button variant="primary" size="md">
                  Patient Prescription History
                </Button>
              </Link>
            </Col>
            <Col md={4} lg={3} className='mb-2 d-flex justify-content-center'>
              <Link to={"/create/past/appointment/" + patient_record?.node?.id}>
                <Button variant="primary" size="md" onClick={setPatientInfo}>
                  Past Appointment
                </Button>
              </Link>
            </Col>
            {/* <Col lg={4} className='mb-2 d-flex justify-content-center'>
              <Button variant="primary" size="md">
                Add to waiting list
              </Button>
            </Col> */}
          </Row> : null}
        <Row className="mt-5">
        <Col md={12}>
        <Row>
          <Col md={6} className={"information"}>
            <h6>
              <b> Basic Information </b>
            </h6>
            {loading ? <span> Loading...</span> : null}
            {patient_record ? (
              <div>
                <h6>
                  {' '}
                  File Number: {patient_record?.node?.identifier}{' '}
                </h6>
                <h6>
                  {' '}
                  Name: {patient_record?.node?.firstName + ' ' + patient_record?.node?.lastName}{' '}
                </h6>
                <h6> Date of Birth: {patient_record?.node?.dob? getDate(new Date(patient_record?.node?.dob)):null} </h6>
                {patient_record?.node?.age? <h6> Age: {patient_record?.node?.age} </h6>:null}
                <h6> Phone: {patient_record?.node?.phone}</h6>
                <h6>
                  {' '}
                  Gender:{' '}
                  <span className="capitalize">
                    {' '}
                    {patient_record.node && patient_record?.node?.displayGender? patient_record?.node?.displayGender?.toLowerCase():""}{' '}
                  </span>{' '}
                </h6>
                <h6> Civil ID: {patient_record?.node?.civilId} </h6>
                {patient_record?.node?.governorate? <h6> Governorate: {patient_record?.node?.governorate} </h6>:null}
                {/* if the saved value is of Datetime format ie correct format we parse it, else we dont display it */}
                {patient_record?.node?.dateFirstVisit && patient_record?.node?.dateFirstVisit?.indexOf("T")>-1? <h6> First Visit Date: {getDate(new Date(patient_record?.node?.dateFirstVisit))} </h6>:<h6>{patient_record?.node?.dateFirstVisit?"First Visit Date:":null} {patient_record?.node?.dateFirstVisit} </h6>}
                {patient_record?.node?.address ? (
                  <h6> Address: {patient_record?.node?.address} </h6>
                ) : null}
                <h6>
                  {' '}
                  Payment Contact:{' '}
                  {patient_record?.node?.paymentContact
                    ? patient_record?.node?.paymentContact
                    : 'Not Provided'}{' '}
                </h6>
                <h6>
                  {' '}
                  Payment Contact Details:{' '}
                  {patient_record?.node?.paymentContactDetails
                    ? patient_record?.node?.paymentContactDetails
                    : 'Not Provided'}{' '}
                </h6>
                
                  {patient_record?.node?.additionalNotes?
                  <>
                    <h6>Notes:</h6>
                    <p>{patient_record?.node?.additionalNotes}</p>
                  </>
                  :null}
              </div>
            ) : null}
          </Col>
          <Col md={6} className={"information"}>
            <h6>
              <b> Appointment Information </b>
            </h6>
            {loading ? <span> Loading...</span> : null}
            {patient_record ? (
              <div>
                {patient_record && patient_record?.node && patient_record?.node?.doctor? <h6>
                  {' '}
                  Doctor:{' '}
                  {patient_record?.node?.doctor.firstName +
                    ' ' +
                    patient_record?.node?.doctor.lastName}{' '}
                </h6>:null}
                <h6> Referred By: {patient_record?.node?.referredBy} </h6>
                <h6> Diagnosis: {patient_record?.node?.impressionDiagnosis}</h6>
                <h6> Medication: {patient_record?.node?.medication} </h6>
                <h6> Civil ID: {patient_record?.node?.civilId} </h6>
                <h6>
                  {' '}
                  Discount %: {patient_record?.node?.discount ? patient_record?.node?.discount : 0}
                </h6>
              </div>
            ) : null}
          </Col>
          </Row>
          </Col>
          {/* Patient doctor discount section */}
          <PatientDoctorDiscountComponent patient_record={patient_record} />

          <ControlledPrecription patient_record={patient_record} controlledPrescriptions={patient_record?.node?.controlledPrescriptions} REQUEST_PATIENT={REQUEST_PATIENT}/>

          <Col md={12}>
            <Row>
              <Col md={4} lg={4} className="mr-auto">
                <div className="mt-5 ">
                  <h6>
                    <b> Upcoming Appointment </b>
                  </h6>
                  {upcomingApointments && upcomingApointments.length > 0 ? (
                    <>
                      <div className="patient_app_container thinScrollBar">
                        {loading_upcoming ? (
                          <Preloader />
                        ) : (
                          renderUpcomingAppointments(upcomingApointments)
                        )}
                      </div>
                      <div className="d-flex justify-content-center pt-5">
                        <Pagination>{renderPagesUpcoming('upcoming')}</Pagination>
                      </div>
                    </>
                  ) : (
                    'No Upcoming Appointments'
                  )}
                </div>
              </Col>
              <Col md={4} className="mr-auto">
                {pastAppointments && pastAppointments.length > 0 ? (
                  <div className="mt-5 ">
                    <h6>
                      <b> Past Appointment </b>
                    </h6>
                    <div className="patient_app_container thinScrollBar">
                      {loading_past ? <Preloader /> : renderUpcomingAppointments(pastAppointments)}
                    </div>
                    <div className="d-flex justify-content-center pt-5">
                      <Pagination>{renderPagesUpcoming('past')}</Pagination>
                    </div>
                  </div>
                ) : null}
              </Col>
              {/* <Col md={4} className="mr-auto">
                {pastAppointments && pastAppointments.length > 0 ? (
                  <div className="mt-5 ">
                    <h6>
                      <b> Prescriptions </b>
                    </h6>
                    <div className="patient_app_container thinScrollBar">
                      {loading_past ? <Preloader /> : renderUpcomingAppointments(pastAppointments)}
                    </div>
                    <div className="d-flex justify-content-center pt-5">
                      <Pagination>{renderPagesUpcoming('past')}</Pagination>
                    </div>
                  </div>
                ) : null}
              </Col> */}
            </Row>
          </Col>
        </Row>
        </>
      )}
      <Row className={'mt-4'}>
        <Col md={12}>
          <h6>
            <b> Payments </b>
          </h6>
          {loagingPaymentsList ? (
            <Preloader />
          ) : (
            <Table responsive className="table table-borderless">
              <thead>
                <tr className='textGrey'>
                  <td>Date</td>
                  <td>Paid For</td>
                  <td>Appointment Date</td>
                </tr>
              </thead>
              <tbody>{paymentsList ? renderPaymentList(paymentsList) : null}</tbody>
            </Table>
          )}
        </Col>
        <Col md={12} className="mt-4">
          <h6>
            <b> Waiting List </b>
          </h6>
          {patient_record && patient_record.node && patient_record?.node?.waitinglist?
            <table className="table table-borderless mb-4 d-block overflow-x-auto">
              <thead>
                <tr>
                  <td>Type</td>
                  <td>Appointment time</td>
                  <td>Pracitioner</td>
                  <td>Duration</td>
                  <td>Should be Seen</td>
                </tr>
              </thead>
              <tbody>
                {patient_record?.node?.waitinglist?.edges.map((item,index)=>{
                  return(
                  <tr>
                    <td>{item.node.waitingList.name}</td>
                    <td>{item.node.event.start ? getDate(new Date(item.node.event.start)) + " " + getForamttedTime(new Date(item.node.event.start)) : null}</td>
                    <td>{item.node.event.doctor.firstName} {item.node.event.doctor.lastName} </td>
                    <td>{item.node.event.duration} Mins</td>
                    <td>{item.node.event.shouldBeSeen ? item.node.event.shouldBeSeen : "-"}</td>
                  </tr>
                  )
                })}
               
              </tbody>
            </table> : <div className='mb-5'>Not Found</div>}
          {/* {has_add_permission ? <Row className='py-4'>
            <Col md={6} lg={6} className='mb-2 d-flex justify-content-center'>
              <Link to="/create/appointment">
                <Button variant="primary" size="md" onClick={setPatientInfo}>
                  New Appointment
                </Button>
              </Link>
            </Col>
            <Col md={6} lg={6} className="mb-2 d-flex justify-content-center">
              <Link to={{
                pathname: `/manual/payment`,
                state: {
                  // appointment_detail: appointment_detail,
                  patient_record: patient_record,
                  isPatient: true
                }
              }}>
                {' '}
                <Button variant="primary" size="md">
                  Manual Payment
                </Button>
              </Link>
            </Col> */}
            {/* <Col lg={4} className='mb-2 d-flex justify-content-center'>
              <Button variant="primary" size="md">
                Add to waiting list
              </Button>
            </Col> */}
          {/* </Row> : null} */}
        </Col>
      </Row>
    </Base>
  );
}
export default withRouter(PatientRecord);
