/*global chrome*/
import { Row, Col, Form, Button } from "react-bootstrap";
import { useRef, useState, useEffect } from "react";
import Base from "./base.js";
import CalendarHoc from "../component/AppointmentDetails/Calendar/CalendarHoc";
import Preloader from "../Common/Preloder/Preloader";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { Formik } from "formik";
import { withRouter, Link } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  getPermissionForAction,
  appColors,
  urlActions,
} from "../Common/helpers";
import {
  myAppointmentDetailsVar,
  appFromCalVar,
  eventsDataVar,
  displayLoaderVar,
  isCustomAppVar,
} from "../cache/cache";
import SelectPatientModal from "./SearchForPatient/selectPatientModal";
import { useReactiveVar } from "@apollo/client";
import * as Sentry from "@sentry/browser";

export const REQUEST_DOCTOR = gql`
  query {
    doctors(user_IsActive: true) {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          availability {
            edges {
              node {
                id
                day
                shifts {
                  edges {
                    node {
                      startTime
                      endTime
                      id
                    }
                  }
                }
              }
            }
          }
          overrideSchedules {
            edges {
              node {
                date
                shifts {
                  edges {
                    node {
                      startTime
                      endTime
                      id
                    }
                  }
                }
              }
            }
          }
          eventType {
            edges {
              node {
                id
                title
                duration
                buffer
                price
                eventType {
                  id
                  title
                }
                eventLocation {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MY_APPOINTMENT_DETAILS = gql`
  query {
    myAppointmentDetails @client
  }
`;

const REQUEST_EVENTS = gql`
  query (
    $doctor_Identifier: String
    $patient_Identifier: String
    $status: String
    $active: Boolean
    $start: DateTime
    $end: DateTime
  ) {
    events(
      doctor_Identifier: $doctor_Identifier
      patient_Identifier: $patient_Identifier
      status: $status
      active: $active
      start: $start
      end: $end
    ) {
      edges {
        node {
          title
          id
          status
          start
          end
          eventType {
            title
          }
          doctor {
            # id
            # firstName
            # lastName
            initials
          }
          patient {
            id
            # firstName
            # lastName
            # identifier
          }
          waitinglist {
            id
          }
          location {
            title
          }
        }
      }
    }
  }
`;

export const REQUEST_PATIENT = gql`
  query getPatients($search: String) {
    patients(search: $search) {
      edges {
        node {
          firstName
          lastName
          id
          dob
          email
          identifier
          phone
          doctor {
            identifier
          }
          civilId
          patientId
          discount
        }
      }
    }
  }
`;

function MyAppointments(props) {
  const [openSnackbar] = useSnackbar(error_options);
  const formikRef = useRef();
  const fullCalendarRef = useRef();
  const searchPatientRef = useRef();
  const [selectedPatientFullName, setSelectedPatientFullName] = useState("");
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  const [selectedDoctorIdentifier, setSelectedDoctorIdentifier] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  // const [myAppDetailsState, setMyAppDetailsState] = useState(null);
  const [active, setActive] = useState(false);
  const [forceFilterApply, setForceFilterApply] = useState(false); // just a state used to rerender / trigger useeffect
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(null);
  const [patientDataObj, setPatientDataObj] = useState(null);
  const [selectedPatientFileNumber, setSelectedPatientFileNumber] =
    useState("");
  const appFromCal = useReactiveVar(appFromCalVar);
  const [getPatientDetail, { data: selectedPatientData }] = useLazyQuery(
    REQUEST_PATIENT,
    {
      fetchPolicy: "network-only",
    }
  );

  const [
    GetEvents,
    { data: eventsList, loading: eventsLoading, startPolling, stopPolling },
  ] = useLazyQuery(REQUEST_EVENTS, {
    fetchPolicy: "network-only",
    variables: {
      doctor_Identifier: selectedDoctorIdentifier,
      patient_Identifier: selectedPatientFileNumber,
      status: active ? "" : selectedStatus,
      active: active,
      start: dateStart,
      end: dateEnd,
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 16000,
    onCompleted: (data) => {
      var events = [];
      if (data && data.events && data.events.edges) {
        events = data.events.edges.map((evt) => {
          let val = JSON.parse(JSON.stringify(evt.node));
          let color = getEventColor(evt.node);
          val["backgroundColor"] = color;
          val["borderColor"] = color;
          if (color === appColors.blue || color === appColors.waitingColor) {
            val["textColor"] = "#fff";
          }
          return val;
        });
      }
      eventsDataVar(events);
      displayLoaderVar(false);
    },
    onError: ({ networkError }, err) => {
      Sentry.setContext("error", networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: networkError?.statusCode,
      });
      Sentry.captureException("GetEvents error " + networkError);
      if (networkError?.result) {
        openSnackbar(
          networkError?.result?.errors?.map((error, index) => {
            return error.message;
          }),
          [SNACK_DURATION]
        );
      }
    },
  });

  const [GetMyAppointments, { loading: myAppointmentDetailsLoading }] =
    useLazyQuery(MY_APPOINTMENT_DETAILS, {
      fetchPolicy: "network-only",
      onCompleted: ({ myAppointmentDetails }) => {
        let appDetailsToAccess = myAppointmentDetails;
        if (
          appDetailsToAccess.selectedDoctorIdentifier ||
          appDetailsToAccess.selectedPatientFullName ||
          appDetailsToAccess.selectedStatus ||
          appDetailsToAccess.forceFilterApply
        ) {
          setForceFilterApply(false);
          setSelectedDoctorIdentifier(
            appDetailsToAccess.selectedDoctorIdentifier
          );
          setSelectedPatientFullName(
            appDetailsToAccess.selectedPatientFullName
          );
          formikRef.current.setFieldValue(
            "patient",
            appDetailsToAccess.selectedPatientFullName
          );
          formikRef.current.setFieldValue(
            "doctor",
            appDetailsToAccess.selectedDoctorIdentifier
          );
          setPatientDataObj(appDetailsToAccess.patientDataObj);
          setSelectedStatus(appDetailsToAccess.selectedStatus);

          // if (appDetailsToAccess.selectedStatus === "active") {
          //   setActive(true);
          // }
          // else {
          //   setActive(false);
          // }

          let variables = {};
          let dataToPass = appFromCal ? appFromCal : {};

          if (myAppointmentDetails?.patientDataObj) {
            dataToPass["patientData"] = appDetailsToAccess.patientDataObj;
            variables["patient_Identifier"] =
              appDetailsToAccess.patientDataObj.identifier;
          } else {
            dataToPass["patientData"] = "";
            variables["patient_Identifier"] = "";
          }

          if (appDetailsToAccess.selectedStatus === "") {
            setActive(false);
            variables["status"] = "";
            variables["active"] = false;
          } else if (appDetailsToAccess.selectedStatus === "cancelled") {
            setActive(false);
            variables["status"] = "cancelled";
            variables["active"] = false;
          } else if (appDetailsToAccess.selectedStatus === "active") {
            setActive(true);
            variables["status"] = "";
            variables["active"] = true;
          }

          if (
            myAppointmentDetails?.docObj ||
            appDetailsToAccess.selectedDoctorIdentifier
          ) {
            setSelectedDoctor(appDetailsToAccess.docObj);
            dataToPass["doctor"] = appDetailsToAccess.docObj;
            variables["doctor_Identifier"] =
              appDetailsToAccess.selectedDoctorIdentifier;
          } else {
            setSelectedDoctor(null);
            dataToPass["doctor"] = "";
            variables["doctor_Identifier"] = "";
          }

          appFromCalVar(dataToPass);
          let calendarView = fullCalendarRef?.current?.getApi()?.view;

          if (calendarView) {
            let dtSt = new Date(calendarView.activeStart);
            let dtEn = new Date(calendarView.activeEnd);
            setDateStart(dtSt);
            setDateEnd(dtEn);
            if (dtSt && dtEn) {
              variables["start"] = dtSt;
              variables["end"] = dtEn;
            }
            if (!eventsLoading) {
              displayLoaderVar(true);
              GetEvents({
                variables: variables,
              });
            }
          }
        }
      },
      onError: ({ networkError }, err) => {
        Sentry.setContext("error", networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: err });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: networkError?.statusCode,
        });
        Sentry.captureException("GetMyAppointments error " + networkError);

        if (networkError.result) {
          openSnackbar(
            networkError?.result?.errors?.map((error, index) => {
              return error.message;
            }),
            [SNACK_DURATION]
          );
        }
      },
    });

  const { data: doctorsList } = useQuery(REQUEST_DOCTOR, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    onError: ({ networkError }, err) => {
      Sentry.setContext("error", networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: networkError?.statusCode,
      });
      Sentry.captureException("REQUEST_DOCTOR error " + networkError);

      if (networkError.result) {
        openSnackbar(
          networkError?.result?.errors?.map((error, index) => {
            return error.message;
          }),
          [SNACK_DURATION]
        );
      }
    },
  });

  const getEventColor = (event) => {
    if (event.status === "CANCELLED") {
      return appColors.tomato;
    } else if (event.status === "CHECKEDIN") {
      return appColors.basil;
    } else if (
      event.status === "PENDING" &&
      event.eventType.title.toLowerCase().includes("follow")
    ) {
      return appColors.grape;
    } else if (
      (event.status === "CONFIRMED" &&
        event.location?.title.toLowerCase().includes("online")) ||
      (event.status === "CONFIRMED" &&
        event.eventType?.title.toLowerCase().includes("online"))
    ) {
      return appColors.tangerine;
    } else if (
      event.status === "CONFIRMED" &&
      event.eventType.title.toLowerCase().includes("follow")
    ) {
      return appColors.banana;
    } else if (
      event.status === "PENDING" &&
      event.eventType.title.toLowerCase().includes("new")
    ) {
      return appColors.flamingo;
    } else if (
      event.status === "CONFIRMED" &&
      event.eventType.title.toLowerCase().includes("new")
    ) {
      return appColors.sage;
    } else if (event.patient === null) {
      return appColors.blue;
    } else if (event.waitinglist?.id) {
      return appColors.waitingColor;
    }
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    displayLoaderVar(true);
    GetMyAppointments();
    // Specify how to clean up after this effect:

    return () => {
      myAppointmentDetailsVar({});
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  const onFocus = () => {
    startPolling(16000);
  };
  const onBlur = () => {
    stopPolling();
  };

  const doctors =
    doctorsList && doctorsList.doctors ? doctorsList.doctors.edges : [];

  useEffect(() => {
    let patient_name = urlActions(window.location.href, "get", "patient_name");
    let doctor_Identifier = urlActions(window.location.href, "get", "doctor");
    let status = urlActions(window.location.href, "get", "status");
    formikRef.current.setFieldValue("doctor", doctor_Identifier);
    formikRef.current.setFieldValue("status", status);
    let docObj = doctors.find((i) => i.node.identifier === doctor_Identifier);
    setSelectedDoctor(docObj);
    if (patient_name) {
      formikRef.current.setFieldValue("patient", patient_name);
    }
  }, [doctors]);

  const onSelectPatient = (patientData) => {
    formikRef.current.setFieldValue(
      "patient",
      `${patientData.firstName} ${patientData.lastName}`
    );
    let newUrlParams = "";
    if (patientData) {
      newUrlParams = urlActions(
        window.location.href,
        "set",
        "patient",
        patientData.identifier
      );
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "set",
        "patient_name",
        `${patientData.firstName} ${patientData.lastName}`
      );
    } else {
      newUrlParams = urlActions(window.location.href, "delete", "patient");
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "delete",
        "patient_name"
      );
    }
    window.history.pushState("", "", "?" + newUrlParams);

    setSelectedPatientFullName(
      `${patientData.firstName} ${patientData.lastName}`
    );
    setSelectedPatientFileNumber(`${patientData.identifier}`);
    setPatientDataObj(patientData);
    let dataToPass = appFromCal ? appFromCal : {};
    dataToPass["patientData"] = patientData;
    appFromCalVar(dataToPass);
  };

  const onSelectDoctor = (event) => {
    formikRef.current.setFieldValue("doctor", event.target.value);
    let newUrlParams = "";
    if (event.target.value) {
      newUrlParams = urlActions(
        window.location.href,
        "set",
        "doctor",
        event.target.value
      );
    } else {
      newUrlParams = urlActions(window.location.href, "delete", "doctor");
    }
    window.history.pushState("", "", "?" + newUrlParams);
    setSelectedDoctorIdentifier(event.target.value);
    let docObj = doctors.find((i) => i.node.identifier === event.target.value);
    let dataToPass = appFromCal ? appFromCal : {};
    if (docObj) {
      dataToPass["doctor"] = docObj;
      setSelectedDoctor(docObj);
    } else {
      dataToPass["doctor"] = null;
      setSelectedDoctor(null);
    }
    appFromCalVar(dataToPass);
    if (!event.target.value) {
      setForceFilterApply(true);
    }
  };

  useEffect(() => {
    if (
      active ||
      selectedStatus ||
      selectedDoctorIdentifier ||
      selectedPatientFullName ||
      forceFilterApply
    ) {
      let docObj = doctors?.find(
        (i) => i.node.identifier === selectedDoctorIdentifier
      );
      let myAppDetailsObj = {
        selectedDoctorIdentifier,
        selectedPatientFullName,
        selectedStatus,
        docObj,
        patientDataObj,
        active,
        forceFilterApply,
      };
      if (formikRef.current.values["status"]) {
        myAppDetailsObj["selectedStatus"] = formikRef.current.values["status"];
      }
      if (formikRef.current.values["doctor"]) {
        myAppDetailsObj["selectedDoctorIdentifier"] =
          formikRef.current.values["doctor"];
        docObj = doctors?.find(
          (i) => i.node.identifier === formikRef.current.values["doctor"]
        );
        myAppDetailsObj["docObj"] = docObj;
      }
      if (
        formikRef.current.values["patient"] &&
        selectedPatientData?.patients?.edges?.length > 0
      ) {
        myAppDetailsObj["selectedPatientFullName"] =
          selectedPatientData.patients.edges[0].node.firstName +
          selectedPatientData.patients.edges[0].node.lastName;
        myAppDetailsObj["patientDataObj"] =
          selectedPatientData.patients.edges[0].node;
      }
      if (
        docObj ||
        patientDataObj ||
        active ||
        forceFilterApply ||
        selectedStatus
      ) {
        myAppointmentDetailsVar(myAppDetailsObj);
      }
    }
    // For each change in filters, this useeffect is triggered which saves the selected filter in cache (myAppointmentDetailsVar) which automatically triggers GetMyAppointments lazy query. in the oncompleted, getEvents with correct values is triggered.
  }, [
    selectedPatientFullName,
    doctors,
    selectedDoctorIdentifier,
    formikRef,
    selectedStatus,
    patientDataObj,
    active,
    forceFilterApply,
  ]);

  useEffect(() => {
    if (active) {
      setSelectedStatus("active");
    }
  }, [active]);

  const initialValues = {
    doctor: "",
    patient: "",
    status: "",
  };

  const handleChangeStatus = (e) => {
    formikRef.current.setFieldValue("status", e.target.value);
    let newUrlParams = "";
    if (e.target.value) {
      newUrlParams = urlActions(
        window.location.href,
        "set",
        "status",
        e.target.value
      );
    } else {
      newUrlParams = urlActions(window.location.href, "delete", "status");
    }
    window.history.pushState("", "", "?" + newUrlParams);
    if (e.target.value) {
      setSelectedStatus(e.target.value);
      if (e.target.value === "active") {
        setActive(true);
      } else {
        setActive(false);
      }
    } else {
      setSelectedStatus("");
      setActive(false);
      // incase all selectedstatus and active is set back to default, the get events from myappVar is not triggered so we call the getevents here incase there is no select value passed
      // GetEvents();
    }
    setForceFilterApply(true);
  };
  // Event Status
  const eventStatus = {
    active: "Active",
    cancelled: "Cancelled",
  };
  var is_my_appointments = false;
  var has_add_permission = getPermissionForAction("appointments", "add");

  return (
    <Base
      title={is_my_appointments ? " My Appointments " : "Appointments"}
      showHeader={true}
      rightChild={
        has_add_permission ? (
          // <Link to={'/search/patient'} className='center'>
          //   <Button variant="link" className="back">
          //     {' '}
          //     <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i> New Appointment{' '}
          //   </Button>
          // </Link>
          <Row className="mt-2">
            <Col xs={12} className="mb-2">
              <Link to="/create/appointment" className="">
                <Button
                  className="w-100"
                  variant="primary"
                  size="md"
                  block
                  active
                >
                  New Appointment
                </Button>
              </Link>
            </Col>
            <Col xs={12}>
              <Link
                to={{
                  pathname: "/create/appointment/",
                  search: "?isCustom=true",
                }}
                onClick={() => {
                  isCustomAppVar(true);
                }}
                className=""
              >
                <Button
                  className="w-100"
                  variant="primary"
                  size="md"
                  block
                  active
                >
                  New Custom
                </Button>
              </Link>
            </Col>
          </Row>
        ) : null
      }
    >
      <Row className="mt-3">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="mx-auto mb-2">
          <Formik initialValues={initialValues} innerRef={formikRef}>
            {({ values, handleChange }) => (
              <Form autoComplete="off" className="mb-4">
                <Row className="filter-block">
                  {!is_my_appointments ? (
                    <Col md={12} xl={4}>
                      <Form.Group as={Row} className="text-left text-xl-right">
                        <Form.Label column sm={3} md={3} xl={4}>
                          Practitioner
                        </Form.Label>
                        <Col sm={12} md={9} xl={8}>
                          <Form.Control
                            autoComplete="off"
                            as="select"
                            name="doctor"
                            value={values.doctor}
                            onChange={onSelectDoctor}
                          >
                            <option value="">Select Practitioner</option>
                            {doctors.map((doctor) => {
                              return (
                                <option
                                  value={doctor.node.identifier}
                                  key={doctor.node.id}
                                >
                                  {doctor.node.firstName} {doctor.node.lastName}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                  ) : null}
                  <Col md={12} xl={4}>
                    <Form.Group as={Row} className="text-left text-xl-right">
                      <Form.Label column sm={3} md={3} xl={4}>
                        Patient
                      </Form.Label>
                      <Col sm={12} md={9} xl={8} ref={searchPatientRef}>
                        <Form.Control
                          placeholder="Select Patient"
                          autoComplete="off"
                          type="text"
                          name="patient"
                          // disabled={is_my_appointments ? false : (selectedDoctorIdentifier ? false : true)}
                          value={values.patient}
                          onChange={handleChange}
                          className="mr-sm-2 pr-0 float-left pr-5"
                        />
                        <span
                          onClick={() =>
                            // setShowPatientSearch(is_my_appointments ? true : (selectedDoctorIdentifier ? true : false))
                            setShowPatientSearch(true)
                          }
                          className="search-icon"
                        >
                          <i className="fa fa-search"></i>
                        </span>
                        {values?.patient ? (
                          <span
                            className="clear"
                            onClick={() => {
                              formikRef.current.setFieldValue("patient", "");
                              let newUrlParams = "";
                              newUrlParams = urlActions(
                                window.location.href,
                                "delete",
                                "patient"
                              );
                              newUrlParams = urlActions(
                                window.location.protocol +
                                  "//" +
                                  window.location.host +
                                  "?" +
                                  newUrlParams,
                                "delete",
                                "patient_name"
                              );
                              window.history.pushState(
                                "",
                                "",
                                "?" + newUrlParams
                              );
                              setSelectedPatientFullName("");
                              setSelectedPatientFileNumber("");
                              setPatientDataObj(null);
                              let dataToPass = appFromCal ? appFromCal : {};
                              dataToPass["patientData"] = null;
                              appFromCalVar(dataToPass);
                              setForceFilterApply(true);
                            }}
                          >
                            <i
                              className="fa fa-times ml-2 helpers-fail-icon"
                              aria-hidden="true"
                            ></i>
                          </span>
                        ) : null}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} xl={4}>
                    <Form.Group as={Row} className="text-left text-xl-right">
                      <Form.Label column sm={3} md={3} xl={4}>
                        Status
                      </Form.Label>
                      <Col sm={12} md={9} xl={8}>
                        <Form.Control
                          autoComplete="off"
                          as="select"
                          name="status"
                          // disabled={is_my_appointments ? false : (selectedDoctorIdentifier ? false : true)}
                          value={values.status}
                          onChange={handleChangeStatus}
                        >
                          <option value="">Select Status</option>
                          {Object.entries(eventStatus).map(
                            ([key, value], index) => {
                              return (
                                <option value={key} key={index}>
                                  {value}
                                </option>
                              );
                            }
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          {myAppointmentDetailsLoading ? (
            <Preloader />
          ) : (
            <CalendarHoc
              myAppointments={true}
              eventsList={eventsList}
              selectedDoctor={selectedDoctor}
              fullCalendarRef={fullCalendarRef}
              GetEvents={GetEvents}
              eventsLoading={eventsLoading || myAppointmentDetailsLoading}
            />
          )}
        </Col>
      </Row>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />
    </Base>
  );
}
export default withRouter(MyAppointments);
