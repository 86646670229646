import React, { useState, useRef, useEffect } from "react";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import CreateEventForm from "./CreateEventForm";
import Preloader from "../../../Common/Preloder/Preloader";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "../../../Common/helpers.js";
import { withRouter } from "react-router-dom";
import {
  dataPatientInVar,
  dateEventInVar,
  appFromCalVar,
} from "../../../cache/cache";
import * as Sentry from "@sentry/browser";

const CREATE_EVENT = gql`
  mutation createEvent(
    $doctor: ID!
    $patient: ID!
    $start: DateTime!
    $title: String!
    $description: String!
    $doctorEventType: ID!
    $require_payment: Boolean!
    $discount: Float
    $waitinglist: ID
    $shouldBeSeen: DateTime
    $lastSeenDate: Date
    $initialDate: Date
    $lastDate: Date
    $calledDate: Date
    $waitingListNotes: String
  ) {
    createEvent(
      input: {
        doctor: $doctor
        patient: $patient
        start: $start
        title: $title
        description: $description
        doctorEventType: $doctorEventType
        requirePayment: $require_payment
        discount: $discount
        waitinglist: $waitinglist
        shouldBeSeen: $shouldBeSeen
        lastSeenDate: $lastSeenDate
        initialDate: $initialDate
        lastDate: $lastDate
        calledDate: $calledDate
        waitingListNotes: $waitingListNotes
      }
    ) {
      errors {
        field
        messages
      }
      obj {
        start
        end
        title
        description
        id
        patient {
          id
          identifier
          firstName
          lastName
        }
        doctor {
          identifier
        }
      }
    }
  }
`;

export const REQUEST_DOCTOR = gql`
  query {
    doctors(user_IsActive: true) {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          eventType {
            edges {
              node {
                id
                title
                duration
                buffer
                price
                notification {
                  downPaymentNotification
                  downPaymentNotificationCutoff
                }
                eventType {
                  id
                  title
                }
                eventLocation {
                  id
                  title
                }
              }
            }
          }
          overrideSchedules {
            edges {
              node {
                date
                shifts {
                  edges {
                    node {
                      startTime
                      endTime
                      id
                    }
                  }
                }
              }
            }
          }
          availability {
            edges {
              node {
                id
                day
                shifts {
                  edges {
                    node {
                      startTime
                      endTime
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const REQUEST_DOCTOR_LEAVES = gql`
  query leaves($doctor_identifier: String!) {
    leaves(doctor_Identifier: $doctor_identifier) {
      edges {
        node {
          id
          startDate
          endDate
        }
      }
    }
  }
`;

export const WAITING_LISTS = gql`
  query listWaitinglist($doctor_identifier: String!) {
    listWaitinglist(doctor_Identifier: $doctor_identifier) {
      edges {
        node {
          id
          name
          priority
          isUrgent
        }
      }
    }
  }
`;

export const REQUEST_LOCATION = gql`
  query {
    listEventLocation {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`;

export const REQUEST_EVENT_TYPE = gql`
  query {
    listEventType {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`;

const SELECTED_PATIENT_DATA = gql`
  query receivePatientData {
    dataPatient @client
  }
`;

const SELECTED_DATE_EVENT = gql`
  query receiveDate {
    dateEvent @client
  }
`;

export const REQUEST_PATIENT = gql`
  query GetPatients($searchTerm: String) {
    patients(searchText_Icontains: $searchTerm) {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          phone
        }
      }
    }
  }
`;

const CALENDAR_SLOTS = gql`
  query receiveDate {
    calendarSlots @client
  }
`;

const CreateEventFormHoc = (props) => {
  const {
    setSelectedDoctor,
    selectedDoctor,
    setDoctorLeavesList,
    fullCalendarRef,
    setSelectedSession,
    selectedSession,
    history,
    GetEvents,
  } = props;
  const { data: patientDataOfSearchPage = [] } = useQuery(
    SELECTED_PATIENT_DATA
  );
  const { data: selectedDateEvent = [] } = useQuery(SELECTED_DATE_EVENT);
  // const { data: calendarData = null } = useQuery(CALENDAR_SLOTS);
  const { data: calendarData = null } = useQuery(CALENDAR_SLOTS);
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const formikRef = useRef();
  const { data: doctors = [], loading: loadingDoctors } = useQuery(
    REQUEST_DOCTOR,
    {
      fetchPolicy: "network-only",
      onError: (err) => {
        Sentry.setContext("error", err?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: err });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: err?.networkError?.statusCode,
        });
        Sentry.captureException("doctors error " + err);
        let errorMsg = concatAllErrors(err?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );
  const { data: location = [], loading: loadingLocation } = useQuery(
    REQUEST_LOCATION,
    {
      fetchPolicy: "network-only",
      onError: (err) => {
        Sentry.setContext("error", err?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: err });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: err?.networkError?.statusCode,
        });
        Sentry.captureException("location Completed " + err);
        let errorMsg = concatAllErrors(err?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );
  const { data: eventType = [], loading: loadingEventType } = useQuery(
    REQUEST_EVENT_TYPE,
    {
      fetchPolicy: "network-only",
      onError: (err) => {
        Sentry.setContext("error", err?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: err });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: err?.networkError?.statusCode,
        });
        Sentry.captureException("eventType Completed " + err);
        let errorMsg = concatAllErrors(err?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [getWaitingList, { data: waitingListObj = null }] = useLazyQuery(
    WAITING_LISTS,
    {
      fetchPolicy: "network-only",
      onError: (err) => {
        Sentry.setContext("error", err?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: err });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: err?.networkError?.statusCode,
        });
        Sentry.captureException("waitingListObj Completed " + err);
        let errorMsg = concatAllErrors(err?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [getPatients, { data: patientList }] = useLazyQuery(REQUEST_PATIENT, {
    fetchPolicy: "network-only",
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.captureException("getPatients Completed " + err);
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [doctorLeaves] = useLazyQuery(REQUEST_DOCTOR_LEAVES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setDoctorLeavesList(data);
    },
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.captureException("doctorLeaves Completed " + err);
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  var waitingList = [];
  if (waitingListObj) {
    waitingList = waitingListObj.listWaitinglist.edges;
  }
  const [createEvent] = useMutation(CREATE_EVENT, {
    onCompleted: ({ createEvent }) => {
      if (createEvent.errors.length === 0) {
        let alert_msg = (
          <span>
            Appointment created for{" "}
            <strong>
              {createEvent.obj.patient.firstName +
                " " +
                createEvent.obj.patient.lastName}
            </strong>
            <br />
          </span>
        );
        // let patient_url = '/patient/record/' +createEvent.obj.patient.identifier;
        // history.push(patient_url);
        let docParams = "";
        if (createEvent.obj?.doctor?.identifier) {
          docParams = "&&doctor=" + createEvent.obj?.doctor?.identifier;
        }
        history.push({
          pathname: "/appointments",
          search:
            "?calendarInitDate=" +
            createEvent.obj.start.split("+")[0] +
            "&&calendarInitView=timeGridDay" +
            docParams,
        });
        openSnackbarSuccess(alert_msg, [SNACK_DURATION]);
        if (formikRef && formikRef.current) {
          formikRef.current.handleReset();
        }
        setSelectedPatient([]);
        dataPatientInVar([]);
        dateEventInVar([]);
        appFromCalVar(null);
      } else {
        var alert_msg = "";
        createEvent.errors.map((error) => {
          let messagesArr = error.messages;
          if (messagesArr) {
            alert_msg += messagesArr.join(" ");
          }
          return null;
        });
        openSnackbar(alert_msg, [SNACK_DURATION]);
      }
    },
    onError: (error) => {
      Sentry.setContext("error", error?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: error });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: { code: error?.networkError?.statusCode },
      });
      Sentry.captureException("createEvent Completed " + error);
      let errorMsg = concatAllErrors(error?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    // returned function will be called on component unmount

    // let eventformref=formikRef?formikRef.current:null;
    return () => {
      setSelectedPatient([]);
      dataPatientInVar([]);
      dateEventInVar([]);
      appFromCalVar(null);
    };
  }, []);

  if (loadingDoctors) return <Preloader />;
  if (loadingLocation) return <Preloader />;
  if (loadingEventType) return <Preloader />;

  return (
    <CreateEventForm
      createEvent={createEvent}
      patientList={patientList}
      doctorList={doctors}
      locationList={location}
      eventType={eventType}
      patientDataOfSearchPage={patientDataOfSearchPage}
      selectedDateEvent={selectedDateEvent}
      getPatients={getPatients}
      setSelectedDoctor={setSelectedDoctor}
      selectedDoctor={selectedDoctor}
      doctorLeaves={doctorLeaves}
      fullCalendarRef={fullCalendarRef}
      setSelectedSession={setSelectedSession}
      setSelectedPatient={setSelectedPatient}
      selectedPatient={selectedPatient}
      selectedSession={selectedSession}
      formikRef={formikRef}
      waitingList={waitingList}
      calendarData={calendarData}
      GetEvents={GetEvents}
      history={history}
      getWaitingList={getWaitingList}
    />
  );
};

export default withRouter(CreateEventFormHoc);
