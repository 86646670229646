import {
  Row,
  Col,
  Button,
  Form,
  Container,
  Modal,
  Table,
} from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import Base from "./base.js";
import { Formik } from "formik";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getDate,
  concatAllErrors,
} from "../Common/helpers.js";
import SelectPatientModal from "./SearchForPatient/selectPatientModal";
import Logo from "../assets/images/alawadhi_logo.jpeg";
import "./prescriptionStyles.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Sentry from "@sentry/browser";
import moment from "moment";

function PrescriptionPrint(props) {
  const [selectedPatient, setSelectedPatient] = useState({});
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  // appointment input enable/disable
  const [allMedications, setAllMedications] = useState([]);
  const [dummy, setDummy] = useState(false);
  const [blockPrintData, setBlockPrintData] = useState(null);
  const [showMedicationModal, setShowMedicationModal] = useState(false);
  const formikRef = useRef();
  const modalFormikRef = useRef();
  const [printDate, setPrintDate] = useState("");
  const [noStamp, setNoStamp] = useState(false);
  const [formMedicationValues, setFormMedicationValues] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);

  const reprintData = props?.location?.state?.reprintData;

  const patient_record = props.location.state
    ? props.location.state.patient_record
    : null;
  const isPatient = props.location.state
    ? props.location.state.isPatient
    : null;

  const [initialValues, setInitialValues] = useState({
    patient: isPatient
      ? patient_record?.node?.firstName + " " + patient_record?.node?.lastName
      : "",
    reason: "",
    order: "",
    amount: "",
    appointment: "",
    payment_method: "",
    note: "",
  });

  const REQUEST_DOCTOR = gql`
    query {
      doctors(user_IsActive: true) {
        edges {
          node {
            firstName
            lastName
            id
            email
            identifier
            doctorId
            stamp
          }
        }
      }
    }
  `;

  const CREATE_UPDATE_PRESCRIPTION = gql`
    mutation createUpdatePrescription(
      $patient: ID!
      $doctor: ID!
      $date: Date
    ) {
      createUpdatePrescription(
        input: { patient: $patient, doctor: $doctor, date: $date }
      ) {
        errors {
          field
          messages
        }
        obj {
          id
        }
      }
    }
  `;

  const CREATE_UPDATE_PRESCRIPTION_ITEM = gql`
    mutation createUpdatePrescriptionItem(
      $prescription: ID!
      $medication: String!
      $dosage: String!
      $frequency: String!
      $quantity: String!
      $route: String!
      $others: String
      $rawData: String
    ) {
      createUpdatePrescriptionItem(
        input: {
          prescription: $prescription
          medication: $medication
          dosage: $dosage
          frequency: $frequency
          quantity: $quantity
          route: $route
          others: $others
          rawData: $rawData
        }
      ) {
        errors {
          field
          messages
        }
        obj {
          id
        }
      }
    }
  `;

  const { data: doctorsList } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("doctorsList error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [createUpdatePrescription] = useMutation(CREATE_UPDATE_PRESCRIPTION, {
    onCompleted: ({ createUpdatePrescription }) => {
      if (createUpdatePrescription?.errors?.length == 0) {
        createPrescriptionItems(createUpdatePrescription.obj.id);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("createUpdatePrescription error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [createUpdatePrescriptionItem] = useMutation(
    CREATE_UPDATE_PRESCRIPTION_ITEM,
    {
      onCompleted: ({ createUpdatePrescriptionItem }) => {
        if (createUpdatePrescriptionItem?.errors?.length == 0) {
          openSnackbarSuccess("Prescription Created Successfully", [
            SNACK_DURATION,
          ]);
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        Sentry.captureException("createUpdatePrescriptionItem error " + e);

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const createPrescriptionItems = (prescId) => {
    allMedications.map((item) => {
      let variables = {
        prescription: prescId,
        medication: item.medication,
        dosage: item.dosage,
        frequency: item.frequency,
        quantity: item.quantity,
        route: item.route,
        rawData: JSON.stringify(item.rawValues),
      };

      if (item.others) {
        variables.others = item.others;
      }

      createUpdatePrescriptionItem({ variables });
    });
  };

  const doctors =
    doctorsList && doctorsList.doctors ? doctorsList.doctors.edges : [];

  const searchPatientRef = useRef();

  const onSelectPatient = (patientData) => {
    formikRef.current.setFieldValue(
      "patient",
      `${patientData.firstName} ${patientData.lastName}`
    );
    setSelectedPatient(patientData);
  };

  const handleSwitchChange = () => {
    setNoStamp(!noStamp);
  };

  const renderStamp = () => {
    if (!noStamp && blockPrintData?.doctor?.node?.stamp) {
      return (
        <img
          alt={"Logo"}
          className="img-fluid printLogo"
          style={{ width: "300px" }}
          src={blockPrintData?.doctor?.node?.stamp}
        />
      );
    }

    return null;
  };

  const editEntry = (data, index) => {
    setActiveIndex(index);
    setFormMedicationValues(data);
    setShowMedicationModal(true);
  };

  const cancelFormModal = () => {
    setShowMedicationModal(false);
    setActiveIndex(false);
  };

  const addMedicationModal = () => {
    setShowMedicationModal(true);
    setActiveIndex(null);
    setFormMedicationValues({});
  };

  const renderMedicationDetail = (medicationList) => {
    if (medicationList.length > 0) {
      return (
        <Table bordered className="w100">
          <thead>
            <tr>
              <th>
                <div>Medication</div>
              </th>
              <th>
                <div>Dosage</div>
              </th>
              <th>
                <div>Frequency</div>
              </th>
              <th>
                <div>Route</div>
              </th>
              <th>
                <div>Quantity</div>
              </th>
              <th className="no-print"></th>
            </tr>
          </thead>
          <tbody>
            {allMedications.map((singleMed, index) => {
              let freq = frequencyOptions[singleMed.frequency];

              if (singleMed.frequency === "others") {
                freq = singleMed.others;
              }

              return (
                <tr key={index}>
                  <td>{singleMed.medication}</td>
                  <td>{singleMed.dosage}</td>
                  <td>{freq}</td>
                  <td>{routeOptions[singleMed.route]}</td>
                  <td>{quantityOptions[singleMed.quantity]}</td>
                  <td className="no-print d-flex justify-content-center align-items-center border-0">
                    <Button
                      onClick={() => removeRefForms(index)}
                      className="red p-2 iconCell"
                      variant="link"
                    >
                      <i className="fa fa-times"></i>
                    </Button>
                    <Button
                      onClick={() => editEntry(singleMed.rawValues, index)}
                      className="p-2 iconCell "
                      variant="link"
                    >
                      <i className="fa fa-edit"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    }
  };
  const PrintPriscriptionComponent = () => {
    return (
      <div className=" print prescriptions-print-page mt-5">
        <div className="lab-request-header">
          <Row className="h100">
            <Col className="d-flex justify-content-start align-items-center">
              <img alt={"Logo"} className="img-fluid printLogo" src={Logo} />
            </Col>
            {/* <Col className="d-flex justify-content-end align-items-center">
              {getDate(new Date())}
            </Col> */}
          </Row>
        </div>
        <div className="page-footer">
          {/* <p className="footer-p">Tel: +965 22269308 / 22269307</p>
          <p className="footer-p">
            Mobile: +965 96914125 / 60903472 / 50593664
          </p>
          <p className="footer-p">Email: Contact@mhc-kw.com</p>
          <p className="footer-p">
            Address: 12th Floor Mazaya Clover Center, AlJabriya Block 1A, Street
            1, Kuwait
          </p> */}
          <p className="footer-p">
            Clover Center, Mazaya Tower - Floor 12, Block 1A, Bldg 198, Jabriya
            - Tel: +965 22269308 / 22269307, Mobile: +965 96914125
          </p>
          <p className="footer-p">
            www.mariamalawadhi.com - Email: contact@mariamalawadhi.com{" "}
          </p>
        </div>

        <table className="w100">
          <thead>
            <tr>
              <td>
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <Row>
              <Col sm={12}>
                <h3 className="mb-1 mt-3 text-center">
                  <b>Prescription</b>
                </h3>
              </Col>
            </Row>
            <div className="mt-5">
              {blockPrintData ? (
                <div className="mx-2 encounter-print-patient-signature margin-top-10">
                  <Row className="mb-3">
                    <Col xs={5}>
                      <h5>Patient: </h5>
                      <div>
                        Name: {blockPrintData?.patient?.firstName}{" "}
                        {blockPrintData?.patient?.lastName}
                      </div>
                      {blockPrintData?.patient.dob ? (
                        <div>
                          Date of Birth: {blockPrintData?.patient?.age}{" "}
                          {blockPrintData?.patient.dob
                            ? getDate(new Date(blockPrintData.patient.dob))
                            : null}
                        </div>
                      ) : null}
                      <div>Civil ID: {blockPrintData?.patient?.civilId}</div>
                      <div>Phone: {blockPrintData?.patient?.phone}</div>
                      <div>
                        File Number: {blockPrintData?.patient?.identifier}
                      </div>
                    </Col>
                    <Col xs={4}>
                      <h5> Practitioner: </h5>
                      <div>
                        Name: {blockPrintData?.doctor.node.firstName}{" "}
                        {blockPrintData?.doctor?.node?.lastName}
                      </div>
                    </Col>
                    <Col xs={3}>
                      <h5>Date: {getDate(new Date(printDate))}</h5>
                    </Col>
                  </Row>
                  {renderMedicationDetail(blockPrintData.medication)}
                </div>
              ) : null}
              <div className="mt-5">
                <div className="mx-2 encounter-print-patient-signature"></div>
                <div
                  className="patient-signature-container"
                  style={{ alignItems: "end", marginTop: "15px" }}
                >
                  <div>Signature:</div>

                  <div
                    className="text-field-line-signature"
                    style={{ textAlign: "center" }}
                  >
                    {renderStamp()}
                  </div>
                  <div>:التوقيع </div>
                </div>
              </div>
            </div>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  //LISTENER FOR ON SELECT ON DIAGNOSIS AND MEDICATION BLOCK
  window.addEventListener("message", function (event) {
    if (event.origin === window.location.origin) {
      if (formikRef && formikRef.current) {
        if (event.data.event_id === "medicataion") {
          let drugValue = event.data.drugFieldFinal;
          if (drugValue && event.data.drug_strengthsFinal) {
            // drugValue += ", Dosage: " + event.data.drug_strengthsFinal
            // formikRef.current.setFieldValue(event.data.fieldName, drugValue);
            modalFormikRef.current.setFieldValue("medication", drugValue);
            modalFormikRef.current.setFieldValue(
              "dosage",
              event.data.drug_strengthsFinal
            );
          } else {
            modalFormikRef.current.setFieldValue("medication", "");
            modalFormikRef.current.setFieldValue("dosage", "");
          }
        }
      }
    }
  });

  useEffect(() => {
    if (reprintData) {
      onSelectPatient(reprintData?.patient);

      // setSelectedPatient(reprintData?.patient);
      setInitialValues({
        ...initialValues,
        patient:
          reprintData?.patient.firstName + " " + reprintData?.patient.lastName,
        doctor: reprintData?.doctor.id,
      });

      formikRef.current.setFieldValue("doctor", reprintData?.doctor?.id);

      // add prescription data
      const lines = reprintData?.lines?.edges;

      if (lines?.length > 0) {
        const formattedData = lines.map((item) => {
          const node = item.node;
          const rawData = JSON.parse(node.rawData);
          const singleMedInput = {
            medication:
              rawData.unlistedMedication && rawData.strength
                ? rawData.unlistedMedication
                : node.medication,
            dosage:
              rawData.unlistedMedication && rawData.strength
                ? rawData.strength
                : node.dosage,
            frequency: rawData.frequency,
            route: rawData.route,
            quantity: rawData.quantity,
            others: node.others,
            rawValues: rawData, // Assuming modalFormikRef.current.values is equivalent to rawData
          };
          return singleMedInput;
        });

        setAllMedications(formattedData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reprintData]);

  const onSubmit = (values, { resetForm }) => {
    let doctor = null;

    if (values.reason !== "appointment") {
      doctor = values.doctor;
    } else {
      doctor = null;
    }
    // manualPayment({ variables: val });
    let doctorObj = doctorsList.doctors.edges.find(
      (i) => i?.node?.id === doctor
    );
    let dataToUse = {
      patient: selectedPatient,
      doctor: doctorObj,
      medication: allMedications,
    };

    if (!dataToUse.doctor || !dataToUse.patient) {
      openSnackbarError("Please fill all required fields", [SNACK_DURATION]);
      return;
    } else {
      if (values.calledFrom === "create") {
        createPrescriptionForPatient(dataToUse);
      } else {
        setBlockPrintData(dataToUse);
        setTimeout(() => {
          setBlockPrintData(null);
        }, 1000);
      }
    }
  };

  console.log(printDate);
  const createPrescriptionForPatient = (dataToUse) => {
    if (dataToUse.medication.length > 0) {
      let variables = {
        patient: dataToUse.patient.id,
        doctor: dataToUse?.doctor?.node?.id,
        date: printDate ? moment(printDate).format("YYYY-MM-DD") : null,
      };
      createUpdatePrescription({ variables });
    }
  };

  useEffect(() => {
    if (blockPrintData) {
      setTimeout(() => {
        window.print();
      }, 700);
    }
  }, [blockPrintData]);

  const removeRefForms = (i) => {
    let meds = allMedications;
    delete meds[i];
    setAllMedications(meds);
    setDummy(!dummy);
  };

  const medicationSubmit = () => {
    let medication = modalFormikRef.current.values["medication"];
    let unlistedMedication =
      modalFormikRef.current.values["unlistedMedication"];
    let strength = modalFormikRef.current.values["strength"];
    let dosage = modalFormikRef.current.values["dosage"];
    let frequency = modalFormikRef.current.values["frequency"];
    let others = modalFormikRef.current.values["frequencyOthers"];
    let route = modalFormikRef.current.values["route"];
    let quantity = modalFormikRef.current.values["quantity"];
    let meds = allMedications;
    if (unlistedMedication && medication) {
      openSnackbarError(
        "Please enter only one of the Medication or Unlisted medication",
        [SNACK_DURATION]
      );
      return;
    }
    let singleMedInput = {
      medication:
        unlistedMedication && strength ? unlistedMedication : medication,
      dosage: unlistedMedication && strength ? strength : dosage,
      frequency: frequency,
      route: route,
      quantity: quantity,
      others: others,
      rawValues: modalFormikRef.current.values,
    };
    if (
      singleMedInput.medication &&
      singleMedInput.dosage &&
      singleMedInput.frequency &&
      singleMedInput.route &&
      singleMedInput.quantity
    ) {
      if (activeIndex !== null) {
        meds[activeIndex] = singleMedInput;
      } else {
        meds.push(singleMedInput);
      }

      setAllMedications(meds);
      setShowMedicationModal(false);
      setActiveIndex(null);
    } else if (singleMedInput.frequency === "others" && !others) {
      openSnackbarError("Please fill all required fields test", [
        SNACK_DURATION,
      ]);
    } else {
      openSnackbarError("Please fill all required fields", [SNACK_DURATION]);
    }
  };

  const frequencyOptions = {
    once_daily: "Once daily",
    twice_daily: "Twice daily",
    three_times_a_day: "Thrice a day",
    four_times_a_days: "Four times a days",
    others: "Others",
  };

  const routeOptions = {
    per_oral: "PO",
    iv: "IV",
    im: "IM",
    id: "ID",
    nasal: "Nasal Spray",
  };

  const quantityOptions = {
    one: "1 Month",
    two: "2 Month",
    three: "3 Month",
    four: "4 Month",
    five: "5 Month",
    six: "6 Month",
    seven: "7 Month",
    eight: "8 Month",
    nine: "9 Month",
    ten: "10 Month",
    eleven: "11 Month",
    twelve: "12 Month",
  };

  return (
    <>
      <Base title={"Prescription Print"} hideBack={true} showHeader={true}>
        <Row>
          <Col xs={12} lg={10}>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              innerRef={formikRef}
            >
              {({ handleSubmit, handleBlur, handleChange, values }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Container>
                    <Row>
                      <Col sm={4}>
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={4}
                            md={4}
                            className="text-right pr-0 required"
                          >
                            Patient
                          </Form.Label>
                          <Col
                            sm={8}
                            md={8}
                            className="payment-section__patient-search"
                            ref={searchPatientRef}
                          >
                            <Form.Control
                              autoComplete="off"
                              placeholder="Select Patient"
                              type="input"
                              onBlur={handleBlur}
                              name="patient"
                              required
                              onChange={handleChange}
                              value={values.patient}
                              disabled={isPatient}
                              className="mr-sm-2 pr-0 float-left pr-5"
                            />
                            <span
                              onClick={() =>
                                isPatient
                                  ? setShowPatientSearch(false)
                                  : setShowPatientSearch(true)
                              }
                              className="search-icon"
                            >
                              <i className="fa fa-search"></i>
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={3}
                            md={3}
                            lg={4}
                            className="text-right pr-0 required"
                          >
                            Practitioner
                          </Form.Label>
                          <Col sm={12} md={9} lg={8}>
                            <Form.Control
                              autoComplete="off"
                              onBlur={handleBlur}
                              as="select"
                              name="doctor"
                              required
                              value={values.doctor}
                              onChange={handleChange}
                            >
                              <option value="">Select Practitioner</option>
                              {doctors.map((doctor) => {
                                return (
                                  <option
                                    value={doctor?.node?.id}
                                    key={doctor?.node?.id}
                                  >
                                    {doctor?.node?.firstName}{" "}
                                    {doctor?.node?.lastName}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={3}
                            md={3}
                            lg={4}
                            className="text-right pr-0 required"
                          >
                            Date
                          </Form.Label>
                          <Col sm={12} md={9} lg={8}>
                            <DatePicker
                              autoComplete="off"
                              selected={printDate}
                              placeholderText="Select Date"
                              onChange={(date) => setPrintDate(date)}
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                            />
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col sm={10}>
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={3}
                            md={3}
                            lg={2}
                            className="text-right pr-1"
                          >
                            Medication
                          </Form.Label>
                          <Col sm={12} md={9} lg={10}>
                            {allMedications
                              ? renderMedicationDetail(allMedications)
                              : null}
                            <Button
                              variant="link"
                              className="px-0 "
                              onClick={addMedicationModal}
                            >
                              <i
                                className="fa fa-plus pr-2"
                                aria-hidden="true"
                              ></i>{" "}
                              Add Medication{" "}
                            </Button>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-4 mb-2 align-items-center">
                      <Form className="mr-4">
                        <Form.Check
                          type="checkbox"
                          id="custom-switch"
                          label="Without stamp"
                          checked={noStamp}
                          onChange={handleSwitchChange}
                        />
                      </Form>

                      <Button
                        variant="primary"
                        size="sm"
                        className="mr-4"
                        onClick={() => props.history.goBack()}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        className="mr-4"
                        onClick={() => {
                          formikRef.current.setFieldValue(
                            "calledFrom",
                            "create"
                          );
                          handleSubmit();
                        }}
                      >
                        Create
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          formikRef.current.setFieldValue(
                            "calledFrom",
                            "print"
                          );
                          handleSubmit();
                        }}
                      >
                        Print
                      </Button>
                    </div>
                  </Container>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
        <SelectPatientModal
          showPatientSearch={showPatientSearch}
          setShowPatientSearch={setShowPatientSearch}
          onSelectPatientFunc={onSelectPatient}
        />
        <Modal size="lg" show={showMedicationModal} onHide={cancelFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Medication</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              onSubmit={onSubmit}
              initialValues={formMedicationValues}
              innerRef={modalFormikRef}
            >
              {({ handleSubmit, handleBlur, handleChange, values }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Container>
                    {values?.medication && values?.dosage && (
                      <Form.Group as={Row} className="red">
                        <Form.Label
                          column
                          sm={3}
                          md={3}
                          lg={2}
                          className="text-right pr-0 "
                        ></Form.Label>
                        <Col sm={12} md={9} lg={10}>
                          {values.medication} - {values?.dosage} (Use this as
                          reference)
                        </Col>
                      </Form.Group>
                    )}
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={3}
                        md={3}
                        lg={2}
                        className="text-right pr-0 "
                      >
                        Medication
                      </Form.Label>
                      <Col sm={12} md={9} lg={10}>
                        <iframe
                          title="medication"
                          src={
                            process.env.PUBLIC_URL +
                            "/medication.html?fieldName=medication"
                          }
                          id="medicalFrame"
                          className="medicationBlock"
                          frameBorder="0"
                          width="100%"
                        />
                      </Col>
                    </Form.Group>
                    <Row>
                      <Col md={6}>
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={12}
                            md={4}
                            className="text-right pr-0 "
                          >
                            Unlisted Medication
                          </Form.Label>
                          <Col sm={12} md={8}>
                            <Form.Control
                              autoComplete="off"
                              name="unlistedMedication"
                              value={values?.unlistedMedication}
                              onChange={handleChange}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={12}
                            md={4}
                            className="text-right pr-0 "
                          >
                            Strength
                          </Form.Label>
                          <Col sm={12} md={8}>
                            <Form.Control
                              autoComplete="off"
                              name="strength"
                              value={values?.strength}
                              onChange={handleChange}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={12}
                            md={4}
                            className="text-right pr-0 required"
                          >
                            Frequency
                          </Form.Label>
                          <Col sm={12} md={8}>
                            <Form.Control
                              autoComplete="off"
                              name="frequency"
                              as="select"
                              value={values?.frequency}
                              onChange={handleChange}
                              required
                            >
                              <option value="">Select Frequency</option>
                              {Object.entries(frequencyOptions).map(
                                ([key, value], index) => {
                                  return (
                                    <option value={key} key={index}>
                                      {value}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Control>
                            {values?.frequency === "others" && (
                              <Form.Control
                                placeholder="Frequency others"
                                autoComplete="off"
                                name="frequencyOthers"
                                value={values?.frequencyOthers}
                                onChange={handleChange}
                              />
                            )}
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={12}
                            md={4}
                            className="text-right pr-0 required"
                          >
                            Route
                          </Form.Label>
                          <Col sm={12} md={8}>
                            <Form.Control
                              autoComplete="off"
                              name="route"
                              as="select"
                              value={values?.route}
                              onChange={handleChange}
                              required
                            >
                              <option value="">Select Route</option>
                              {Object.entries(routeOptions).map(
                                ([key, value], index) => {
                                  return (
                                    <option value={key} key={index}>
                                      {value}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={12}
                            md={4}
                            className="text-right pr-0 required"
                          >
                            Quantity
                          </Form.Label>
                          <Col sm={12} md={8}>
                            <Form.Control
                              autoComplete="off"
                              name="quantity"
                              as="select"
                              value={values?.quantity}
                              onChange={handleChange}
                              required
                            >
                              <option value="">Select Quantity</option>
                              {Object.entries(quantityOptions).map(
                                ([key, value], index) => {
                                  return (
                                    <option value={key} key={index}>
                                      {value}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelFormModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={medicationSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </Base>
      <PrintPriscriptionComponent />
    </>
  );
}

export default PrescriptionPrint;
