import React, { useEffect } from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import Calendar from "./Calendar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "../../../Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import { editableAppointmentVar } from "cache/cache.js";
import * as Sentry from "@sentry/browser";

const SELECTED_DATE_EVENT = gql`
  query receiveDate {
    dateEvent @client
  }
`;

const CALENDAR_SLOTS = gql`
  query receiveDate {
    calendarSlots @client
  }
`;

export default function CalendarHoc(props) {
  const {
    selectedDoctor,
    doctorLeavesList,
    fullCalendarRef,
    myAppointments,
    selectedSession,
    GetEvents,
    error,
    eventsLoading,
  } = props;
  const [openSnackbar] = useSnackbar(error_options);
  const { data: calendarData = null } = useQuery(CALENDAR_SLOTS);
  const editableAppointment = useReactiveVar(editableAppointmentVar);

  const { data: selectedDateEvent = [] } = useQuery(SELECTED_DATE_EVENT, {
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.captureException("selectedDateEvent error " + err);
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    // let doctor_identifier = selectedDoctor ? selectedDoctor.node.identifier : null;
    if (selectedDoctor?.node?.identifier && fullCalendarRef?.current) {
      let calendarView = fullCalendarRef?.current?.getApi()?.view;
      let calendarEnd = calendarView.activeEnd;
      let dateStart = new Date(calendarView.activeStart);
      dateStart.setDate(1);
      let current_month = dateStart.getMonth();
      let current_year = dateStart.getFullYear();
      let dateEnd = calendarView.activeEnd;
      // if (calendarEnd > dateEnd) {
      //   dateEnd = calendarEnd;
      // }
      dateEnd.setDate(dateEnd.getDate() + 7);
      let today = new Date();
      if (!myAppointments && dateStart < today) {
        dateStart = today;
      }
      if (selectedDoctor?.node?.identifier && selectedSession) {
        GetEvents({
          variables: {
            excludeId: editableAppointment?.eventId,
            doctor_identifier: selectedDoctor.node.identifier,
            start: dateStart,
            end: dateEnd,
            loadView: calendarView.type,
          },
        });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDoctor?.node?.identifier, selectedSession]);

  if (error) return `Error! ${error.message}`;

  return (
    <Calendar
      error={error}
      myAppointments={myAppointments}
      selectedDoctor={selectedDoctor}
      selectedDateEvent={selectedDateEvent}
      doctorLeavesList={doctorLeavesList}
      fullCalendarRef={fullCalendarRef}
      selectedSession={selectedSession}
      calendarData={calendarData}
      GetEvents={GetEvents}
      eventsLoading={eventsLoading}
      editableAppointment={editableAppointment}
    />
  );
}
