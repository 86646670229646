import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import SearchForPatient from "./SearchForPatient";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "../../Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import * as Sentry from "@sentry/browser";

export const REQUEST_PATIENT = gql`
  query getPatients($search: String) {
    patients(search: $search) {
      edges {
        node {
          firstName
          lastName
          id
          dob
          email
          identifier
          phone
          doctor {
            identifier
          }
          civilId
          patientId
          discount
          waitinglist {
            edges {
              node {
                id
                event {
                  identifier
                  id
                  start
                  description
                  shouldBeSeen
                  lastSeenDate
                  doctor {
                    firstName
                    lastName
                  }
                }
                calledDate
                shouldBeSeenStart
                shouldBeSeenEnd
                waitingListNotes
                waitingList {
                  name
                }
              }
            }
          }
          patientDoctorDiscount {
            edges {
              node {
                id
                doctor {
                  identifier
                  firstName
                  lastName
                }
                discount
              }
            }
          }
        }
      }
    }
  }
`;

const SearchForPatientHoc = (props) => {
  const { isModal, onSelectPatientFunc } = props;
  const [openSnackbar] = useSnackbar(error_options);
  const [getPatients, { data }] = useLazyQuery(REQUEST_PATIENT, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("REQUEST_PATIENT error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });
  const { setShowPatientSearch } = props;
  return (
    <SearchForPatient
      getPatients={getPatients}
      data={data}
      setShowPatientSearch={setShowPatientSearch}
      isModal={isModal}
      onSelectPatientFunc={onSelectPatientFunc}
    />
  );
};

export default SearchForPatientHoc;
