import { Card, Table,Button } from 'react-bootstrap';
import React from 'react';
import { getDate, error_options,success_options,SNACK_DURATION,ERROR_MESSAGE,concatAllErrors } from "Common/helpers.js";
import { useSnackbar } from 'react-simple-snackbar';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import * as Sentry from "@sentry/browser";


function RefundRequestDetail(props) {
	/** 
	 * This component is for appointment payment details
	 * It will be a list of payments needed for this event.
	 * 
	 * It expects a prop event for its data. That should have sources
	 * **/

	const { event, REQUEST_EVENT } = props;
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);


  const MANUAL_REFUND = gql`
  mutation manualRefund(
    $refundRequest: ID!
  ) {
    manualRefund(
      input: {
        refundRequest: $refundRequest
      }
    ) {
        errors{
          field
          messages
        }
        obj{
          id
          amount
          status
          displayStatus
          notes
          dateRefunded
          refundId
        }
      }
    
  }
`;


  const [manualRefund] = useMutation(MANUAL_REFUND, {
    onCompleted: ({ manualRefund }) => {
      if (manualRefund?.errors?.length == 0 ) {
        openSnackbarSuccess('Refunded Manually', [SNACK_DURATION]);
      }
      if(manualRefund?.errors?.length>0){
        Sentry.captureException("manualRefund Completed "+manualRefund?.errors);
      }
    },
    onError: (err) => {
      Sentry.setContext('error', err?.networkError?.result);
      Sentry.setContext('ERROR CODE statusCode ', {code:err?.networkError?.statusCode});
      Sentry.setContext('ERROR OBJ ', {errorObj : err});
      Sentry.captureException("manualRefund error "+err);
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
    refetchQueries: [
      { query: REQUEST_EVENT, fetchPolicy: 'network-only' }
    ],
  });

  const postManualRefund = (refundId) => {
    console.log("refund id", refundId)
    if (window.confirm("Are you sure to Refund Manually?")) {
      manualRefund({
        variables: { refundRequest: refundId},
      });
    }
  }


	const renderRefundRequestItems = () => {
		const sources = event.refundRequests.edges;
		console.log("sourcessourcessources",sources);
		if (sources.length > 0) {
			return sources.map((item, index) => (
				<tr key={index}>
					<td className="align-middle">{item.node.refundId ? item.node.refundId : "-" }</td>
					<td className="align-middle">{item.node.amount ? item.node.amount : "-" }</td>
					<td className="align-middle">{item.node.displayStatus ? item.node.displayStatus : "-" }</td>
          <td className="align-middle">{item.node.created ? getDate(new Date(item.node.created)) : "-"}</td>
          <td className="align-middle">{item.node.dateRefunded ? getDate(new Date(item.node.dateRefunded)) : "-"}</td>
          <td className="align-middle">{item.node.notes ? item.node.notes : "-" }</td>
          <td className="align-middle">{item.node.status === "OFFLINE_REFUND" ? 
            <Button
              onClick={() => postManualRefund(item.node.id)}
              size="md"
              variant="primary"
            >
              Refund Manually
            </Button>
            : "-"}</td>
				</tr>
			));
		} else {
			return (
				<tr>
					<td colSpan={3} className="align-middle">No Refunds Requested.</td>
				</tr>
			)
		}
	}

	return (
		// TODO: always ready all static text for translations.
		<Card>
			<Card.Body>
				<Card.Title>Refund Requests</Card.Title>
				<Table responsive>
					<thead>
						<tr>
							<th>Refunded ID</th>
							<th>Amount</th>
              <th>Status</th>
							<th>Request Created</th>
              <th>Date Refunded</th>
							<th>Notes</th>
              <th>Action</th>
						</tr>
					</thead>
					<tbody>
						{renderRefundRequestItems()}
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	);

}

export default RefundRequestDetail;