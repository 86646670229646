import { Card, Table, Button, Form,} from 'react-bootstrap';
import React, { useState } from 'react';
import { getDate, error_options, success_options, SNACK_DURATION, ERROR_MESSAGE, generateUID, getValidDateStringForGraphQL,concatAllErrors } from "Common/helpers.js";
import { useSnackbar } from 'react-simple-snackbar';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import DatePicker from 'react-datepicker';
import * as Sentry from "@sentry/browser";


function ControlledPrecription(props) {
	/** 
	 * This component is for appointment payment details
	 * It will be a list of payments needed for this event.
	 * 
	 * It expects a prop event for its data. That should have sources
	 * **/

	const { patient_record, REQUEST_PATIENT, controlledPrescriptions } = props;
	const [openSnackbar] = useSnackbar(error_options);
	const [openSnackbarSuccess] = useSnackbar(success_options);
	const [prescriptionRow, setPrescriptionRow] = useState({ keys: [] });
	const [prescriptionRowValues, setPrescriptionRowValues] = useState({ values: [] });
	const [selectedDate, setSelectedDate] = useState(null);
	const [newMedicine, setNewMedicine] = useState(null);
	const [newDosage, setNewDosage] = useState(null);
	const [newFrequency, setNewFrequency] = useState(null);
	const [newQuantity, setNewQuantity] = useState(null);
	const [currentEdit, setCurrentEdit] = useState(null);

	const addPrescriptionRow = () => {
		setPrescriptionRow(prescriptionRow => ({ keys: [...prescriptionRow.keys, generateUID()] })); // giving unique ids to each row.
	}

	const removePrescriptionRow = (i) => {
		let values = prescriptionRow.keys;
		values.splice(i, 1);
		setPrescriptionRow({ keys: values });
	}

	const CREATE_UPDATE_CONTROLLED_PRESCRIPTION = gql`
		mutation createUpdateControlledPrescription(
			$patient: ID!
			$id: ID
			$date: Date!
			$medicine: String!
			$dosage: String!
			$frequency: String!
			$quantity: String!
		){
			createUpdateControlledPrescription(
			input:{
				patient: $patient
				id: $id
				date: $date
				medicine: $medicine
				dosage: $dosage
				frequency: $frequency
				quantity: $quantity
			}) 
			{
			obj{
				id
				date
				medicine
				dosage
				frequency
				quantity
		
			}
			errors {
				field
				messages
			}
			}
		}
	`;

	const DELETE_CONTROLLED_PRESCRIPTION = gql`
		mutation deleteControlledPrescription($id: ID!){
			deleteControlledPrescription(id: $id) {
			deleted
			}
		}	
	`;

	const [createUpdateControlledPrescription] = useMutation(CREATE_UPDATE_CONTROLLED_PRESCRIPTION, {
		onCompleted: ({ createUpdateControlledPrescription }) => {
			if (createUpdateControlledPrescription?.errors.length == 0) {
				openSnackbarSuccess('Prescription Updated', [SNACK_DURATION]);
				resetPrescription();
			}
		},
		onError: (e) => {
			Sentry.setContext('error', e?.networkError?.result);
			Sentry.setContext('ERROR OBJ ', {errorObj : e});
			Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
			Sentry.captureException("createUpdateControlledPrescription error "+e);
			
			let errorMsg = concatAllErrors(e?.graphQLErrors);
			let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
			openSnackbar(msgToDisplay, [SNACK_DURATION]);
		},
		refetchQueries: [
			{ query: REQUEST_PATIENT, fetchPolicy: 'network-only' }
		],
	});

	const postCreateUpdateControlledPrescription = (value) => {
		let variables = {
			patient: patient_record?.node?.id,
			date: getValidDateStringForGraphQL(value?.date),
			medicine: value?.medicine,
			dosage: value?.dosage,
			frequency: value?.frequency,
			quantity: value?.quantity,
		}
		if (currentEdit) {
			variables['id'] = currentEdit.node.id;
		}
		createUpdateControlledPrescription({
			variables: variables,
		});
		removePrescriptionRow();
	}
	const [deleteControlledPrescription] = useMutation(DELETE_CONTROLLED_PRESCRIPTION, {
		onCompleted: ({ deleteControlledPrescription }) => {
			if (deleteControlledPrescription.deleted) {
				openSnackbarSuccess('Prescription Deleted', [SNACK_DURATION]);
				resetPrescription();
			}
		},
		onError: (e) => {
			Sentry.setContext('error', e?.networkError?.result);
			Sentry.setContext('ERROR OBJ ', {errorObj : e});
			Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
			Sentry.captureException("deleteControlledPrescription error "+e);

			let errorMsg = concatAllErrors(e?.graphQLErrors);
			let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
			openSnackbar(msgToDisplay, [SNACK_DURATION]);
		},
		refetchQueries: [
			{ query: REQUEST_PATIENT, fetchPolicy: 'network-only' }
		],
	});

	const editPrescriptionRow = (item) => {
		setCurrentEdit(item);
		setNewDosage(item.node.dosage);
		setNewFrequency(item.node.frequency);
		setSelectedDate(new Date(item.node.date));
		setNewQuantity(item.node.quantity);
		setNewMedicine(item.node.medicine);
	}

	const resetPrescription = () => {
		setCurrentEdit(null);
		setNewDosage(null);
		setNewFrequency(null);
		setSelectedDate(null);
		setNewQuantity(null);
		setNewMedicine(null);
		setPrescriptionRow({ keys: [] })
	}

	const renderControlledPrescriptionItems = () => {
		const sources = controlledPrescriptions?.edges;
		if (sources?.length > 0) {
			return sources.map((item, index) => {
				return (
					currentEdit?.node?.id === item.node.id ?
						getPrescriptionRow(currentEdit, index)
						:
						<tr key={index}>
							<td className="align-middle">{item.node.date ? item.node.date : "-"}</td>
							<td className="align-middle" >{item.node.medicine ? item.node.medicine : "-"}</td>
							<td className="align-middle" >{item.node.dosage ? item.node.dosage : "-"}</td>
							<td className="align-middle" >{item.node.frequency ? item.node.frequency : "-"}</td>
							<td className="align-middle" >{item.node.quantity ? item.node.quantity : "-"}</td>
							<td className="align-middle d-flex flex-row">
								{/* {index !== enableEdit?  */}
								<Button
									onClick={() => {
										editPrescriptionRow(item);
									}}
									size="sm"
									variant="primary"
									className='mr-2'
								>
									Edit
								</Button>
								<Button
									onClick={() => deletePrescriptionRow(item.node.id, index)}
									size="sm"
									variant="danger"
									className='removeRef '
								>
									Delete
								</Button>
							</td>
						</tr>
				)
			});
		} else {
			return (
				<tr>
					<td colSpan={3} className="align-middle">No Prescriptions Yet.</td>
				</tr>
			)
		}
	}

	const savePrescriptionRow = (event, elId) => {
		let formVal = {};
		let currRef = prescriptionRowValues.values.find(i => i.id === elId);
		formVal["date"] = selectedDate;
		formVal["medicine"] = newMedicine;
		formVal["dosage"] = newDosage;
		formVal["frequency"] = newFrequency;
		formVal["quantity"] = newQuantity;
		setPrescriptionRowValues(prescriptionRowValues => ({ values: [...prescriptionRowValues.values.filter(i => i.id !== elId), formVal] }));

		postCreateUpdateControlledPrescription(formVal)
	};

	const deletePrescriptionRow = (id, index) => {
		removePrescriptionRow(index);
		console.log("indexxxx", index)
		if (id) {
			if (window.confirm("Are you sure you want to delete this Prescription?")) {
				deleteControlledPrescription({
					variables: { id: id },
				});
			}
		}
	}

	const getPrescriptionRow = (currentEdit, itemIndex) => {
		if (prescriptionRow?.keys?.length !== 0) {
			return prescriptionRow.keys.map((elId, index) => {
				return (
					<tr>
						<td>
							<Form.Group>
								<DatePicker
									name="date"
									autoComplete="off"
									input={true}
									selected={selectedDate}
									showMonthDropdown
									showYearDropdown
									onChange={(date) => setSelectedDate(date)}
									dateFormat="dd/MM/yyyy"
									className='form-control'
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Form.Control
									autoComplete="off"
									required
									as="input"
									name="medicine"
									value={newMedicine}
									onChange={(e) => setNewMedicine(e.target.value)}
								>
								</Form.Control>
							</Form.Group>
						</td>
						<td>
							<Form.Group >
								<Form.Control
									autoComplete="off"
									as="input"
									name="dosage"
									value={newDosage}
									onChange={(e) => setNewDosage(e.target.value)}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group >
								<Form.Control
									autoComplete="off"
									as="input"
									name="frequency"
									value={newFrequency}
									onChange={(e) => setNewFrequency(e.target.value)}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group >
								<Form.Control
									autoComplete="off"
									as="input"
									name="quantity"
									value={newQuantity}
									onChange={(e) => setNewQuantity(e.target.value)}
								/>
							</Form.Group>
						</td>
						<td>
							<div className="removeRef">
								<Button onClick={() => savePrescriptionRow(index)} className="mr-2" variant="success" size='sm' > Save </Button>
								<Button onClick={() => resetPrescription()} className="px-0" variant="secondary" size='sm' > Cancel </Button>
							</div>
						</td>

					</tr>
				)
			})
		}
		else {
			addPrescriptionRow();
		}
	}

	return (
		// TODO: always ready all static text for translations.
		<Card className='col-md-10 mt-5 controlled-prescription-table'>
			<Card.Body>
				<Card.Title className='d-flex flex-row justify-content-between align-items-center'>
					Controlled Prescriptions
					<div>
						<Button variant="primary" size='md' className='px-0 '
							onClick={addPrescriptionRow}
						>
							<i className="fa fa-plus pr-2" aria-hidden="true"></i> Add Prescription{' '}
						</Button>
					</div>
				</Card.Title>
				<Table responsive>
					<thead>
						<tr>
							<th>Date</th>
							<th>Medicine</th>
							<th>Dosage</th>
							<th>Frequency</th>
							<th>Quantity</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{renderControlledPrescriptionItems()}
						{prescriptionRow?.keys?.length > 0 && !currentEdit ? getPrescriptionRow() : null}
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	);

}

export default ControlledPrecription;