import { Row, Col, Button, Form, Container } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react';
import Base from './base.js';
import { Formik } from 'formik';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  paymentReason,
  concatAllErrors
} from '../Common/helpers.js';
import SelectPatientModal from './SearchForPatient/selectPatientModal';
import moment from 'moment';
import * as Sentry from "@sentry/browser";



function ManualPayment(props) {
  const [selectedPatient, setSelectedPatient] = useState({});
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  // appointment input enable/disable
  const [appointmentEnable, setAppointmentEnable] = useState(false);
  const [isPaymentManual, setIsPaymentManual] = useState(false);
  const formikRef = useRef();

  const patient_record = props.location.state ? props.location.state.patient_record : null;
  const isPatient = props.location.state ? props.location.state.isPatient : null;

  // Payment reason
  // const paymentReason = {
  //   appointment: 'Appointment',
  //   report: 'Report',
  //   phone_call: 'Phone Call',
  //   others: 'Others',
  // };
  // Payment types
  const PaymentTypes = {
    manual: 'Manual',
    online: 'Online',
  };

  const ManualPaymentTypes = {
    visa: 'VISA',
    cash: 'CASH',
    knet: 'KNET',
    master: 'MASTER CARD',
  };

  const REQUEST_UNPAID_APPOINTMENT = gql`
    query getUnpaidAppointments($patient_identifier: String!, $unpaid: Boolean!) {
      events(patient_Identifier: $patient_identifier, unpaid: $unpaid) {
        edges {
          node {
            title
            id
            start
            end
            status
            eventType{
              title
            }
            doctor {
              id
              identifier
              firstName
              lastName
            }
          }
        }
      }
    }
  `;

  const MANUAL_PAYMENT = gql`
    mutation createUpdatePayment(
      $patient: ID!
      $doctor: ID
      $reason: String!
      $order: ID!
      $amountAllocated: Float!
      $sourceType: String!
      $manualType: String
      $notes: String
    ) {
      createUpdatePayment(
        input: {
          patient: $patient
          doctor: $doctor
          reason: $reason
          appointment: $order
          amount: $amountAllocated
          paymentType: $sourceType
          manualType: $manualType
          notes: $notes
        }
      ) {
        errors {
          field
          messages
        }
        obj {
          id
          paid
        }
      }
    }
  `;

const REQUEST_DOCTOR = gql`
  query {
    doctors(user_IsActive:true) {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          doctorId
        }
      }
    }
  }
`;

  const { data: doctorsList } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy:"network-only",
    onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      Sentry.captureException("REQUEST_DOCTOR error "+e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
			let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
			openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const doctors = doctorsList && doctorsList.doctors ? doctorsList.doctors.edges : [];

  const [manualPayment] = useMutation(MANUAL_PAYMENT, {
    onCompleted: ({ createUpdatePayment }) => {
      if (createUpdatePayment.errors && createUpdatePayment.errors.length > 0) {
        let error_messages = createUpdatePayment.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbarError(error, [SNACK_DURATION]);
        }
      } else if (createUpdatePayment.obj) {
        openSnackbarSuccess('Payment Successful.');
        setAppointmentEnable(false);
        setIsPaymentManual(false);
        formikRef.current.setFieldValue('doctor', '');
        formikRef.current.handleReset();
        setSelectedPatient(null);
      }
    },
    onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      Sentry.captureException("MANUAL_PAYMENT error "+e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
			let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
			openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [getUnpaidAppointments, { data: unpaidAppointments = [] }] = useLazyQuery(
    REQUEST_UNPAID_APPOINTMENT,
    {
      fetchPolicy:"network-only",
      onError: (e) => {
        Sentry.setContext('error', e?.networkError?.result);
        Sentry.setContext('ERROR OBJ ', {errorObj : e});
        Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
        Sentry.captureException("getUnpaidAppointments error "+e);

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    },
  );

  const searchPatientRef = useRef();

  const onSelectPatient = (patientData) => {
    formikRef.current.setFieldValue('patient', `${patientData.firstName} ${patientData.lastName}`);
    setSelectedPatient(patientData);
    getUnpaidAppointments({
      variables: { patient_identifier: patientData.identifier, unpaid: true },
    });
  };

  const initialValues = {
    patient: isPatient ? patient_record.node.firstName + ' ' + patient_record.node.lastName : '',
    reason: '',
    order: '',
    amount: '',
    appointment: '',
    payment_method: '',
    note: '',
  };

  const handleChangeReason = (event) => {
    // when user change reason we enable or disable appointment
    if (event.target.value === 'appointment') {
      setAppointmentEnable(true);
    } else {
      setAppointmentEnable(false);
      // clear the appointment input
    }
    formikRef.current.setFieldValue('reason', event.target.value);
    formikRef.current.setFieldValue('appointment', '');
  };

  const handleChangePaymentMethod = (event) => {
    if (event.target.value === 'manual') {
      setIsPaymentManual(true);
    } else {
      setIsPaymentManual(false);
    }
    
    formikRef.current.setFieldValue('payment_method', event.target.value);
    // formikRef.current.setFieldValue('appointment', '');
  }
  
  const onSubmit = (values, { resetForm }) => {
    let doctor = null;
    
    if (values.reason !== 'appointment') {
      doctor = values.doctor;
    } else {
      doctor = null;
    }

    let val = {
      patient: isPatient ? patient_record.node.id : selectedPatient.id,
      doctor: doctor,
      reason: values.reason,
      order: values.appointment,
      amountAllocated: values.amount,
      sourceType: values.payment_method,
      manualType: values.manual_type,
      notes: values.note,
    };
    manualPayment({ variables: val });
    // setIsPaymentManual(false);
    // setAppointmentEnable(false);
    // formikRef.current.setFieldValue('doctor', '');
  };

  useEffect(() => {
    if (patient_record) {
      getUnpaidAppointments({
        variables: { patient_identifier: patient_record?.node?.identifier, unpaid: true },
      });
    }
  }, [patient_record])

  const renderAppointmentDropdown = () => {
    if (unpaidAppointments && unpaidAppointments.events) {
      const notCancelledAppointments = unpaidAppointments.events.edges.filter((item) => {
        return item.node.status !== 'CANCELLED';
      })

      const sortedApps = notCancelledAppointments.sort((a,b) => {
        return new Date(b.node.start) - new Date(a.node.start)
      })

      return sortedApps.map((app, index) => {
        // let date = new Date(app.node.start);
        let time = new Date(app.node.start);
        let dateend = new Date(app.node.end);
        // let app_name =`${app.node.title} (${app.node.eventType.title}) - ${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        let date = moment(app.node.start)
        // let dateend = moment(app.node.end)
        let app_name =`${app.node.doctor.firstName} ${app.node.doctor.lastName} (${app.node.eventType.title}) - ${moment(date).format("DD/MM/YYYY - hh:mm A")}`;
        
        return (
          <option value={app.node.id} key={index}>
            {app_name}
          </option>
        );
      });
    }
  };

  return (
    <Base title={'Manual Payment'} showHeader={true}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={10} xl={7}>
          <Formik onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef}>
            {({ handleSubmit, handleBlur, handleChange, values }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Container>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0">
                      Patient
                    </Form.Label>
                    <Col
                      sm={8}
                      md={8}
                      className="payment-section__patient-search"
                      ref={searchPatientRef}>
                      <Form.Control
                        autoComplete="off"
                        placeholder="Select Patient"
                        type="input"
                        onBlur={handleBlur}
                        name="patient"
                        required
                        onChange={handleChange}
                        value={values.patient}
                        disabled={isPatient}
                        className="mr-sm-2 pr-0 float-left pr-5"
                      />
                      <span onClick={() => isPatient ? setShowPatientSearch(false) : setShowPatientSearch(true)} className="search-icon">
                        <i className="fa fa-search"></i>
                      </span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0">
                      Reason 
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="select"
                        name="reason"
                        value={values.reason}
                        onChange={handleChangeReason}
                        required>
                        <option value="">Select reason</option>
                        {Object.entries(paymentReason).map(([key, value], index) => {
                          return (
                            <option value={key} key={index}>
                              {value}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  {!appointmentEnable ? 
                    <Form.Group as={Row}>
                      <Form.Label column sm={3} md={3} lg={4} className="text-right pr-0">
                        Practitioner
                      </Form.Label>
                      <Col sm={12} md={9} lg={8}>
                        <Form.Control
                          autoComplete="off"
                          onBlur={handleBlur}
                          as="select"
                          name="doctor"
                          value={values.doctor}
                          onChange={handleChange}>
                          <option value="">Select Practitioner</option>
                          {doctors.map((doctor) => {
                            return (
                              <option value={doctor.node.id} key={doctor.node.id}>
                                {doctor.node.firstName} {doctor.node.lastName}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                   : null}
                    {appointmentEnable ?
                      <Form.Group as={Row}>
                        <Form.Label column sm={4} md={4} className="text-right pr-0">
                          Appointment
                        </Form.Label>
                        <Col sm={8} md={8}>
                          <Form.Control
                            autoComplete="off"
                            as="select"
                            onBlur={handleBlur}
                            name="appointment"
                            value={values.appointment}
                            onChange={handleChange}
                            disabled={!appointmentEnable}>
                            <option>Select appointment</option>
                            {renderAppointmentDropdown()}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    : null}
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0">
                      Amount 
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="number"
                        onBlur={handleBlur}
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0">
                      Payment Method
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="select"
                        onBlur={handleBlur}
                        name="payment_method"
                        value={values.payment_method}
                        // onChange={handleChange}
                        onChange={handleChangePaymentMethod}
                        required>
                        <option value="">Select payment method</option>
                        {Object.entries(PaymentTypes).map(([key, value], index) => {
                          return (
                            <option value={key} key={index}>
                              {value}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  {isPaymentManual ?
                    <Form.Group as={Row}>
                      <Form.Label column sm={4} md={4} className="text-right pr-0">
                        Manual Payment Type
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="select"
                          onBlur={handleBlur}
                          name="manual_type"
                          value={values.manual_type}
                          onChange={handleChange}
                          disabled={!isPaymentManual}
                        >
                          <option>Select Manual Payment Type</option>
                          {Object.entries(ManualPaymentTypes).map(([key, value], index) => {
                            return (
                              <option value={key} key={index}>
                                {value}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  : null}
                  <Form.Group as={Row}>
                    <Form.Label column  sm={12} md={4} className="text-right pr-0">
                      Note
                    </Form.Label>
                    <Col sm={12} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="textarea"
                        rows={5}
                        type="textarea"
                        onBlur={handleBlur}
                        name="note"
                        value={values.note}
                        onChange={handleChange}
                        // required
                      />
                    </Col>
                  </Form.Group>
                  <div className="d-flex justify-content-between mt-4 mb-2">
                    <Button variant="primary" size="sm" onClick={() => props.history.goBack()}>
                      Cancel
                    </Button>
                    <Button variant="primary" size="sm" type="submit">
                      Save
                    </Button>
                  </div>
                </Container>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />
    </Base>
  );
}
export default ManualPayment;
