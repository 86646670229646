import React, { useEffect, useState } from "react";
import { Button, Table, Form, Row } from "react-bootstrap";
import Base from "./base.js";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import Preloader from "../Common/Preloder/Preloader";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getForamttedTime,
  getDate,
  concatAllErrors,
} from "../Common/helpers.js";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import { Link } from "react-router-dom";

export function getYearsRange() {
  const years = [];
  for (let year = 2015; year <= 2035; year++) {
    years.push(year);
  }
  return years;
}

export default function WaitingList(props) {
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [search, setSearch] = useState("");

  const { history } = props;
  const [activeDetailID, setActiveDetailID] = useState(null);
  const [activeWaitingList, setActiveWaitingList] = useState(null);

  const [seenStart, setSeenStart] = useState(null);
  const [seenEnd, setSeenEnd] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const months = [
    { key: 1, value: "January" },
    { key: 2, value: "February" },
    { key: 3, value: "March" },
    { key: 4, value: "April" },
    { key: 5, value: "May" },
    { key: 6, value: "June" },
    { key: 7, value: "July" },
    { key: 8, value: "August" },
    { key: 9, value: "September" },
    { key: 10, value: "October" },
    { key: 11, value: "November" },
    { key: 12, value: "December" },
  ];

  var waiting_list = [];

  const REQUEST_DOCTOR = gql`
    query {
      doctors(user_IsActive: true) {
        edges {
          node {
            firstName
            lastName
            id
            email
            identifier
            doctorId
          }
        }
      }
    }
  `;

  const { data: doctors = [] } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (err) => {
      Sentry.setContext("error", err?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: err });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: err?.networkError?.statusCode,
      });
      Sentry.captureException("doctors error " + err);
      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const onClickToCreateAppointment = (e) => {
    e.preventDefault();

    history.push({ pathname: "/create/appointment" });
  };

  const WAITING_LISTS = gql`
    query ($doctor_identifier: String!) {
      listWaitinglist(doctor_Identifier: $doctor_identifier) {
        edges {
          node {
            id
            name
            priority
            isUrgent
            patients {
              totalCount
            }
          }
        }
      }
    }
  `;

  const WAITING_DETAIL = gql`
    query waitingDetail(
      $waitingList: ID
      $seenStart: Date
      $seenEnd: Date
      $search: String
      $doctorId: String
      $orderBy: String
      $year: String
      $month: String
    ) {
      waitingListRecords(
        waitingList: $waitingList
        seenStart: $seenStart
        seenEnd: $seenEnd
        search: $search
        doctorId: $doctorId
        orderBy: $orderBy
        year: $year
        month: $month
      ) {
        totalCount
        edges {
          node {
            id
            patient {
              firstName
              lastName
              identifier
              phone
            }
            event {
              identifier
              id
              start
              description
              shouldBeSeen
              lastSeenDate
              doctor {
                firstName
                lastName
              }
            }
            calledDate
            shouldBeSeenStart
            shouldBeSeenEnd
            waitingListNotes
          }
        }
      }
    }
  `;

  const DELETE_WAITING_RECORD = gql`
    mutation deleteWaitinglistRecord($id: ID!) {
      deleteWaitinglistRecord(id: $id) {
        deleted
      }
    }
  `;

  useEffect(() => {
    const payload = {
      doctorId: selectedDoctor?.node?.doctorId,
      search: search,
      waitingList: activeDetailID,
      seenStart: seenStart ? moment(seenStart).format("YYYY-MM-DD") : null,
      seenEnd: seenEnd ? moment(seenEnd).format("YYYY-MM-DD") : null,
      year,
      month,
    };

    if (activeWaitingList) {
      if (activeWaitingList.isUrgent) {
        payload.orderBy = "urgent";
      } else if (activeWaitingList?.name?.toLowerCase().includes("new")) {
        payload.orderBy = "urgent";
      } else {
        payload.orderBy = "noturgent";
      }
    } else {
      payload.orderBy = "lastseen";
    }

    if (selectedDoctor?.node?.doctorId) {
      getWaitingListDetail({
        variables: payload,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeDetailID,
    search,
    seenStart,
    selectedDoctor?.node?.doctorId,
    seenEnd,
    year,
    month,
  ]);

  const [deleteWaitingRecord] = useMutation(DELETE_WAITING_RECORD, {
    onCompleted: ({ deleteWaitinglistRecord }) => {
      if (deleteWaitinglistRecord?.deleted) {
        openSnackbarSuccess("Deleted Waiting Record", [SNACK_DURATION]);
        setSelectedDoctor(selectedDoctor);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("deleteWaitingRecord error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [getWaitingList, { data: waitingListObj = null }] = useLazyQuery(
    WAITING_LISTS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        Sentry.captureException("waitingListObj error " + e);
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  if (waitingListObj) {
    waiting_list = waitingListObj.listWaitinglist.edges;
  }

  const [
    getWaitingListDetail,
    { data: waitingDetail, loading: detailLoading },
  ] = useLazyQuery(WAITING_DETAIL, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("getWaitingListDetail error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const handleSelect = (event) => {
    const value = event.target.value;

    setActiveDetailID(value);
    const waitValue = waiting_list?.find((item) => item.node.id === value);

    setActiveWaitingList(waitValue?.node);
    setYear(null);
    setMonth(null);
  };

  const deleteWaitingRecordClicked = (id) => {
    if (window.confirm("Are you sure to delete this waiting list record?")) {
      deleteWaitingRecord({
        variables: { id: id },
      });
    }
  };

  const renderDetailTable = (waitingDetail) => {
    let patients =
      waitingDetail && waitingDetail.waitingListRecords
        ? waitingDetail.waitingListRecords
        : [];
    if (detailLoading) {
      return <Preloader />;
    }
    if (patients.edges && patients.edges.length > 0) {
      return (
        <Table
          striped
          bordered
          responsive
          className="table waiting_table text-xs-left"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th scope="col" style={{ verticalAlign: "middle" }}>
                No.
              </th>
              <th scope="col" style={{ verticalAlign: "middle" }}>
                Patient file number
              </th>
              <th scope="col" style={{ verticalAlign: "middle" }}>
                Patient name
              </th>
              <th scope="col" style={{ verticalAlign: "middle" }}>
                Phone number
              </th>

              {activeWaitingList?.name?.toLowerCase().includes("new") ? (
                <th scope="col" style={{ verticalAlign: "middle" }}>
                  Called date
                </th>
              ) : (
                <th scope="col" style={{ verticalAlign: "middle" }}>
                  Last seen
                </th>
              )}

              {activeWaitingList?.isUrgent && (
                <th scope="col" style={{ verticalAlign: "middle" }}>
                  Called date
                </th>
              )}
              {activeWaitingList?.isUrgent === false && (
                <>
                  <th scope="col" style={{ verticalAlign: "middle" }}>
                    Should be seen start date
                  </th>
                  <th scope="col" style={{ verticalAlign: "middle" }}>
                    Should be seen end date
                  </th>
                </>
              )}

              <th scope="col" style={{ verticalAlign: "middle" }}>
                Remarks
              </th>
              <th scope="col" style={{ verticalAlign: "middle" }}>
                Next Appointment
              </th>
              <th scope="col" style={{ verticalAlign: "middle" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {patients.edges.map((patientobj, index) => {
              let next_app = new Date(patientobj.node.event.start);
              const appIdentifier = patientobj.node.event.identifier;

              const lastSeen = patientobj.node.event.lastSeenDate
                ? new Date(patientobj.node.event.lastSeenDate)
                : null;

              const startDate = patientobj.node.shouldBeSeenStart
                ? new Date(patientobj.node.shouldBeSeenStart)
                : null;

              const calledDate = patientobj.node.calledDate
                ? new Date(patientobj.node.calledDate)
                : null;

              const endDate = patientobj.node.shouldBeSeenEnd
                ? new Date(patientobj.node.shouldBeSeenEnd)
                : null;
              return (
                <tr key={index}>
                  <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    <Link
                      target="_blank"
                      to={
                        "/patient/record/" + patientobj.node.patient.identifier
                      }
                    >
                      {patientobj.node.patient.identifier}
                    </Link>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <Link
                      target="_blank"
                      to={
                        "/patient/record/" + patientobj.node.patient.identifier
                      }
                    >
                      {`${patientobj.node.patient.firstName} ${patientobj.node.patient.lastName}`}
                    </Link>
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    {patientobj.node.patient.phone || "-"}
                  </td>

                  {activeWaitingList?.name?.toLowerCase().includes("new") ? (
                    <td style={{ verticalAlign: "middle" }}>
                      {calledDate ? getDate(calledDate) : "-"}
                    </td>
                  ) : (
                    <td style={{ verticalAlign: "middle" }}>
                      {lastSeen ? getDate(lastSeen) : "-"}
                    </td>
                  )}

                  {activeWaitingList?.isUrgent && (
                    <td style={{ verticalAlign: "middle" }}>
                      {calledDate ? getDate(calledDate) : "-"}
                    </td>
                  )}

                  {activeWaitingList?.isUrgent === false && (
                    <>
                      <td style={{ verticalAlign: "middle" }}>
                        {startDate ? getDate(startDate) : "-"}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {endDate ? getDate(endDate) : "-"}
                      </td>
                    </>
                  )}
                  <td style={{ verticalAlign: "middle" }}>
                    {patientobj.node.waitingListNotes}
                  </td>

                  <td style={{ verticalAlign: "middle" }}>
                    {getDate(next_app)}
                    <br />
                    {getForamttedTime(next_app)}
                    <br />({appIdentifier})
                  </td>

                  <td style={{ verticalAlign: "middle" }}>
                    <Button
                      variant="link"
                      className="danger-color"
                      onClick={() =>
                        deleteWaitingRecordClicked(patientobj.node.id)
                      }
                    >
                      Delete
                    </Button>
                    <Button
                      variant="link"
                      onClick={() => editAppointment(patientobj.node.event.id)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    }
    return "No patient in this list";
  };

  const handleDoctor = (event) => {
    const activeDoctor = doctors?.doctors?.edges?.find(
      (item) => item.node.identifier === event.target.value
    );

    setSelectedDoctor(activeDoctor);
    setActiveDetailID(null);
    setYear(null);
    setMonth(null);
    setActiveWaitingList(null);

    if (activeDoctor) {
      getWaitingList({
        variables: { doctor_identifier: activeDoctor.node.identifier },
      });
    }
  };

  const editAppointment = (id) => {
    const url = `/create/appointment?editID=${id}`;
    window.open(url, "_blank");
  };

  const handleDateChange = (type, date) => {
    const payload = {
      waitingList: activeDetailID,
      search: search,
      doctorId: selectedDoctor.node.doctorId,
    };

    if (seenStart) {
      payload.seenStart = moment(seenStart).format("YYYY-MM-DD");
    }

    if (seenEnd) {
      payload.seenEnd = moment(seenEnd).format("YYYY-MM-DD");
    }

    if (type === "start") {
      setSeenStart(date);
      payload.seenStart = date ? moment(date).format("YYYY-MM-DD") : null;
    } else {
      setSeenEnd(date);
      payload.seenEnd = date ? moment(date).format("YYYY-MM-DD") : null;
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const renderWaitingList = () => {
    if (!selectedDoctor) {
      return "Please select specific doctor to proceed.";
    } else {
      return <>{renderDetailTable(waitingDetail)}</>;
    }
  };

  return (
    <Base title={"Waiting List"} showHeader={true}>
      <>
        <Button onClick={onClickToCreateAppointment}>
          <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>
          Create entry to waiting list
        </Button>
        <Form.Group as={Row}>
          <Form.Label column md={3}>
            Practitioner
            <Form.Control
              autoComplete="off"
              as="select"
              name="doctor"
              value={selectedDoctor ? selectedDoctor.node.identifier : null}
              onChange={handleDoctor}
              column
              md={8}
            >
              <option value="">Select Practitioner</option>
              {doctors &&
                doctors?.doctors?.edges?.map((doctor) => {
                  return (
                    <option value={doctor.node.identifier} key={doctor.node.id}>
                      {doctor.node.firstName} {doctor.node.lastName}
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Label>
          <Form.Label column md={3}>
            Waiting list
            <Form.Control
              autoComplete="off"
              as="select"
              name="waiting"
              value={activeDetailID}
              onChange={handleSelect}
              column
              md={8}
              disabled={!selectedDoctor}
            >
              <option value="">Select waiting list</option>
              {waiting_list &&
                waiting_list?.map((item, index) => {
                  return (
                    <option value={item.node.id} key={index}>
                      {item.node.name}({item.node.patients.totalCount})
                    </option>
                  );
                })}
            </Form.Control>
          </Form.Label>
          <Form.Label column md={3}>
            Search
            <Form.Control
              autoComplete="off"
              type="text"
              name="search"
              value={search}
              onChange={handleSearch} // Use handleInputChange
              placeholder="File #/CivilID/Phone or Appointment ID"
              column
              md={8}
              disabled={!selectedDoctor}
            />
          </Form.Label>
          <Form.Label column md={3}>
            Total number
            <h4>
              <strong>
                {waitingDetail?.waitingListRecords?.totalCount || 0}
              </strong>
            </h4>
          </Form.Label>
        </Form.Group>

        {activeWaitingList?.isUrgent === false && (
          <>
            <Form.Group as={Row}>
              <Form.Label column md={3}>
                Filter by month
                <Form.Control
                  autoComplete="off"
                  as="select"
                  name="month"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  column
                  md={8}
                  // disabled={!selectedDoctor}
                >
                  <option value="">Select month</option>
                  {months?.map((item, index) => {
                    return (
                      <option value={item.key} key={item.key}>
                        {item.value}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Label>
              <Form.Label column md={3}>
                Filter by year
                <Form.Control
                  autoComplete="off"
                  as="select"
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  column
                  md={8}
                  // disabled={!selectedDoctor}
                >
                  <option value="">Select year</option>
                  {getYearsRange()?.map((item, index) => {
                    return (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Label>
            </Form.Group>
          </>
        )}

        {renderWaitingList()}
      </>
    </Base>
  );
}
