import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, Container } from 'react-bootstrap';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, SNACK_DURATION, ERROR_MESSAGE ,getPermissionForAction,getValidDateStringForGraphQL,concatAllErrors, validateDecimal} from '../../Common/helpers.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Sentry from "@sentry/browser";



const CreateNewPatient = (props) => {
  const [openSnackbar] = useSnackbar(error_options);
  const { createUpdatePatient, UpdatePatient, history, is_edit, formikRef } = props;
  const [editPatient, setEditPatient] = useState(null);
  const identifier = props.match.params.identifier;
  const [firstVisitDate, setFirstVisitDate] = useState('');
  const [startDate, setStartDate] = useState('');

  const REQUEST_PATIENT = gql`
    query {
      patients(identifier:"${identifier}"){
        edges {
          node {
            firstName
            lastName
            dob
            id
            phone
            displayGender
            civilId
            additionalNotes
            doctor{
              id
            }
            dateFirstVisit
            firstVisit
            address
            governorate
            referredBy
            discount
            impressionDiagnosis
            medication
            identifier
            paymentContact
            paymentContactDetails
            
          }
        }
      }
    }
  `;

  const REQUEST_DOCTOR = gql`
    query {
      doctors(user_IsActive:true) {
        edges {
          node {
            firstName
            lastName
            id
            identifier
          }
        }
      }
    }
  `;

  const { data } = useQuery(REQUEST_PATIENT, {
    fetchPolicy:"network-only",
    onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      Sentry.captureException("REQUEST_PATIENT error "+e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const { data: doctorsList } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy:"network-only",
    onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      Sentry.captureException("REQUEST_DOCTOR error "+e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const doctors = doctorsList && doctorsList.doctors ? doctorsList.doctors.edges : [];

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  useEffect(() => {
    if (is_edit && data && data.patients && data.patients.edges[0]) {
      setEditPatient(data.patients.edges[0].node);
      console.log("data",data)
      let first_visit_date = '';
      if(data.patients.edges[0].node?.dateFirstVisit){
        first_visit_date=new Date(data.patients.edges[0].node?.dateFirstVisit);
        if (!isValidDate(first_visit_date)){
          first_visit_date = "";
        }
      }
      setFirstVisitDate(first_visit_date);
    }
  }, [is_edit, data]);

  const onSubmit = (values) => {
    let dateFirstVisit= firstVisitDate;
    if(dateFirstVisit){
      dateFirstVisit.setHours(10);
      dateFirstVisit=dateFirstVisit.toISOString();
    }
    let val = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone_number,
      gender: values.gender,
      civil_id: values.civil_id,
      doctor: values.doctor,
      address: values.address,
      governorate: values.governorate,
      referred_by: values.referred_by,
      diagnosis: values.diagnosis,
      medication: values.medication,
      file_number: values.file_number,
      first_visit: values.first_visit,
      dateFirstVisit:dateFirstVisit,
      identifier: values.file_number,
      discount: values.discount_value? values.discount_value:0.00,
      paymentContact:values.payment_contact,
      paymentContactDetails:values.payment_contact_details,
      additional_notes:values.additional_notes,
    };
    let variables = val;
    console.log('variables', variables);
    if(startDate){
      variables['dob'] = startDate?getValidDateStringForGraphQL(new Date(startDate)):'';
    }
    if (is_edit && editPatient) {
      variables['id'] = editPatient.id;
      UpdatePatient({ variables: variables });
    } else {
      createUpdatePatient({
        variables: variables,
      });
    }
    // if (values.forwardToPatient) {
    //   setTimeout(() => {history.push('/create/appointment')}, 1200)
    // }
    
  };

  var initialValues = {
    firstName: '',
    lastName: '',
    dob: '',
    phone_number: '',
    gender: '',
    civil_id: '',
    doctor: '',
    address: '',
    governorate: '',
    referred_by: '',
    diagnosis: '',
    medication: '',
    file_number: '',
    first_visit_date:'',
    discount_value: '0.00',
    first_visit: false,
    file_show: false,
    show_discount: false,
  };

  if (editPatient) {
    initialValues['firstName'] = editPatient.firstName;
    initialValues['lastName'] = editPatient.lastName;
    initialValues['phone_number'] = editPatient.phone;
    initialValues['gender'] = editPatient?.displayGender?.toLowerCase();
    initialValues['civil_id'] = editPatient.civilId;
    if(editPatient.doctor){
      initialValues['doctor'] = editPatient.doctor.id;
    }
    initialValues['address'] = editPatient.address;
    initialValues['governorate'] = editPatient.governorate;
    initialValues['referred_by'] = editPatient.referredBy;
    initialValues['diagnosis'] = editPatient.impressionDiagnosis;
    initialValues['medication'] = editPatient.medication;
    initialValues['file_number'] = editPatient.identifier;
    initialValues['additional_notes'] = editPatient.additionalNotes;
    // initialValues['first_visit'] = editPatient.firstVisit;
    initialValues['first_visit_date'] = editPatient.dateFirstVisit;
    initialValues['discount_value'] = editPatient.discount;
    initialValues['payment_contact'] = editPatient.paymentContact;
    initialValues['payment_contact_details'] = editPatient.paymentContactDetails;
  }

  useEffect(() => {
    if (editPatient?.dob) {
      setStartDate(new Date(editPatient.dob));
    }
  }, [editPatient]);

  const has_appointment_add_permission=getPermissionForAction("appointments","add");
  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={11}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formikRef}>
        {({ handleSubmit, handleChange, values, errors, handleBlur, touched, submitForm, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Container>
              <Row className="text-center">
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className='pl-0 pr-0'>
                  <Form.Group as={Row} className="text-right ">
                    <Form.Label xs={12} column sm={4} md={5} className="required">
                      Patient First Name
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        required
                        autoComplete="off"
                        type="text"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName || ''}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5} className="required">
                      Patient Last Name
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        required
                        autoComplete="off"
                        type="text"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName || ''}
                      />
                    </Col>
                  </Form.Group>
                  {/* <Form.Group as={Row}>
                    <Form.Label column sm={4} className="text-right">
                      Email
                    </Form.Label>
                    <Col sm={7}>
                      <Form.Control
                        required
                        autoComplete="off"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        value={values.email || ''}
                      />
                    </Col>
                  </Form.Group> */}
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Date Of Birth
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      {console.log("values",values)}
                      <DatePicker
                        name="dob"
                        autoComplete="off"
                        input = {true}
                        selected={startDate}
                        showMonthDropdown
                        onBlur={handleBlur}
                        showYearDropdown
                        onChange={(date) => setStartDate(date)}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                      />
                      <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5} className="required">
                      Gender
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        onBlur={handleBlur}
                        as="select"
                        name="gender"
                        value={values.gender}
                        required
                        onChange={handleChange}>
                        <option value="">Select Gender</option>
                        <option value={'female'}>Female</option>
                        <option value={'male'}>Male</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Civil ID
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        onBlur={handleBlur}
                        name="civil_id"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.civil_id || ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.civil_id}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5} className="required">
                      Phone Number
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        required
                        onBlur={handleBlur}
                        name="phone_number"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.phone_number || ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone_number}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Payment Contact
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        onBlur={handleBlur}
                        name="payment_contact"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.payment_contact || ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone_number}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group  as={Row} className="text-right">
                    <Form.Label  column xs={12} sm={4} md={5}>Notes</Form.Label>
                    <Col xs={12} sm={8} md={7}>
                    <Form.Control
                      autoComplete="off"
                      as="textarea"
                      rows={2}
                      name="additional_notes"
                      value={values.additional_notes}
                      onChange={handleChange}
                      />
                      </Col>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className='pl-0 pr-0'>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5} className="required">
                      Practitioner
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        onBlur={handleBlur}
                        as="select"
                        name="doctor"
                        value={values.doctor}
                        required
                        onChange={handleChange}>
                        <option value="">Select Practitioner</option>
                        {doctors.map((doctor) => {
                          return (
                            <option value={doctor.node.id} key={doctor.node.id}>
                              {doctor.node.firstName} {doctor.node.lastName}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Address
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        onBlur={handleBlur}
                        name="address"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.address || ''}
                      />
                      <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Governorate
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        onBlur={handleBlur}
                        name="governorate"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.governorate || ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.governorate}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5} className="required"> 
                      Referred By
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        required
                        onBlur={handleBlur}
                        name="referred_by"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.referred_by || ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.referred_by}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Impression / Diagnosis
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="text"
                        onBlur={handleBlur}
                        name="diagnosis"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.diagnosis || ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.diagnosis}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Medication
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        onBlur={handleBlur}
                        name="medication"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.medication ? values.medication : ''}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.medication}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group  as={Row} className="text-right">
                    <Form.Label  column xs={12} sm={4} md={5}>Payment Contact Details</Form.Label>
                    <Col xs={12} sm={8} md={7}>
                    <Form.Control
                      autoComplete="off"
                      as="textarea"
                      rows={2}
                      name="payment_contact_details"
                      value={values.payment_contact_details}
                      onChange={handleChange}
                      />
                      </Col>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className='pl-0 pr-0'>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Change Discount
                    </Form.Label>
                    <Col  sm={8} md={7} className="text-left pst-absolute-125px">
                      <Form.Check
                        className="mt-2"
                        type="checkbox"
                        value={values.show_discount}
                        name="show_discount"
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      View File Number
                    </Form.Label>
                    <Col sm={8} md={7} className="text-left pst-absolute-125px">
                      <Form.Check
                        className="mt-2"
                        type="checkbox"
                        value={values.file_show}
                        name="file_show"
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  {/* <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      First visit in Clinic
                    </Form.Label>
                    <Col sm={8} md={7} className="text-left pst-absolute" >
                      <Form.Check
                        className="mt-2"
                        type="checkbox"
                        value={values.first_visit}
                        name="first_visit"
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group> */}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className='pl-0 pr-0'>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      Discount %
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <Form.Control
                        type="number"
                        step="any"
                        onInput={(e)=>validateDecimal(e)}
                        disabled={!values.show_discount}
                        onBlur={handleBlur}
                        name="discount_value"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.discount_value}
                      />
                    </Col>
                  </Form.Group>
                  {values.file_show ? (
                    <Form.Group as={Row} className="text-right">
                      <Form.Label column xs={12} sm={4} md={5}>
                        File Number
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          type="text"
                          onBlur={handleBlur}
                          name="file_number"
                          autoComplete="off"
                          onChange={handleChange}
                          value={values.file_number ? values.file_number : ''}
                        />
                      </Col>
                    </Form.Group>
                  ) : null}
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column xs={12} sm={4} md={5}>
                      First Visit Date
                    </Form.Label>
                    <Col xs={12} sm={8} md={7}>
                      <DatePicker
                        autoComplete="off"
                        selected={firstVisitDate}
                        onChange={(date) => setFirstVisitDate(date)}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className={'button_block mb-2'} >
                <Col className="justify-content-center d-flex mt-2" xs={{ order: 'last' }}>
                  <Button variant="primary" size="md" active onClick={() => history.goBack()}>
                    Cancel
                  </Button>
                </Col>
                <Col className="justify-content-center d-flex mt-2">
                  <Button
                    type="submit"
                    variant="primary"
                    size="md"
                    active
                    name={!is_edit ? 'add' : 'edit'}>
                    {!is_edit ? 'Save and add Another' : 'Save'}
                  </Button>
                </Col>
                {!is_edit && has_appointment_add_permission? (
                  <Col className="justify-content-center d-flex mt-2" xs={{ order: 'first' }}>
                    <Button variant="primary" type="submit" size="md" active name="appointment"
                    onClick={() => 
                      {
                        setFieldValue('forwardToPatient', true);
                        // handleSubmit(e);
                      }
                    }>
                      Save and Create Appointment
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
    </Col>
  );
};
export default withRouter(CreateNewPatient);
