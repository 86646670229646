import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Form, Card, Button, Table } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import Base from "./base.js";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getDate,
  concatAllErrors
} from "../Common/helpers";
import SelectPatientModal from "./SearchForPatient/selectPatientModal";
import { urlBackend } from "../index";
import Preloader from "../Common/Preloder/Preloader";
import Logo from "../assets/images/alawadhi_logo.jpeg";
import * as Sentry from "@sentry/browser";
import PaymentDetail from 'component/AppointmentDetails/unpaidpaymentDetail';

// var today = new Date();
// today = getDate(today) + ' ' + getForamttedTime(today);

const PaymentsPrintDetail = (props) => {
  const paymentId = props.match.params.id;
  const { item } = props.location.state;
  const [paymentCreatedDate, setPaymentCreatedDate] = useState();


  const renderPatientInfo = () => {
    return (
      <div>
        {item ? (
          <div>
        <div className='mt-5 print'>
            <div className="d-flex justify-content-between" style={{marginBottom: '50px'}}>
              <div className="payment-cont">
                {/* <div className="payment-number">1234</div> */}

              </div>
              <div className='patient-date-container'>
                <div>Date:</div>
              <div className='mx-2 encounter-print-patient-date'>
                {item.datePaid? getDate(new Date(item.datePaid)):null}
              </div>
                <div className='text-field-line-date'></div>
                <div>:التاريخ </div>
              </div>
            </div>

          <div className='mt-2'>
            <div className='mx-2 encounter-print-patient-id'>
            {item?.patient.identifier}
            </div>
            <div className='patient-id-container'>
              <div>File No.:</div>
              <div className='text-field-line'></div>
              <div>:رقم الملف </div>
            </div>
          </div>
         
          <div className='mt-2'>
            <div>
            <div className='mx-2 encounter-print-patient-name'>
              {item?.patient.firstName} {item?.patient.lastName}
            </div>
            <div className='patient-name-container'>
              <div>Patient Name: </div>
              <div className='text-field-line'></div>
              <div>:اسم المريض </div>
            </div>
          </div>
          </div>
          <div className='mt-2'>
            <div className='mx-2 encounter-print-patient-name'>
              {/* {item?.appointment?.doctor?.firstName} {item?.appointment?.doctor?.lastName} */}
              {item?.appointment ? `${item?.appointment?.doctor?.firstName} ${item?.appointment?.doctor?.lastName}` : `${item?.doctor?.firstName} ${item?.doctor?.lastName}`} 
            </div>
            <div className='patient-name-container'>
              <div>Doctor Name:</div>
              <div className='text-field-line'></div>
              <div>:اسم الطبيب </div>
            </div>
          </div>
        </div>


            <h6 className="d-print-none">
              {" "}
              Patient:
              <Link
                className="d-inline-block patient-name-print-mode-link p-0"
                target="_blank"
                to={`/patient/record/${
                  item?.patient
                    ? item?.patient?.identifier
                    : null
                }`}
              >
                <Button variant="link" className="p-0 mx-1 capitalize">
                  {item?.patient?.firstName}{" "}
                  {item?.patient?.lastName}
                </Button>
              </Link>
            </h6>
            <h6 className="capitalize d-print-none">
              Gender: {item?.patient?.displayGender}
            </h6>
            <h6 className="capitalize d-print-none">
              Phone: {item?.patient?.phone}
            </h6>
            <h6 className="capitalize d-print-none">
              Identifier: {item?.patient?.identifier}
            </h6>
              {item?.appointment ?
                <h6 className="capitalize d-print-none">
                  Practitioner: {item?.appointment?.doctor?.firstName} {item?.appointment?.doctor?.lastName}
                </h6>
              : null}
          </div>
        ) : null}

      </div>
    );
  };


  const renderPaymentDetail = () => {
    if (item) {
      return (
        <tr className="page-table-cell w100">
          <td className="page-table-cell w100">
            <Table bordered className="services-table w100 print-table">
              <thead>
                <tr>
                  <th>
                    <div>ملاحظات</div>
                    <div>Notes</div>
                  </th>
                  <th>
                    <div>الخدمة المقدمة</div>
                    <div>Description of Service</div>
                  </th>
                  <th>
                    <div>المبلغ</div>
                    <div>Amount</div>
                  </th>
                  <th>
                    <div>الخصم</div>
                    <div>Discount</div>
                  </th>
                  <th>
                    <div>المبلغ الصافي</div>
                    <div>Net Amount</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>Payment created: {getDate(new Date(paymentCreatedDate))}</p>
                    <p>{item.notes}</p> 
                  </td>
                  <td>
                  {item?.appointment?.eventType?.title ? (
                    <p className="">
                      {item?.appointment?.eventType?.title}
                    </p>
                  ) : <p className="capitalize">{item?.reason.toLowerCase()}</p>}

          
                  </td>
                  <td>
                    <p>{item?.appointment?.originalPrice ? item?.appointment?.originalPrice : item.amount} {item.currency}</p>
                  </td>
                  <td>
                    {item?.appointment?.originalPrice ? (parseFloat(item?.appointment?.originalPrice).toFixed(2) - parseFloat(item?.amount).toFixed(2)) + ' ' + item?.currency : null} 
                  </td>
                  <td>{item.amount} {item.currency}</td>
                </tr>
                <tr>
                  <td colSpan="4">
                    <div className=''>
                      <div className='mx-2 encounter-print-patient-total'>{item.amount} {item.currency}</div>
                      <div className='patient-total-container'>
                        <div>Total:</div>
                        <div className='text-field-line'></div>
                        <div>:الاجمالي </div>
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
            <div className="page">
              <Row className="d-print-none">
                <Col>
                  <h6 className="">
                    Date: {getDate(new Date(item.created))}
                  </h6>
                  <h6 className="">
                    Payment Type: {item.displayPaymentType}
                  </h6>
                  <h6 className="">
                    Amount: {item.amount} {item.currency}
                  </h6>
                  {item.appointment ? (
                    <h6 className="">
                      Appointment Date:{" "}
                      {getDate(new Date(item.appointment.start))}
                    </h6>
                  ) : null}
                  <h6 className="mb-3">Notes: {item.notes}</h6>
                </Col>
              </Row>
            </div>
          </td>
        </tr>
      );
    }
    
  };


  return (
    <>
      <Base
        title={"Reciept"}
        showHeader={true}
      >
        <Row className="d-print-none">
          <Col>
            {renderPatientInfo()}
            <Row>
              <Col>
                <h6 className="mb-1 mt-3">
                  <b>Payments:</b>
                </h6>
              </Col>
            </Row>
            {renderPaymentDetail()}
            <PaymentDetail sources={item.sources} />
          </Col>
        </Row>
      </Base>
    </>
  );
};

export default withRouter(PaymentsPrintDetail);
