import React from 'react';
// import style from './Navigation.module.css';
import { Col, Row, Button } from 'react-bootstrap';
import LogoutButton from './LogoutButton/LogoutButton';
import { withRouter, Link } from 'react-router-dom';
import Logo from '../../assets/images/img.png';
import { gql, useQuery } from '@apollo/client';
import { getPermissionForAction } from '../../Common/helpers';

const Navigation = (props) => {
  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const { data } = useQuery(USER_DETAILS);

  var userDetails = data.userDetails;
  if (userDetails && userDetails.indexOf('username') > -1) {
    userDetails = JSON.parse(userDetails);
  }
  // const handleChangeTab = (tabId) => {
  //   const { history } = props;
  //   history.push("/"+tabId);
  // };

  const getDate = () => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    let today = new Date();
    let res = days[today.getDay()];
    res += ' - ' + monthNames[today.getMonth()];
    res += ' ' + today.getDate();
    res += ', ' + today.getFullYear();
    return res;
  };

  const REQUEST_USER_PERMISSION = gql`
  query receiveDate {
    userPermissions @client
  }
`;
  const { data: user_permissions = { user_permissions: [] } } = useQuery(REQUEST_USER_PERMISSION);

  let permissions = [
    'patients',
    'appointments',
    'my_appointments',
    'prescriptions',
    'waiting_list',
    'payments',
    'unpaid_payments',
    'settings',
  ];
  permissions = permissions.filter(i => getPermissionForAction(i, "list", user_permissions.userPermissions));
  permissions.push("unpaid_payments");
  const has_payments_permission = getPermissionForAction("payments", "add", user_permissions.userPermissions);
  const has_appointment_permissions=getPermissionForAction("appointments", "add", user_permissions.userPermissions);
  let navigation_links = [
    {
      key: 'patients',
      slug: '/patients',
      icon: 'fa fa-users',
      title: 'Patients',
    },
    {
      key: 'appointments',
      slug: '/appointments',
      icon: 'fa fa-calendar',
      title: 'Appointments',
    },
    {
      key: 'my_appointments',
      slug: '/my/appointments',
      icon: 'fa fa-calendar',
      title: 'My Appointments',
    },
    {
      key: 'waiting_list',
      slug: '/waiting/list',
      icon: 'fa fa-edit',
      title: 'Waiting List',
    },
    {
      key: 'payments',
      slug: '/payments',
      icon: 'fa fa-credit-card',
      title: 'Payments',
    },
    {
      key: 'unpaid_payments',
      slug: '/pending/payments',
      icon: 'fa fa-credit-card',
      title: 'Unpaid manual payments',
    },
    // {
    //   key: 'settings',
    //   slug: '/settings',
    //   icon: 'fa fa-cog',
    //   title: 'Settings',
    // },
  ];
  const toggleNav = (open) => {
    if (open) {
      document.getElementById('mySidenav').style.width = '250px';
      document.getElementById('nav_overlay').classList.remove('d-none');
    } else {
      document.getElementById('mySidenav').style.width = '0';
      document.getElementById('nav_overlay').classList.add('d-none');
    }
  };

  const renderNavContents = () => {
    return (
      <>
        <div>
          <div className="d-md-none userdetails px-4 mb-4">
            <h6 className="textPrimary">Logged in as:</h6>
            <span className="textPrimary capitalize wrapAnywhere">
              {userDetails ? userDetails.username : 'User'}
            </span>
          </div>
          <Link to={"/dashboard"} >
            {' '}
            <Button className="text-left" variant="link">
              {' '}
              <i className={' fa fa-columns mx-2 '} aria-hidden="true"></i> Dashboard
            </Button>
          </Link>
          {navigation_links.map((link, index) => {
            if (permissions.includes(link.key)) {
              return (
                <Link to={link.slug} key={index}>
                  {' '}
                  <Button className="text-left" variant="link">
                    {' '}
                    <i className={link.icon + ' mx-2 '} aria-hidden="true"></i> {link.title}
                  </Button>
                </Link>
              );
            } else {
              return null;
            }
          })}
          {has_appointment_permissions ? <Link to="/prescription/print">
            <Button variant="link" className="text-left">
              <i className='fa fa-receipt mx-2' aria-hidden="true"></i> Prescriptions
            </Button>
          </Link> : null}
          {has_payments_permission ? <Link to="/manual/payment">
            <Button variant="link" className="text-left">
              <i className='fa fa-receipt mx-2' aria-hidden="true"></i> Manual Payment
            </Button>
          </Link> : null}
        
          <LogoutButton />
        </div>
      </>
    );
  };
  return (
    <>
      <div className="mobile_nav w100 justify-content-between align-items-center">
        <span onClick={() => toggleNav(true)} className="flex1">
          <i className={'fa fa-bars menu_icon '} aria-hidden="true"></i>{' '}
        </span>
        <Link to="/dashboard" className="d-flex justify-content-center flex1">
          <img alt={'Logo'} className="img-fluid navLogo" src={Logo} />
        </Link>
        <h6 className="today_date text-right mb-0 flex1">{getDate()}</h6>
        <div id="nav_overlay" onClick={() => toggleNav()} className=" d-none"></div>
      </div>
      <Row className="flex4 user_details">
        <Col>
          <h6 className="today_date mb-5 mt-3 text-right">{getDate()}</h6>
          <Link to="/dashboard">
            <img alt={'Logo'} className="img-fluid navLogo" src={Logo} />
          </Link>
          <h6 className="today_date text-center mt-4 "> Logged in as </h6>
          <span className="text-light wrapAnywhere">
            {userDetails ? userDetails.username : 'User'}
          </span>
        </Col>
      </Row>
      <Row className="flex6 tabs">{renderNavContents()}</Row>
      <div id="mySidenav" className="sidenav">
        <div className="closebtn" onClick={() => toggleNav()}>
          <i className={'fa fa-times '} aria-hidden="true"></i>{' '}
        </div>
        {renderNavContents()}
      </div>
    </>
  );
};

export default withRouter(Navigation);
