import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Table, Form, Button, Card, Modal } from 'react-bootstrap';
import Base from './base.js';
import { withRouter, Link } from 'react-router-dom';
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  isUserStaff,
  success_options,
  concatAllErrors
} from '../Common/helpers';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import Preloader from '../Common/Preloder/Preloader';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import * as Sentry from "@sentry/browser";

// import CustomModals from '../component/Modal/modal';
import PatientFileUploadModal from './patientsFileUploadModal';
// import { useTranslation } from "react-i18next";

function PatientRecordFiles(props) {
  const isStaff = isUserStaff();
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  const [date, setDate] = useState("");
  const [modifiedLt, setModifiedLt] = useState(new Date());
  const [modifiedGt, setModifiedGt] = useState(new Date("2002-04-01"));
  const id = props.match.params.id;
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const formikRef = useRef();
  // const { t } = useTranslation();

  const REQUEST_PATIENT = gql`
  query {
    patient(id:"${id}"){
          firstName
          lastName
          id
          files(name_Icontains:"${fileName}", modified_Lt:"${modifiedLt.toISOString()}", modified_Gt:"${modifiedGt.toISOString()}"){
            edges{
              node{
                name
                id
                file
                created
                description
              }
            }
          }
        }
  }
`;
const DELETE_PATIENT_FILE = gql`
		mutation deletePatientFile($id: ID!){
			deletePatientFile(id: $id) {
			deleted
			}
		}	
	`;

  const [getPatients, { data, loading }] = useLazyQuery(REQUEST_PATIENT, {
    fetchPolicy: 'network-only',
    onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      Sentry.captureException("REQUEST_PATIENT error "+e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [deletePatientFile] = useMutation(DELETE_PATIENT_FILE, {
		onCompleted: ({ deletePatientFile }) => {
			if (deletePatientFile.deleted) {
				openSnackbarSuccess('Patient file Deleted', [SNACK_DURATION]);
        // resetFilter();
			}
		},
		onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      Sentry.captureException("DELETE_PATIENT_FILE error "+e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
		},
		refetchQueries: [
			{ query: REQUEST_PATIENT, fetchPolicy: 'network-only' }
		],
	});

  const deleteFile = (id) => {
		console.log("iddddd", id)
		// if (id) {
			if (window.confirm("Are you sure you want to delete this file?")) {
				deletePatientFile({
					variables: { id: id },
				});
			}
		// }
	}

  useEffect(() => {
    getPatients().then(data=>{
      console.log("all_file_length 1",all_file_length)
      console.log("get patients data 1",data);
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("data", data);
  const patient_record = data && data.patient ? data.patient : null;
  const file_len = patient_record?.files?.edges?.length? patient_record?.files?.edges?.length:0;
const all_file_length = allFiles?.length;

  useEffect(() => {
    if (patient_record && patient_record.files.edges) {
      getPatients().then(data=>{
        console.log("all_file_length 2",all_file_length)
        console.log("get patients data 2",data);
      })
      setAllFiles(patient_record.files.edges);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_record?.files?.edges?.length]);

  // useEffect(() => {
  //   if (all_file_length>0) {
  //     console.log("file_len",all_file_length);
  //       getPatients().then(data=>{
  //           console.log("file_len 3",all_file_length)
  //           console.log("get patients data 3",data);
  //         })
  //   }
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [all_file_length]);

  const onSubmit = (values, { resetForm }) => {
    if (values.fileName) {
      setFileName(values.fileName);
    }
    if (date) {
      let dateGt = new Date(date);
      let dateLt = new Date(date);
      dateLt.setDate(dateLt.getDate() + 1);
      setModifiedGt(new Date(dateGt));
      setModifiedLt(new Date(dateLt));
    }
    getPatients();
  };
  const resetFilter = (handleReset) => {
    setFileName("");
    handleReset();
    setDate("");
    setModifiedGt(new Date("2002-04-01"));
    setModifiedLt(new Date());
  }


  console.log("patient_record", patient_record);
  const initialValues = {
    fileName: '',
  }

  return (
    <Base isPatientPortal={!isStaff} title={patient_record ? <b>Your Files</b> : <b>Patient record files</b>} showHeader={true}>

      <Formik onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef}>
        {({ handleSubmit, handleChange, handleReset, values }) => (
          <Form noValidate onSubmit={handleSubmit} className="d-flex flex-row mt-5">
            <Row>
              <Col md={6} lg={4} xl={4} sm={12}>
                <Form.Group as={Row}>
                  <Form.Label column sm={3} md={3} lg={3} className="text-right pr-0">
                    Date
                  </Form.Label>
                  <Col sm={8} md={8} lg={8}>
                    <DatePicker
                      autoComplete="off"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      className="form-control"
                      dateFormat="dd/MMM/yyyy"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6} lg={4} xl={4} sm={12}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4} md={4} className="text-right pr-0">
                    File Name
                  </Form.Label>
                  <Col sm={8} md={8}>
                    <Form.Control
                      autoComplete="off"
                      as="input"
                      type="input"
                      name="fileName"
                      value={values.fileName}
                      onChange={handleChange}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6} lg={4} xl={4} sm={12}>
                <div>
                  <Button type="button" variant="primary" size="sm" className='mx-2' onClick={() => resetFilter(handleReset)}>Reset</Button>
                  <Button variant="primary" size="sm" type="submit">Submit</Button>
                </div>
              </Col>
            </Row>
          </Form>


        )}
      </Formik>
      <Card className='col-md-10 mt-5'>
        <Card.Body>
          <Card.Title className='d-flex flex-row justify-content-end align-items-center'>
            <Button variant="primary" className='mx-2' size='md' onClick={() => setShowModal(true)} >
              <b>Add Files</b>
            </Button>
          </Card.Title>
          <Table responsive className=" ">
            <thead>
              <tr >
                <th scope="col " className="text-uppercase"> created</th>
                <th scope="col " className="text-uppercase">name</th>
                <th scope="col " className="text-uppercase"> description</th>
                <th scope="col " className="text-uppercase"> Action</th>
              </tr>
            </thead>
            {loading ? <Preloader /> :
              <tbody>
                {allFiles && allFiles.length > 0 ? allFiles.map((file, index) => {
                  return (
                    <tr key={index} className="patient_table_row">
                      <td>{getDate(new Date(file.node.created))}</td>
                      <td>{file.node.name}</td>
                      <td>{file.node.description}</td>
                      <td>
                        <Button variant="primary" size='sm'>
                          {' '}
                          <a href={file.node.file} target="_blank" rel="noreferrer" className='text-white' >View </a>
                        </Button>
                        <Button variant="danger" size='sm' className='px-0 ml-3 '
                        onClick={() => deleteFile(file?.node?.id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  )
                }) : <h6 className="p-3"> Files Not Added </h6>}
              </tbody>}
          </Table>
        </Card.Body>
      </Card>
      <Modal dialog show={showModal} Header="Upload Patient Files" setShowModal={setShowModal} className=" modal-spacing">
        <h4 className='modal-title d-flex justify-content-center py-3' style={{ borderBottom: '1px solid lightGrey' }}><b>Upload Patient Files</b></h4>
        {patient_record && patient_record.id ? <PatientFileUploadModal
          patient_id={patient_record.id}
          setShowModal={setShowModal}
          setAllFiles={setAllFiles}
          showModal={showModal}
          REQUEST_PATIENT={REQUEST_PATIENT}
        /> : <div className='p-4'> System encountered an error, Please reload </div>}
      </Modal>
    </Base>
  );
}

export default withRouter(PatientRecordFiles);