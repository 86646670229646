import { Card } from 'react-bootstrap';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { getForamttedTime, getDay, getDate } from '../Common/helpers.js';
import { Link } from 'react-router-dom';

function UpcomingAppointmentCard(props) {
  let { event, detail, location, extraClasses } = props;
  const { pathname } = location;
  const getCard = () => {
    let date = new Date(event.start);
    return (
      <Card className={extraClasses ? extraClasses : ''}>
        <Card.Body>
          <div className="d-flex justify-content-between flex-wrap">
            <span> {getDay(date.getDay())} </span>
            <span>{getDate(date)}</span>
            <span>{getForamttedTime(date)}</span>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <span> {event.eventType && event.eventType.title ? event.eventType.title : null} </span>
            <span className="text-capitalize"> {event.displayStatus} </span>
          </div>
          {detail ? (
            <div className="d-flex justify-content-between mt-3">
              {event.location ? (
                <span className="text-capitalize"> Location: {event.location.title} </span>
              ) : null}
              <span className="text-capitalize"> Duration: {event.duration} Minutes </span>
            </div>
          ) : null}
         
          <div className="d-flex justify-content-between mt-3 ">
           {event.doctor?  <span className="text-capitalize"> Practitioner: {event.doctor.firstName} {event.doctor.lastName} </span>:null}
             {/* <span className="text-capitalize"> File No.: {event.patient.identifier} </span> */}
          </div>
        </Card.Body>
      </Card>
    );
  };

  if (event) {
    if (pathname.indexOf('/appointment/detail/') > -1) {
      return getCard();
    } else {
      return (
        <Link to={'/appointment/detail/' + event.id} className="dark_link">
          {getCard()}
        </Link>
      );
    }
  }
  return null;
}
export default withRouter(UpcomingAppointmentCard);
