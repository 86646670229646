import { Card, Table,Badge } from 'react-bootstrap';
import React from 'react';
import { CopyToClipBoardButton } from "Common/helpers.js";


function PaymentDetail(props) {
	/** 
	 * This component is for appointment payment details
	 * It will be a list of payments needed for this event.
	 * 
	 * It expects a prop event for its data. That should have sources
	 * **/

	const { sources } = props;

	const renderPaymentItems = () => {
		
		console.log("sourcessourcessources",sources);
		if (sources.edges.length > 0) {
			return sources.edges.map((item, index) => (
				<tr key={index}>
					<td className="align-middle">{item.node.reference ? item.node.reference : "-"}</td>
					<td className="align-middle">{item.node.amountAllocated ? item.node.amountAllocated : "-"}</td>
					<td className="align-middle">
						{item.node.status}
					</td>
					<td className="align-middle">{item.node.amountRefunded ? item.node.amountRefunded : "-"}</td>
					<td className="align-middle d-flex">
						<a href={item.node.paymentLink} target="_blank"  rel="noreferrer" className="mr-2" > <i className="fa fa-link ml-2" aria-hidden="true"></i></a>
						<CopyToClipBoardButton textValue={item.node.linkMessage}  variant='link'/>
					</td>
					<td className="align-middle">
						<h5>
							{item.node.smsSent ? <Badge variant="primary"> Notification Sent </Badge> :
								<Badge variant="danger"> Notification not Sent</Badge>}
						</h5>
					</td>

				</tr>
			));
		} else {
			return (
				<tr>
					<td colSpan={3} className="align-middle">No payment sources.</td>
				</tr>
			)
		}
	}

	return (
		// TODO: always ready all static text for translations.
		<Card>
			<Card.Body>
				<Card.Title>Payments</Card.Title>
				<Table responsive>
					<thead>
						<tr>
							<th>Reference</th>
							<th>Amount</th>
							<th>Status</th>
							<th>Amount Refunded</th>
							<th>Payment link</th>
							<th>SMS Status</th>
						</tr>
					</thead>
					<tbody>
						{renderPaymentItems()}
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	);

}

export default PaymentDetail;