
import React from 'react';
import LoginPage from './component/LoginPage/LoginPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch, Redirect, useLocation} from "react-router-dom";
import AppointmentCreate from "./Views/appointmentCreate";
import AppointmentCreatePast from "./Views/appointmentCreatePast";
import Dashboard from "./Views/dashboard";
import Patients from "./Views/patients";
import MyAppointments from "./Views/my_appointments";
import WaitingList from "./Views/waiting_list";
import Payments from "./Views/payments";
import PendingPayments from "./Views/pendingPayments";

import PaymentsPrintDetail from "./Views/PaymentsPrintDetail";
import PendingPaymentsPrintDetail from "./Views/pendingPaymentsPrintDetail";

import PrescriptionPrint from "./Views/PrescriptionPrint";
// import Settings from "./Views/settings";
import CreateNewPatient from './Views/CreateNewPatient/CreateNewPatientHoc';
import ManualPayment from "./Views/manual_payment";
import PatientRecord from "./Views/patient_record";
import PatientRecordFiles from './Views/patientRecordFiles';
import AppointmentDetail from "./Views/appointment_detail";
import SearchForPatient from './Views/SearchForPatient/searchForPatientPage';
import SnackbarProvider from 'react-simple-snackbar';
import VerifyToken from "./verifyToken";
import PatientPrescriptionHistory from './Views/patientPrescriptionHistory';


const App = () => {

  let location = useLocation();

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      localStorage.getItem('token')
        ? 
        <>
          <VerifyToken />
          <Component {...props} />
        </>
        : <Redirect to={{
          pathname: '/',
          state:{from:location}
        }} />
    )} />
  )
  const PublicOnlyRoute = ({ component: Component,...rest }) => (
    <Route {...rest} render={(props) => (
      localStorage.getItem('token')
        ?
        <Redirect to={{
          pathname: '/dashboard',
        }} />
        :
        <>
          <VerifyToken />
          <Component {...props} />
        </>
    )} />
  )
  return (
    <SnackbarProvider>
        <Switch>
          <PrivateRoute exact path="/patient/record/:identifier" component={PatientRecord} />
          <PrivateRoute exact path="/create/appointment" component={AppointmentCreate} />
          <PrivateRoute exact path="/create/past/appointment/:patientId" component={AppointmentCreatePast} />
          <PrivateRoute exact path="/appointment/detail/:appointmentId" component={AppointmentDetail} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/patients" component={Patients} />
          <PrivateRoute exact path="/prescription/print" component={PrescriptionPrint} />
          <PrivateRoute exact path="/patient/prescription/history/:identifier" component={PatientPrescriptionHistory} />
          <PrivateRoute exact path="/patient/record/files/:id" component={PatientRecordFiles} />
          <PrivateRoute exact path="/appointments" component={MyAppointments} />
          <PrivateRoute exact path="/manual/payment" component={ManualPayment} />
          <PrivateRoute exact path="/search/patient" component={SearchForPatient} />
          <PrivateRoute exact path="/create/patient" component={CreateNewPatient} />
          <PrivateRoute exact path="/edit/patient/:identifier" component={CreateNewPatient} />
          <PrivateRoute exact path="/waiting/list" component={WaitingList} />
          <PrivateRoute exact path="/payments" component={Payments} />
          <PrivateRoute exact path="/payments/detail/:id" component={PaymentsPrintDetail} />

          <PrivateRoute exact path="/pending/payments" component={PendingPayments} />
          <PrivateRoute exact path="/pending/payments/detail/:id" component={PendingPaymentsPrintDetail} />

          {/* <PrivateRoute exact path="/settings" component={Settings} /> */}
          <PublicOnlyRoute  exact path="/" component={LoginPage} />
          <Route path="/password-reset/" component={LoginPage} />
          <Route path="/forgot/password/" component={LoginPage} />
          <Route path="/" component={LoginPage} />
        </Switch>
    </SnackbarProvider>  
  );
};

export default App;
