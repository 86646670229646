import React, { useState, useEffect } from 'react';
import style from './LoginForm/LoginForm.module.css';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  success_options,
  SNACK_DURATION,
  urlActions,
  ERROR_MESSAGE,
  concatAllErrors
} from '../../Common/helpers.js';
import * as Sentry from "@sentry/browser";


const PasswordReset = (props) => {
  const { history } = props;
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [token, setToken] = useState(null);

  useEffect(() => {
    let token = urlActions(window.location.href, 'get', 'token');
    if (token) {
      setToken(token);
    }
  }, []);

  //=======================LOGIN==========================//

  const PASSWORD_RESET = gql`
    mutation passwordReset($token: String!, $newPassword1: String!, $newPassword2: String!) {
      passwordReset(
        input: { token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2 }
      ) {
        success
        errors
      }
    }
  `;

  const [passwordReset, { loading }] = useMutation(PASSWORD_RESET, {
    onCompleted({ passwordReset }) {
      if (passwordReset.success) {
        openSnackbarSuccess('Password Reset Successful. Please login.', [SNACK_DURATION]);
        history.push('/');
        formik.handleReset();
      } else {
        let errors = passwordReset.errors;
        let nonFieldErrors = errors.nonFieldErrors;
        let newPassword2Errors = errors.newPassword2;
        for (let i in nonFieldErrors) {
          let e = nonFieldErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in newPassword2Errors) {
          let e = newPassword2Errors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
      }
    },
    onError: (err) => {
      Sentry.setContext('error', err?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : err});
      Sentry.setContext('ERROR CODE statusCode ', {code:err?.networkError?.statusCode});
      Sentry.captureException("passwordReset error "+err);


      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const initialValues = {
    password1: '',
    password2: '',
  };

  const onSubmit = (values, { resetForm }) => {
    passwordReset({
      variables: { token: token, newPassword1: values.password1, newPassword2: values.password2 },
    });
  };

  // const validate = (values) => {
  //   let errors = {};
  //   if (!values.password1) {
  //     errors.password1 = 'Please enter a password.';
  //   }
  //   if (!values.password2) {
  //     errors.password2 = 'Please re-enter a password.';
  //   }
  //   return errors;
  // };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Form.Group as={Row} className="mb-4">
          <Form.Label column md={4} className={'text-white text-right'}>
            Password
          </Form.Label>
          <Col md={8}>
            <Form.Control
              autoComplete="new-password"
              type="password"
              name="password1"
              onChange={formik.handleChange}
              value={formik.values.password1}
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column md={4} className={'text-white text-right'}>
            Re-enter Password
          </Form.Label>
          <Col md={8}>
            <Form.Control
              autoComplete="new-password"
              type="password"
              name="password2"
              onChange={formik.handleChange}
              value={formik.values.password2}
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}></Form.Group>
        <Form.Group as={Row}>
          <Col className={style.login_form__submit}>
            <Button disabled={loading} className="login_form__button" type="submit">
              {loading ? 'Loading ...' : 'Change Password'}
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </>
  );
};

export default withRouter(PasswordReset);
