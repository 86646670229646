import React from 'react';
import style from './LoginForm/LoginForm.module.css';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options ,success_options ,SNACK_DURATION, ERROR_MESSAGE,concatAllErrors} from "../../Common/helpers.js";
import * as Sentry from "@sentry/browser";


const ForgotPassword = (props) => {
  const [openSnackbarError ] = useSnackbar(error_options);
  const [openSnackbarSuccess ] = useSnackbar(success_options);

  //======================= FORGOT PASSWORD ==========================//

  const PASSWORD_RESET = gql`
    mutation sendPasswordResetEmail($resetEmail:String!) {
      sendPasswordResetEmail(
        input: {
          email:$resetEmail,
        }
      ) {
        success,
        errors,
      }
    }
  `;

  const [passwordResetReq, { loadingReset }] = useMutation(PASSWORD_RESET, {
    onCompleted({ sendPasswordResetEmail }) {
      if(sendPasswordResetEmail.success){
        openSnackbarSuccess("Password Reset Email Sent.");
      }
      else{
        let errors=sendPasswordResetEmail.errors;
        let nonFieldErrors=errors.nonFieldErrors;
        let emailErrors=errors.email;
        for(let i in nonFieldErrors){
          let e = nonFieldErrors[i];
          openSnackbarError(e.message,[SNACK_DURATION]);
        }
        for(let i in emailErrors){
          let e = emailErrors[i];
          openSnackbarError(e.message,[SNACK_DURATION]);
        }
      }
    },
    onError: (err) => {
      Sentry.setContext('error', err?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : err});
      Sentry.setContext('ERROR CODE statusCode ', {code:err?.networkError?.statusCode});
      Sentry.captureException("passwordResetReq error "+err);

      let errorMsg = concatAllErrors(err?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const initialValuesReset = {
    email: '',
  };


  const onSubmitReset = (values, { resetForm}) => {
    // console.log("values",values);
    passwordResetReq({ variables: { resetEmail: values.email} });
    resetForm({ values: '' });
  };


  // const validateReset = (values) => {
  //   let errors = {};
  //   if (!values.email) {
  //     errors.email = 'Please enter an email.';
  //   }
  //   return errors;
  // };



  const formikReset = useFormik({
    initialValues:initialValuesReset,
    onSubmit:onSubmitReset,
  });

  return (
    <Form onSubmit={formikReset.handleSubmit}>
    <Form.Group as={Row} className="mb-4">
      <Form.Label column sm={3} className={'text-white text-right' }>
        Email
      </Form.Label>
      <Col sm={9}>
        <Form.Control
          type="email"
          name="email"
          onChange={formikReset.handleChange}
          value={formikReset.values.email || ' '}
          autoComplete="username"
          required
        />
      </Col>
    </Form.Group>
    <Button variant="link" onClick={()=>props.history.push("/")} block className="forgot_password" > Back to Login
      </Button>
      <Form.Group as={Row}></Form.Group>
    <Form.Group as={Row}>
      <Col className={style.login_form__submit}>
        <Button disabled={loadingReset} className="login_form__button" type="submit">
          {loadingReset ? "Loading ..." : "Reset Password" }
        </Button>
      </Col>
    </Form.Group>
  </Form>
  );
};

export default withRouter(ForgotPassword);
