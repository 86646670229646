import { InMemoryCache, makeVar } from '@apollo/client';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        dateEvent: {
          read() {
            return dateEventInVar();
          },
        },
        dataPatient: {
          read() {
            return dataPatientInVar();
          },
        },
        userDetails: {
          read() {
            return userDetailsVar();
          }
        },
        userPermissions: {
          read() {
            return userPermissionsVar();
          }
        },
        calendarSlots: {
          read() {
            return calendarSlotsVar();
          }
        },
        myAppointmentDetails: {
          read() {
            return myAppointmentDetailsVar();
          }
        },
        calendarActiveMonth: {
          read() {
            return calendarActiveMonthVar();
          }
        },
        eventsData: {
          read() {
            return eventsDataVar();
          }
        },
        isCustomApp: {
          read() {
            return isCustomAppVar();
          }
        },
        editableAppointment: {
          read() {
            return editableAppointmentVar();
          }
        },
        paymentsFilter: {
          read() {
            return paymentsFilterVar();
          }
        },
        appFromCal: {
          read() {
            return appFromCalVar();
          }
        },
        displayLoader: {
          read() {
            return displayLoaderVar();
          }
        },
      },
    },
  },
});
export const isLoggedInVar = makeVar(!!localStorage.getItem('token'));
export const dateEventInVar = makeVar([]);
export const calendarSlotsVar = makeVar(null);
export const dataPatientInVar = makeVar([]);
export const userDetailsVar = makeVar(localStorage.getItem('user'));
export const calendarActiveMonthVar = makeVar(null);
export const userPermissionsVar = makeVar(localStorage.getItem('user_permissions'));
export const myAppointmentDetailsVar = makeVar({});
export const eventsDataVar = makeVar({});
export const isCustomAppVar = makeVar(false);
export const appFromCalVar = makeVar(null);
export const editableAppointmentVar = makeVar(null);
export const paymentsFilterVar = makeVar(null);
export const displayLoaderVar = makeVar(false);
