import { Row, Col, Button, Card,Badge } from 'react-bootstrap';
import Base from './base.js';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, SNACK_DURATION, ERROR_MESSAGE,concatAllErrors } from '../Common/helpers';
import { getForamttedTime, getDate , getPermissionForAction} from '../Common/helpers.js';
import Preloader from '../Common/Preloder/Preloader';
import * as Sentry from "@sentry/browser";

function Dashboard(props) {
  const REQUEST_EVENTS = gql`
  query($start: DateTime!, $end: DateTime!) {
    events(start: $start, end: $end, orderby:"start",active:true){
      edges {
        node {
          title
          id
          displayStatus
          status
          start
          end
          doctor{
            firstName
            lastName   
          }
          patient{
            firstName
            lastName
            identifier
          }
          eventType{
            title
          }
        }
      }
    }
  }
  `;

  let startDate = new Date();
  let endDate = new Date();
  startDate = startDate.toISOString().split('.')[0];

  let endTime = 'T23:59:59';
  let startTime = 'T00:00:01';
  // set dynamic change of date but not time to show appointments for only one day
  startDate = startDate.split('T')[0] + startTime;
  endDate = startDate.split('T')[0] + endTime;
  const [openSnackbar] = useSnackbar(error_options);
  const { data, loading } = useQuery(REQUEST_EVENTS, {
    fetchPolicy:"network-only",
    variables: {
      start: startDate,
      end: endDate,
    },
    onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      Sentry.captureException("REQUEST_EVENTS error "+e);

			let errorMsg = concatAllErrors(e?.graphQLErrors);
			let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
			openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });
  let upcoming_appointments = [];
  if (data) {
    upcoming_appointments = data.events.edges.map((evt) => {
      return evt.node;
    });
    // upcoming_appointments = sortByDate(upcoming_appointments);
  }
  const REQUEST_USER_PERMISSION = gql`
  query receiveDate {
    userPermissions @client
  }
`;
  const { data:user_permissions={user_permissions:[]} } = useQuery(REQUEST_USER_PERMISSION);

  const has_app_permission_add=getPermissionForAction("appointments","add",user_permissions.userPermissions);
  const has_app_permission_view=getPermissionForAction("appointments","list",user_permissions.userPermissions);
  const has_waiting_permission=getPermissionForAction("waiting_list","add",user_permissions.userPermissions);
  const has_payments_permission=getPermissionForAction("payments","add",user_permissions.userPermissions);


  const renderUpcomingAppointments = () => {
    return upcoming_appointments.map((event, index) => {
      let date = new Date(event.start);
      return (
        event?.patient ? 
          <Link to={'/appointment/detail/' + event.id} className="dark_link" key={index}>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between ">
                  <span>
                    {' '}
                    {event.patient?event.patient.firstName:''} {event.patient?event.patient.lastName:''}
                  </span>
                  <span className="grey-text">{getForamttedTime(date)}</span>
                </div>
                <div className="d-flex justify-content-between py-2 ">
                  <span>
                    {' '}
                    {event.eventType && event.eventType.title ? event.eventType.title : null}{' '}
                  </span>
                  <span className="grey-text">{getDate(date)}</span>
                </div>
                <div className="d-flex justify-content-between mt-3 ">
                  <span className="text-capitalize"> Practitioner: {event.doctor.firstName} {event.doctor.lastName} </span>
                  <span className="text-capitalize"> {event.displayStatus} </span>
                </div>
                {event?.patient ? 
                  <div className="d-flex justify-content-between mt-3 ">
                    <span className="text-capitalize"> File Number: {event.patient.identifier} </span>
                  </div>
                : null}
              </Card.Body>
            </Card>
          </Link> : null
      );
    });
  };
  return (
    <Base>
      <h4 className="mt-5"> Actions </h4>
      <div className="buttons_container mt-5">
        {has_app_permission_add?<Link to="/search/patient">
          <Button variant="primary" size="lg" className="mr-4">
            New Appointment
          </Button>
        </Link> :null}
        {has_payments_permission? <Link to="/manual/payment">
          <Button variant="primary" size="lg" className="mr-4">
            Manual Payment
          </Button>
        </Link>:null}
        {has_waiting_permission?<Link to="/waiting/list">
          <Button variant="primary" size="lg" className="mr-4">
            View Waiting List
          </Button>
        </Link>:null}
      </div>
      <Row >
        {(upcoming_appointments && upcoming_appointments?.length > 0 && has_app_permission_view) || loading ? (
          <Col md={6} lg={5} xl={4} className="mb-4">
            <h4 className="mt-5 mb-4"> Upcoming Appointments <Badge variant="danger"> {upcoming_appointments?.filter(i=>i.patient!==null).length}</Badge> </h4> 
            {/* Upcoming appointment badge should only show count of appointments EXCLUDING breaks so filter for i.patient is uesd */}
            {loading ? <Preloader /> : null}
            {renderUpcomingAppointments()}
          </Col>
        ) : <h4 className="mt-5 mb-4"> No Appointments For Today </h4>}
        {/* <Col md={3}>
          <h4 className="mt-5 mb-4"> Revenue </h4>
          <Card>
            <Card.Body>
              <Container>
                <Row>
                  <Col md="6">Today</Col>
                  <Col md="6">200 KD</Col>
                  <Col md="6">This Week</Col>
                  <Col md="6">200 KD</Col>
                  <Col md="6">This Month</Col>
                  <Col md="6">200 KD</Col>
                </Row>
              </Container>
              <Link to={'/payments'}>
                <Button variant="link" block className="mr-4 text-center mt-3">
                  See more at Payments{' '}
                  <i className="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
                </Button>
              </Link>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </Base>
  );
}
export default Dashboard;
