import React, { useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import CreateNewPatient from './CreateNewPatient';
import { dataPatientInVar } from '../../cache/cache.js';
import Base from '../base.js';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, success_options, SNACK_DURATION, ERROR_MESSAGE,concatAllErrors} from '../../Common/helpers.js';
import * as Sentry from "@sentry/browser";

const CREATE_PATIENT = gql`
  mutation createUpdatePatient(
    $firstName: String!
    $lastName: String!
    $phone: String!
    $dob: Date
    $gender:String!
    $civil_id:String
    $doctor:ID!
    $address:String
    $governorate:String
    $referred_by:String!
    $diagnosis:String
    $medication:String
    $discount:Float
    $paymentContact:String
    $paymentContactDetails:String
    $additional_notes:String
    $dateFirstVisit:String
  ) {
    createUpdatePatient(
      input: { firstName: $firstName, lastName: $lastName, phone: $phone, dob:$dob, gender:$gender, civilId:$civil_id,doctor:$doctor, address:$address,governorate:$governorate, referredBy:$referred_by,impressionDiagnosis:$diagnosis,medication:$medication,discount:$discount,paymentContact:$paymentContact, paymentContactDetails:$paymentContactDetails, additionalNotes:$additional_notes,dateFirstVisit:$dateFirstVisit}
    ) {
      obj {
        firstName
        lastName
        email
        identifier
        phone
        id
        doctor{
          identifier
        }
        discount
      }
      errors {
        field
        messages
      }
    }
  }
`;

const UPDATE_PATIENT = gql`
  mutation createUpdatePatient(
    $firstName: String!
    $lastName: String!
    $phone: String!
    $dob: Date
    $gender:String!
    $civil_id:String
    $doctor:ID!
    $address:String
    $governorate:String
    $referred_by:String!
    $diagnosis:String
    $medication:String
    $id: ID!
    $identifier:String
    $discount:Float
    $paymentContact:String
    $paymentContactDetails:String
    $additional_notes:String
    $dateFirstVisit:String
  ) {
    createUpdatePatient(
      input: { firstName: $firstName, lastName: $lastName, phone: $phone, dob:$dob, gender:$gender, civilId:$civil_id,doctor:$doctor, address:$address,governorate:$governorate, referredBy:$referred_by,impressionDiagnosis:$diagnosis,medication:$medication,id: $id,discount:$discount, identifier:$identifier,paymentContact:$paymentContact, paymentContactDetails:$paymentContactDetails , additionalNotes:$additional_notes,dateFirstVisit:$dateFirstVisit}
    ) {
      obj {
        firstName
        lastName
        email
        identifier
        phone
        id
        doctor{
          identifier
        }
        discount
      }
      errors {
        field
        messages
      }
    }
  }
`;

const CreateNewPatientHoc = (props) => {
  const formikRef = useRef();
  const { history } = props;
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  let selected_button = document.activeElement.name;

  const [createUpdatePatient] = useMutation(CREATE_PATIENT, {
    onCompleted: ({ createUpdatePatient }) => {
      if (createUpdatePatient.errors.length === 0) {
        openSnackbarSuccess('New Patient Created', [SNACK_DURATION]);
        if (formikRef?.current?.values?.forwardToPatient ) {
          dataPatientInVar(createUpdatePatient.obj);
          history.push('/create/appointment');
        }
        else{
          formikRef.current.handleReset();
          dataPatientInVar(null);
        }
      } else {
        dataPatientInVar(null);
        let alert_msg = '';
        createUpdatePatient.errors.map((error) => {
          alert_msg += error.messages.join(' ') + '\n';
          openSnackbar(alert_msg, [50000]);
          return null;
        });
      }
    },
    onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      Sentry.captureException("createUpdatePatient error "+e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [UpdatePatient] = useMutation(UPDATE_PATIENT, {
    onCompleted: ({ createUpdatePatient }) => {
      dataPatientInVar(null);
      if (createUpdatePatient.errors.length === 0) {
        openSnackbarSuccess(' Patient Updated', [SNACK_DURATION]);
        history.push('/patient/record/' + createUpdatePatient.obj.identifier);
      } else {
        let alert_msg = '';
        createUpdatePatient.errors.map((error) => {
          alert_msg += error.messages.join(' ') + '\n';
          openSnackbar(alert_msg, [50000]);
          return null;
        });
      }
    },
    onError: (e) => {
      Sentry.setContext('error', e?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : e});
      Sentry.setContext('ERROR CODE statusCode ', {code:e?.networkError?.statusCode});
      Sentry.captureException("UpdatePatient error "+e);
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const { location } = props;
  const { pathname } = location;

  let is_edit = pathname.indexOf('/edit/patient') > -1 ? true : false;

  return (
    <Base title={is_edit ? 'Edit Patient' : 'Create Patient'} showHeader={true}>
      <CreateNewPatient
        createUpdatePatient={createUpdatePatient}
        UpdatePatient={UpdatePatient}
        is_edit={is_edit}
        formikRef={formikRef}
      />
    </Base>
  );
};

export default withRouter(CreateNewPatientHoc);
