import { Row, Col, Button, Form, Container } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react';
import Base from './base.js';
import { Formik } from 'formik';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getValidDateStringForGraphQL,
  concatAllErrors
} from '../Common/helpers.js';
import DatePicker from 'react-datepicker';
import { dataPatientInVar } from '../cache/cache';
import { useReactiveVar } from '@apollo/client';
import * as Sentry from "@sentry/browser";

function PastAppointmentCreate(props) {
  const patientId = props.match.params.patientId;
  const [openSnackbarError] = useSnackbar(error_options);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const formikRef = useRef();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [datePaid, setDatePaid] = useState('');
  const dataPatient = useReactiveVar(dataPatientInVar);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  
const REQUEST_DOCTOR = gql`
  query {
    doctors(user_IsActive:true) {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          doctorId
          eventType {
            edges {
              node {
                id
                title
                duration
                buffer
                price
                notification{
                  downPaymentNotification
                  downPaymentNotificationCutoff
                }
                eventType {
                  id
                  title
                }
                eventLocation {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CREATE_EVENT_PAST = gql`
  mutation createPastEvent(
    $doctor: ID!
    $patient: ID!
    $start: DateTime!
  $datePaid: Date!
    $end: DateTime!
    $price: Float!
    $title: String!
    $doctorEventType: ID!
  ) {
    createPastEvent(
      input: {
        doctor: $doctor
        patient: $patient
        start: $start
        datePaid: $datePaid
        end: $end
        title: $title
        price: $price
        doctorEventType:$doctorEventType
      }
    ) {
      errors {
        field
        messages
      }
      obj {
        start
        end
        title
        description
        id
        patient{
          id
          identifier
          firstName
          lastName
        }
      }
    }
  }
`;

const [createPastEvent] = useMutation(CREATE_EVENT_PAST, {
  onCompleted: ({ createPastEvent }) => {
    if(createPastEvent.errors.length===0){
      let alert_msg=<span>Appointment created for <strong>{createPastEvent.obj.patient.firstName + " "+ createPastEvent.obj.patient.lastName}</strong><br/></span>
      props.history.push({
        pathname: '/appointments',
        search: '?calendarInitDate=' + createPastEvent.obj.start.split('+')[0] + '&&calendarInitView=timeGridDay'
      });
      openSnackbarSuccess(alert_msg,[SNACK_DURATION]);
      if(formikRef && formikRef.current){
        formikRef.current.handleReset();
      }
      setSelectedDoctor(null)
    }
    else{
      var alert_msg="";
      createPastEvent.errors.map(error=>{
        let messagesArr=error.messages;
        if(messagesArr){
          alert_msg += messagesArr.join(" ");
        }
        return null
      })
      openSnackbarError(alert_msg,[SNACK_DURATION]);
    }
  },
  onError: (error) => {
    Sentry.setContext('error', error?.networkError?.result);
    Sentry.setContext('ERROR OBJ ', {errorObj : error});
    Sentry.setContext('ERROR CODE statusCode ', {code:error?.networkError?.statusCode});
    Sentry.captureException("createPastEvent error "+error);
    let errorMsg = concatAllErrors(error?.graphQLErrors);
    let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
    openSnackbarError(msgToDisplay, [SNACK_DURATION]);
  },
});

  const { data: doctorsList } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy:"network-only",
    onError: (error) => {
      Sentry.setContext('error', error?.networkError?.result);
      Sentry.setContext('ERROR OBJ ', {errorObj : error});
      Sentry.setContext('ERROR CODE statusCode ', {code:error?.networkError?.statusCode});
      Sentry.captureException("REQUEST_DOCTOR error "+error);

      let errorMsg = concatAllErrors(error?.graphQLErrors);
      let msgToDisplay = errorMsg? errorMsg:ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const doctors = doctorsList && doctorsList.doctors ? doctorsList.doctors.edges : [];



  const initialValues = {
    doctor: '',
    price: '',
    session: '',
    start: '',
  };

  const onSubmit = (values, { resetForm }) => {
    let title = dataPatient?.firstName && dataPatient.lastName? dataPatient?.firstName + " " + dataPatient.lastName :"Past Appointment";
    let val = {
      patient: patientId,
      doctor: values.doctor,
      doctorEventType: values.session,
      price: values.price,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      datePaid: datePaid? getValidDateStringForGraphQL(datePaid):getValidDateStringForGraphQL(startDate),
      title: title
    };
    createPastEvent({variables:val})
    console.log("val",val)
  };


  useEffect(() => {
    // returned function will be called on component unmount 

    // let eventformref=formikRef?formikRef.current:null;
    return () => {
      dataPatientInVar([]);
    }
  }, [])


  const handleSelectedDoctor=(e)=>{
    formikRef.current.setFieldValue('doctor', e.target.value);
    let docObj = doctors.find(i=>i.node.id === e.target.value);
    console.log("docObj",docObj);
    setSelectedDoctor(docObj);
  }


  const handleSession = (event) => {
    formikRef.current.setFieldValue('session', event.target.value);
    let session_obj = selectedDoctor?.node?.eventType?.edges?.find(
      (i) => i.node.id === event.target.value,
    );
    setSelectedSession(session_obj);
  };

  const getDoctorSessionDetails = (session) => {
    if (selectedDoctor) {
      let selected_session = selectedDoctor?.node?.eventType?.edges?.find((i) => i.node.id === session);
      if (selected_session) {
        return (
          <>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Location: {selected_session.node.eventLocation.title}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Type: {selected_session?.node?.eventType?.title}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Duration: {selected_session.node.duration} Mins
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Price: {selected_session.node.price} KWD
            </Form.Control.Feedback>
          </>
        );
      }
    }
  };


  return (
    <Base title={'Past Appointment'} showHeader={true}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={10} xl={7}>
          <Formik onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef}>
            {({ handleSubmit, handleBlur, handleChange, values }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Container>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3} md={3} lg={4} className="text-right pr-0 required">
                        Practitioner
                      </Form.Label>
                      <Col sm={12} md={9} lg={8}>
                        <Form.Control
                          autoComplete="off"
                          onBlur={handleBlur}
                          as="select"
                          name="doctor"
                          value={values.doctor}
                          onChange={handleSelectedDoctor}>
                          <option value="">Select Practitioner</option>
                          {doctors.map((doctor) => {
                            return (
                              <option value={doctor.node.id} key={doctor.node.id}>
                                {doctor.node.firstName} {doctor.node.lastName}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="pr-0 text-right required">
                      Practitioner Session
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        required
                        as="select"
                        name="session"
                        id="doctor-session"
                        value={values.session}
                        onChange={handleSession}>
                        <option value="">Select Session</option>
                          {selectedDoctor?.node?.eventType?.edges.map((eventtype) => {
                            return (
                              <option value={eventtype.node.id} key={eventtype.node.id}>
                                {eventtype.node.title}
                              </option>
                            );
                          })}
                      </Form.Control>
                      {/* {selectedDoctor && values.session
                        ? getDoctorSessionDetails(values.session)
                        : null} */}
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0 required">
                      Price 
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="number"
                        onBlur={handleBlur}
                        name="price"
                        value={values.price}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0 required">
                      Start 
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <DatePicker
                        dayClassName={date => date > new Date() ? 'disabled-date' : undefined}
                        autoComplete="off"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="form-control"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy - h:mm aa"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0 required">
                      End 
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <DatePicker
                        dayClassName={date => date > new Date() ? 'disabled-date' : undefined}
                        autoComplete="off"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="form-control"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy - h:mm aa"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0 ">
                      Date Paid 
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <DatePicker
                        dayClassName={date => date > new Date() ? 'disabled-date' : undefined}
                        autoComplete="off"
                        selected={datePaid}
                        onChange={(date) => setDatePaid(date)}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                      />
                    </Col>
                  </Form.Group>
                  <div className="d-flex justify-content-between mt-4 mb-2">
                    <Button variant="primary" size="sm" onClick={() => props.history.goBack()}>
                      Cancel
                    </Button>
                    <Button variant="primary" size="sm" type="submit">
                      Save
                    </Button>
                  </div>
                </Container>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Base>
  );
}
export default PastAppointmentCreate;
