import React from 'react';
import { Spinner } from 'react-bootstrap';

const Preloader = () => {
  return (
    <div className='spinner-block'>
      <Spinner animation="grow" className='spinner-block__elem' />
      <Spinner animation="grow" className='spinner-block__elem' />
      <Spinner animation="grow" className='spinner-block__elem' />
    </div>
  );
};

export default Preloader;
